import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { deleteToast } from './actions';

const DEFAULT_TOAST_DISMISS_TIME = 5000;

/**
 * ToastDetails component that renders the details of a single toast notification.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the toast notification.
 * @param {string} props.description - The description or message of the toast notification.
 * @param {string} [props.icon] - Optional icon URL for the toast notification.
 * @param {string|number} props.id - Unique identifier for the toast notification.
 * @param {string} [props.type] - The type of the toast notification (e.g., "error", "success"). Determines the background color.
 * @param {boolean} [props.autoDelete] - Flag to determine if the toast should auto-dismiss after a certain time.
 * @param {number} [props.dismissTime=3000] - The time in milliseconds before the toast is automatically dismissed (default is 3000 ms).
 *
 * @return {JSX.Element} The rendered ToastDetails component.
 */
export const ToastDetails = ({
  title,
  description,
  icon,
  id,
  type,
  autoDelete,
  dismissTime,
}) => {
  useEffect(() => {
    if (autoDelete && id) {
      const interval = setInterval(() => {
        if (autoDelete && id) {
          deleteToast(id);
        }
      }, dismissTime || DEFAULT_TOAST_DISMISS_TIME);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div
      style={{
        padding: '15px',
        backgroundColor: type === 'error' ? '#bd2c2c' : '#307834',
      }}
    >
      <button onClick={() => deleteToast(id)}>X</button>
      {icon && (
        <div className="notification-image">
          <img src={icon} alt="" />
        </div>
      )}
      <div>
        <p className="notification-title">{title}</p>
        <p className="notification-message">{description}</p>
      </div>
    </div>
  );
};


//TODO move css rules from index.html to in component styled components
/**
 * Toast component that displays a list of toast notifications.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} [props.toastList=[]] - The list of toast notifications to display. Each toast object should contain properties like title, description, type, etc.
 * @param {string} [props.position] - The position of the notification container (e.g., "top-right", "bottom-left").
 *
 * @return {JSX.Element} The rendered Toast component.
 */
const Toast = (props = {}) => {
  const { toastList = [], position } = props;
  return (
    <div id="toast-container">
      <div className={`notification-container ${position}`}>
        {toastList.map((toast, i) => (
          <div key={i} className={`notification toast ${position}`}>
            <ToastDetails {...toast} />
          </div>
        ))}
      </div>
    </div>
  );
};

Toast.propTypes = {
  toastList: PropTypes.array.isRequired,
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
};

export default Toast;
