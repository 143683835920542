import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../utils/Button';
//import { useSelector } from "react-redux";
import Error from '../../utils/Error';
import Modal from '../../utils/Modal';
import PaymentMethodForm from '../../utils/PaymentMethodForm';
import Typography from '../../utils/Typography';
import store from '../../store';
import { IconStar } from '../../utils/Icon';
import { deleteUserPaymentMethod, getUserPaymentMethodList } from './actions';
import { getCreditCartExpirationDateMessage, goToUrl } from '../../utils/utilities';
import { addToast } from '../toast/actions';

const DefaultMessageStyled = styled.div`
  display: flex;
  padding: 1rem 2rem;
  background-color: #eee;
  margin: 0 0 2rem 0;
`;

const ContainerStyled = styled.div`
  display: flex;
`;

const ContentStyled = styled.div`
  flex: 1;
`;

const DeleteStyled = styled.div`
  padding: 0 0 0 1rem;
`;
const EditStyled = styled.div`
  padding: 0 1rem 0 0;
`;

const StarContainerStyled = styled.div`
  padding: 0 1rem 0 0;
`;

const DividerStyled = styled.div`
  margin: 2rem 0;
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_TEXT_COLOR};
  opacity: 0.2;
`;

function UserPaymentMethodList(props = {}) {
  const {
    error,
    loading,
    canEdit,
    canDelete,
    canCreate,
    canSetDefault,
    paymentMethodList,
  } = props;
  const [paymentMethod, setPayment] = useState(null);
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
  });

  const api = useSelector((state) => state.api);
  const deleteDetails = useSelector(
      (state) => state.userPaymentMethodDeleteRejectionDetails,
  );

  const handleClickAddPayment = () => {
    setPayment(null);
    setModal({ title: 'Create Payment', content: 'form', isVisible: true });
  };

  const handleOnCreateViaIFrame = (iFrameResponse) => {
    getUserPaymentMethodList().then((res) => {
      setModal({ ...modal, isVisible: false });
      addToast({
        title: 'Success - New payment added!',
        description: 'NOTE: Adding a new payment method to your account will not automatically add this payment method or replace a payment method on any pending requests or orders',
      });
    });
  };

  const handleClickDeletePayment = (paymentMethod) => {
    store.dispatch({
      type: 'SET_USER_DELETE_PAYMENT_METHOD_REJECT_DETAILS',
      data: null,
    });
    setPayment(paymentMethod);
    setModal({ title: 'Delete Payment', content: 'form', isVisible: true });
  };

  const handleClickConfirmDelete = () => {
    if (paymentMethod && paymentMethod.PaymentMethodUUID)
      deleteUserPaymentMethod({
        PaymentMethodUUID: paymentMethod.PaymentMethodUUID,
      }).then((res) => {
        getUserPaymentMethodList();
        store.dispatch({
          type: 'SET_USER_DELETE_PAYMENT_METHOD_REJECT_DETAILS',
          data: null,
        });
        setModal({ ...modal, isVisible: false });
        addToast({
          title: 'Success - payment method removed',
          autoDelete: true,
        });
      }).catch((error) => {
        addToast({
          title: 'Error - unable to remove payment method',
          type: 'error'
        });
      });
  };
  const paymentApiConstants = ['DELETE_USER_PAYMENT_METHOD'];
  const paymentIsLoading =
      api && api.loading.some((r) => paymentApiConstants.includes(r));

  return (
      <div>
        {canSetDefault ? (
            <DefaultMessageStyled>
              <StarContainerStyled>
                <IconStar fill="gold" color="gold"/>
              </StarContainerStyled>
              <Typography style={{ flex: 1 }}>Set as default</Typography>
            </DefaultMessageStyled>
        ) : (
            <DividerStyled style={{ margin: '0 0 2rem 0' }}/>
        )}

        {Array.isArray(paymentMethodList) &&
            paymentMethodList.map((pm, idx) => {
              return (
                  <React.Fragment key={idx}>
                    <ContainerStyled>
                      {canSetDefault && (
                          <StarContainerStyled
                              style={{ cursor: 'pointer' }}
                              onClick={() => alert('set default function')}
                          >
                            <IconStar
                                fill={paymentMethod.default ? 'gold' : '#ddd'}
                                color="#ddd"
                            />
                          </StarContainerStyled>
                      )}
                      <ContentStyled>
                        <Typography tag="h5">{pm.CardHolderName}</Typography>
                        {/* <Typography>{pm.CardHolderName}</Typography> */}
                        <Typography>**** **** **** {pm.LastFour}</Typography>
                        <Typography>
                          Expire: {pm.Expiration}{' '}
                          <span style={{ fontStyle: 'italic' }}>
                      {getCreditCartExpirationDateMessage(pm.Expiration)}
                    </span>
                        </Typography>
                      </ContentStyled>

                      {/* {canEdit && (
                  <EditStyled>
                    <Typography
                      tag="a"
                      id="update-payment-method "
                      onClick={() => handleClickUpdatePayment(pm)}
                    >
                      Edit
                    </Typography>
                  </EditStyled>
                )}
                {canEdit && canDelete && <div> | </div>} */}
                      {canDelete && (
                          <DeleteStyled>
                            <Typography
                                tag="a"
                                id="delete-payment-method"
                                style={{ color: 'red' }}
                                onClick={() => handleClickDeletePayment(pm)}
                            >
                              Delete
                            </Typography>
                          </DeleteStyled>
                      )}
                    </ContainerStyled>
                    <DividerStyled/>
                  </React.Fragment>
              );
            })}
        {error && (
            <div style={{ margin: '2rem 0' }}>
              <Error>{error}</Error>
            </div>
        )}

        {canCreate && (
            <div style={{ margin: '1rem 2rem 4rem' }}>
              <Button fullWidth onClick={handleClickAddPayment} loading={loading}>
                Add New Payment
              </Button>
            </div>
        )}

        <Modal
            title={modal.title}
            open={modal.isVisible}
            onClose={() => setModal({ ...modal, isVisible: false })}
        >
          <div style={{ padding: '1rem 2rem' }}>
            {modal.title === 'Create Payment' && (
                <PaymentMethodForm
                    //onSelect={pm => this.handleOnSelectPaymentMethod(pm)}
                    onCancel={() => setModal({ ...modal, isVisible: false })}
                    onCreateViaIFrame={handleOnCreateViaIFrame}
                    showCreateNewPayment={true}
                    showSelector={false}
                    //paymentMethods={userPaymentMethods}
                    defaultMethod={null}
                />
            )}
            {modal.title === 'Delete Payment' && (
                <div>
                  <Typography tag="h4">
                    Are you sure you want to remove this payment method?{' '}
                  </Typography>
                  <ContentStyled
                      style={{
                        margin: '2rem 0',
                        padding: '2rem',
                        border: 'solid 1px #ddd',
                      }}
                  >
                    <Typography tag="h5">{paymentMethod.CardHolderName}</Typography>
                    {/* <Typography>{pm.CardHolderName}</Typography> */}
                    <Typography>**** **** **** {paymentMethod.LastFour}</Typography>
                    <Typography>Expires: {paymentMethod.Expiration}</Typography>
                  </ContentStyled>

                  {deleteDetails && deleteDetails.message && (
                      <div
                          style={{
                            margin: '2rem 0',
                            padding: '2rem',
                            border: 'solid 1px red',
                            ///backgroundColor: "#f5d0d0",
                          }}
                      >
                        <div style={{ margin: '0 0 1rem 0' }}>
                          {deleteDetails.message}
                        </div>

                        {deleteDetails.orders &&
                            Array.isArray(deleteDetails.orders) &&
                            deleteDetails.orders.length > 0 && (
                                <div>
                                  The following orders are affected:
                                  <br/>
                                  {deleteDetails.orders.map((id) => {
                                    return (
                                        <div key={id}>
                                          <Typography
                                              tag="a"
                                              onClick={() =>
                                                  goToUrl('/user/history/orders/' + id)
                                              }
                                          >
                                            {id}
                                          </Typography>
                                        </div>
                                    );
                                  })}
                                </div>
                            )}

                        {deleteDetails.fanRequests &&
                            Array.isArray(deleteDetails.fanRequests) &&
                            deleteDetails.fanRequests.length > 0 && (
                                <div>
                                  The following requests are affected:{' '}
                                  {deleteDetails.fanRequests.map((id) => {
                                    return (
                                        <div key={id}>
                                          <Typography
                                              tag="a"
                                              onClick={() =>
                                                  goToUrl('/user/history/requests/' + id)
                                              }
                                          >
                                            {id}
                                          </Typography>
                                        </div>
                                    );
                                  })}
                                </div>
                            )}

                        {deleteDetails.guestRequests &&
                            Array.isArray(deleteDetails.guestRequests) &&
                            deleteDetails.guestRequests.length > 0 && (
                                <div>
                                  The following guest list requests are affected: <br/>
                                  <br/>
                                  {deleteDetails.guestRequests.map((id) => {
                                    return <div key={id}>{id}</div>;
                                  })}
                                </div>
                            )}
                      </div>
                  )}

                  <div style={{ margin: '1rem 0 4rem' }}>
                    <Button
                        fullWidth
                        onClick={handleClickConfirmDelete}
                        loading={loading || paymentIsLoading}
                        style={{ backgroundColor: '#e84545' }}
                        disabled={paymentIsLoading}
                    >
                      Confirm Delete
                    </Button>
                  </div>
                </div>
            )}
          </div>
        </Modal>
      </div>
  );
}

UserPaymentMethodList.propTypes = {
  canEdit: PropTypes.bool,
  canCreate: PropTypes.bool,
  canDelete: PropTypes.bool,
  canSetDefault: PropTypes.bool,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

UserPaymentMethodList.defaultProps = {
  loading: false,
  error: null,
  canEdit: false,
  canCreate: false,
  canDelete: false,
  canSetDefault: false,
  onCancel: null,
  onSuccess: null,
};

export default UserPaymentMethodList;
