import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../utils/Typography';

/**
 * Component for rendering the header text during the fan request checkout process.
 *
 * The header text changes dynamically based on the current checkout stage and whether mobile delivery is selected.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.stage=""] - The current stage of the checkout process.
 * @param {Array<Object>} [props.stageList=[]] - List of stage details, where each object contains:
 *   @param {string} stageList[].stage - The name of the stage.
 *   @param {string} stageList[].header - The header text for the corresponding stage.
 * @param {boolean} [props.isMobileDelivery=false] - Indicates whether the user has selected mobile delivery.
 *
 * @example
 * const stageList = [
 *   { stage: "details", header: "Event Details" },
 *   { stage: "addons", header: "Add-Ons" },
 *   { stage: "review", header: "Review and Submit" },
 * ];
 *
 * <FanRequestCheckoutHeader
 *   stage="details"
 *   stageList={stageList}
 *   isMobileDelivery={true}
 * />
 *
 * @remarks
 * - If `isMobileDelivery` is `true` and the stage is not "addons", the header is overridden with "Shipping Details".
 * - If no matching stage or header is found, the component returns `null`.
 *
 * @defaultProps
 * - `stage` defaults to an empty string.
 * - `stageList` defaults to an empty array.
 * - `isMobileDelivery` defaults to `false`.
 *
 * @return {JSX.Element|null} - A `<Typography>` element containing the header text, or `null` if no header text is found.
 *
 * @dependencies
 * - `Typography`: Component for rendering styled text.
 */
export const FanRequestCheckoutHeader = (props = {}) => {
  const {
    stage,
    stageList,
    isMobileDelivery,
  } = props;
  const stageDetails = stageList?.find((x) => x.stage === stage);
  let headerText = stageDetails?.header;
  if (isMobileDelivery && stage !== 'addons') { //See RLMTS-4006 for details
    headerText = 'Shipping Details';
  }
  if (headerText) {
    return (
      <Typography tag="h1" style={{ marginBottom: '2rem' }} data-testid="fan-request-checkout-header">
        {headerText}
      </Typography>
    );
  }
  return null;
};
FanRequestCheckoutHeader.defaultProps = {
  stage: '',
  stageList: [],
  isMobileDelivery: false,
};

FanRequestCheckoutHeader.propTypes = {
  stage: PropTypes.string,
  stageList: PropTypes.array,
  isMobileDelivery: PropTypes.bool,
};