import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../utils/Modal';
import Typography from '../../utils/Typography';
import { CheckboxInput } from '../../utils/Form';

/**
 * Component for managing and displaying payment plan enrollment options.
 *
 * This component allows users to enroll or unenroll from a payment plan, view associated policies, 
 * and handle modal interactions for displaying policy details. It integrates with Redux for accessing outlet policies.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.onChangeCheckboxEnrollment - Callback function triggered when the enrollment checkbox is toggled.
 * @param {boolean} [props.userIsEnrolledInPaymentPlan=false] - Indicates whether the user is currently enrolled in the payment plan.
 * @return {React.FC}
 *
 * @state {Object} modal - The state for managing the modal's visibility and content.
 * @state {string} modal.title - The title of the modal.
 * @state {string} modal.content - The HTML content to display within the modal.
 * @state {string|null} modal.constant - A constant value to identify modal content type.
 * @state {boolean} modal.isVisible - Whether the modal is currently visible.
 *
 * @remarks
 * - Fetches policies from the Redux `outlet` state for displaying relevant details.
 * - Displays a modal for viewing detailed policy content when links are clicked.
 * - Handles enrollment state and provides contextual messaging based on enrollment status.
 *
 * @dependencies
 * - `CheckboxInput`: For toggling enrollment in the payment plan.
 * - `Typography`: For styled text rendering.
 * - `Modal`: For displaying policy details.
 *
 * @see {@link useSelector} for Redux state management.
 */
function PaymentPlanEnrollment(props = {}) {
  const { onChangeCheckboxEnrollment, userIsEnrolledInPaymentPlan = false } =
    props;
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
  });
  const outlet = useSelector((state) => state.outlet);

  const handleClickPolicy = (policy) => {
    setModal({
      content: policy.PolicyText,
      title: policy.Headline,
      constant: 'POLICY',
      isVisible: true,
    });
  };

  let ticketGuidlines,
    shippingGuidlines,
    paymenPlanTC,
    paymentPlanPolicy,
    outletPrivacyPolicy,
    estimatedShipDatePolicyText,
    outletTermsAndConditions;
  outlet.OutletPolicies.map((policy) => {
    //Policy Id's to Name
    // 1	Privacy Policy
    // 2	Terms & Conditions
    // 3	Payment Plan Policy
    // 4	Ticket Guidelines
    // 5	Shipping Address
    // 6	External Events
    // 7	Payment Plan T&C
    // 8	Priority Select
    // 9	Pit Upgrade
    // 10	Estimated Shipping Date Text
    if (policy.SalesTypeID === 1 || policy.SalesTypeID === -1) {
      estimatedShipDatePolicyText = policy.OutletPolicyTypeID === 10;
    }
    if (policy.SalesTypeID == -1) {
      if (policy.OutletPolicyTypeID == 1) {
        outletPrivacyPolicy = policy;
      }
      if (policy.OutletPolicyTypeID == 2) {
        outletTermsAndConditions = policy;
      }
    }

    if (policy.SalesTypeID == 2) {
      if (policy.OutletPolicyTypeID == 4) {
        ticketGuidlines = policy;
      }
      if (policy.OutletPolicyTypeID == 5) {
        shippingGuidlines = policy;
      }
    }
    if (policy.OutletPolicyTypeID == 3) {
      paymentPlanPolicy = policy;
    }
    if (policy.OutletPolicyTypeID == 7) {
      paymenPlanTC = policy;
    }
  });
  //const api = useSelector((state) => state.api));
  //const orderIsLoading = api && api.loading.includes("GET_USER_ORDER");
  //if (!request || !seriesSummary) return null;
  return (
    <div>
      <div
        style={{
          display: 'flex',
          margin: '0 0 2rem 0',
          alignItems: 'center',
        }}
      >
        <CheckboxInput
          name="enrollPaymentPlan"
          checked={userIsEnrolledInPaymentPlan}
          onChange={onChangeCheckboxEnrollment}
        />
        <div
          style={{
            flex: 1,
            padding: '0 0 0 .5rem',
          }}
        >
          <Typography>
            Enroll in the{' '}
            {paymentPlanPolicy && paymentPlanPolicy.Headline ? (
              <Typography
                tag="a"
                onClick={() => handleClickPolicy(paymentPlanPolicy)}
              >
                {paymentPlanPolicy.Headline}
              </Typography>
            ) : (
              ' payment plan'
            )}
          </Typography>

          <Typography style={{ fontSize: '.9em' }}>
            {userIsEnrolledInPaymentPlan
              ? 'Uncheck to UnEnroll '
              : 'By enrolling you accept the '}
            {paymenPlanTC && paymenPlanTC.Headline ? (
              <Typography
                tag="a"
                onClick={() => handleClickPolicy(paymenPlanTC)}
              >
                {paymenPlanTC.Headline}
              </Typography>
            ) : userIsEnrolledInPaymentPlan ? (
              ''
            ) : (
              ' terms and conditions'
            )}
          </Typography>
        </div>
      </div>
      <Modal
        title={modal.title}
        open={modal.isVisible}
        onClose={() => setModal({ isVisible: false })}
      >
        {modal.constant === 'POLICY' && (
          <div
            dangerouslySetInnerHTML={{
              __html: modal.content,
            }}
          />
        )}
      </Modal>
    </div>
  );
}

export default PaymentPlanEnrollment;
