import { ApiClient } from './api-client';
import { getLambdaURL } from './api';


/**
 * API Wrapper around Guest Ticketing service
 */
export class GTService {
  constructor() {
    const lambdaApiUrl = getLambdaURL();
    const apiUrl = lambdaApiUrl;
    this.apiClient = new ApiClient(apiUrl, {
      credentials: 'include', // send cookies with request needed to authenticate the request
    });
  }

  /**
   * Creates a new fan request
   * @param {object} payload payload
   * @return {object}
   */
  createFanRequest = async (payload) => {
    try {
      const res = await this.apiClient.post(`tixrequests/oegrequest`, payload);
      const response = await res.json();
      return response;
    } catch (err) {
      console.error('API Error inside createFanRequest: ', err);
      return {};
    }
  };

  /**
   * Add event to existing fan request
   * @param {object} payload payload
   * @return {object}
   */
  addToFanRequest = async (payload) => {
    try {
      const res = await this.apiClient.post(`tixrequests/oerequest`, payload);
      const response = await res.json();
      return response;
    } catch (err) {
      console.error('API Error inside addToFanRequest: ', err);
      return {};
    }
  };

  /**
   * Edits a fan request
   * @param {number} id oe request id
   * @param {object} payload payload
   * @return {object}
   */
  editFanRequest = async (id, payload) => {
    try {
      const res = await this.apiClient.put(`tixrequests/oerequest/${id}`, payload);
      const response = await res.json();
      return response;
    } catch (err) {
      console.error('API Error inside editFanRequest: ', err);
      return {};
    }
  };

  /**
   * Deletes a fan request
   * @param {number} id oe request id
   * @return {object}
   */
  deleteFanRequest = async (id, payload) => {
    try {
      const res = await this.apiClient.delete(`tixrequests/oerequest/${id}`, payload);
      const response = await res.json();
      return response;
    } catch (err) {
      console.error('API Error inside deleteFanRequest: ', err);
      return {};
    }
  };

  /**
   * Update payment method on the outlet event group (OEG) request
   * @param {number} id oeg request id
   * @param {object} payload payload
   * @return {object}
   */
  updatePaymentInfo = async (id, payload) => {
    try {
      const res = await this.apiClient.put(`tixrequests/oegrequest/${id}`, payload);
      // api does not return a response for this PUT request
      if (res.ok) {
        return {};
      }

      const response = await res.json();
      return response;
    } catch (err) {
      console.error('API Error inside updatePaymentInfo: ', err);
      return {};
    }
  };

  /**
   * Update shipping address on the outlet event group (OEG) request
   * @param {number} id oeg request id
   * @param {object} payload payload
   * @return {object}
   */
  updateShippingAddress = async (id, payload) => {
    try {
      const res = await this.apiClient.put(`tixrequests/oegrequest/${id}`, payload);
      // api does not return a response for this PUT request
      if (res.ok) {
        return {};
      }

      const response = await res.json();
      return response;
    } catch (err) {
      console.error('API Error inside updateShippingAddress: ', err);
      return false;
    }
  };
}
