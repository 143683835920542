import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Collapse from '../utils/Collapse';
import Columns from '../utils/Columns';
import Contain from '../components/app/Contain';
import Typography from '../utils/Typography';
import UserAddressList from '../components/user/UserAddressList';
import UserPasswordUpdate from '../components/user/UserPasswordUpdate';
import UserPaymentMethodList from '../components/user/UserPaymentMethodList';
import UserProfileUpdate from '../components/user/UserProfileUpdate';
import { getUserAddressList, getUserPaymentMethodList } from '../components/user/actions';
import { goToUrl } from '../utils/utilities';

const RightColumnContent = styled.div`
  padding: 0 0 10rem 0;
`;

const RightColumnTitleContainer = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_LINK_COLOR};
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    padding: 2rem 0;
  }
`;

const Section = styled.div`
  margin: 0 0 0 0;
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;

const CollapseContentStyled = styled.div`
  padding: 1rem 2rem;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    padding: 1rem 0.5rem;
  }
`;

const FakeCollapse = styled.div`
  padding: 1rem 0;
  cursor: pointer;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;

/**
 * AccountView Component
 *
 * A user account management view displaying the user's profile, order history, saved addresses,
 * and saved payment methods. It fetches user data on mount and renders sections for different
 * account-related functionalities.
 *
 * @component
 *
 * @description
 * - Fetches user addresses and payment methods on component mount.
 * - Displays the user's profile details, including name, email, and external IDs.
 * - Provides collapsible sections for:
 *   - Order History
 *   - Profile Update
 *   - Password Update
 *   - Saved Addresses
 *   - Saved Payments
 * - Integrates state selectors to access session data, user details, addresses, and payment methods.
 *
 * @return {React.ReactElement} The rendered AccountView component.
 *
 * @dependencies
 * - Redux state selectors for `session`, `api`, `userAddressList`, `userPaymentMethodList`, and `outlet`.
 * - External utility functions: `getUserAddressList`, `getUserPaymentMethodList`.
 *
 * @notes
 * - Conditional rendering ensures sections like "Change Password" are hidden for external users.
 * - Uses various components such as:
 *   - `UserProfileUpdate` for updating user profile.
 *   - `UserPasswordUpdate` for changing passwords.
 *   - `UserAddressList` for managing user addresses.
 *   - `UserPaymentMethodList` for managing payment methods.
 */

export function AccountView() {
  const session = useSelector((state) => state.session);
  const FirstName = session && session.FirstName;
  const LastName = session && session.LastName;
  const EmailAddress = session && session.EmailAddress;
  const UserID = session && session.UserID;
  const ExternalUserName = session && session.ExternalUserName;
  const ExternalUserID = session && session.ExternalUserID;
  const userPaymentMethodList = useSelector((state) => state.userPaymentMethodList);
  const outlet = useSelector((state) => state.outlet);
  const userAddressList = useSelector((state) => state.userAddressList);
  const api = useSelector((state) => state.api);
  const addressApiConstants = [
    'GET_USER_ADDRESS_LIST',
    'DELETE_USER_ADDRESS',
    'CREATE_USER_ADDRESS',
    'UPDATE_USER_ADDRESS',
  ];
  const addressIsLoading = api && api.loading.some((r) => addressApiConstants.includes(r));
  const addressError = api && api.error.some((r) => addressApiConstants.includes(r));

  useEffect(() => {
    getUserAddressList();
    getUserPaymentMethodList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Contain fillHeight hasPaddingMobile={false}>
      <Columns qty={1}>
        <div>
          <RightColumnContent>
            <RightColumnTitleContainer>
              <Typography tag="h1">My Account</Typography>
              {(ExternalUserName || ExternalUserID || UserID) && (
                <div style={{ opacity: '.8' }}>
                  {ExternalUserName && <div>Member Name: {ExternalUserName}</div>}
                  {ExternalUserID && <div>Member ID: {ExternalUserID}</div>}
                </div>
              )}
            </RightColumnTitleContainer>
            {outlet.OutletTypeID !== 3 && (
              <Section onClick={() => goToUrl('/user/history/orders')}>
                <FakeCollapse>
                  <Typography tag="h2">ORDER HISTORY</Typography>
                </FakeCollapse>
              </Section>
            )}
            <Section>
              <Collapse heading="Profile">
                <CollapseContentStyled>
                  <UserProfileUpdate
                    EmailAddress={EmailAddress}
                    FirstName={FirstName}
                    LastName={LastName}
                    UserID={UserID}
                    ExternalUserID={ExternalUserID}
                  />
                </CollapseContentStyled>
              </Collapse>
            </Section>
            {!ExternalUserID && (
              <Section>
                <Collapse heading="Change Password">
                  <CollapseContentStyled>
                    <UserPasswordUpdate emailAddress={EmailAddress} />
                  </CollapseContentStyled>
                </Collapse>
              </Section>
            )}
            <Section>
              <Collapse heading="Saved Addresses">
                <CollapseContentStyled>
                  <UserAddressList
                    addressList={userAddressList}
                    canEdit={true}
                    canCreate={true}
                    canDelete={true}
                    loading={addressIsLoading}
                    error={addressError}
                  />
                </CollapseContentStyled>
              </Collapse>
            </Section>
            <Section>
              <Collapse heading="Saved Payments">
                <CollapseContentStyled>
                  <UserPaymentMethodList
                    paymentMethodList={userPaymentMethodList}
                    canEdit={false}
                    canCreate={true}
                    canDelete={true}
                  />
                </CollapseContentStyled>
              </Collapse>
            </Section>
          </RightColumnContent>
        </div>
      </Columns>
    </Contain>
  );
}

export default AccountView;
