import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState } from 'react';
import Typography from '../../utils/Typography';
import { IconMinus, IconPlus } from '../../utils/Icon';

const rowPadding = '.8rem';
const RowStyled = styled(Typography)`
  border-left: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
  border-right: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
  border-bottom: ${(props) =>
          props.borderBottom ? 'solid 1px ' + props.theme.PRIMARY_GRAY : 'none'};
  border-top: ${(props) =>
          props.borderTop ? 'solid 1px ' + props.theme.PRIMARY_GRAY : 'none'};
  padding: ${rowPadding};
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  background-color: ${(props) => (props.highlighted ? '#e3e211' : 'initial')}70;
`;

const EventTotalLineStyled = styled.div`
  display: flex;
  padding: ${(props) => (props.background ? props.padding : rowPadding)};
  background-color: ${(props) =>
    props.background ? props.theme.PRIMARY_GRAY : 'initial'};
  border-left: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
  border-right: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
  font-weight: 700;
`;

const Label = styled.div`
  flex: 1;
`;
const Total = styled.div`
  padding: 0 0 0 0.5rem;
`;

/**
 * Component to display a summary of checkout totals and fan requests for a series.
 * 
 * This component handles rendering a detailed view of the user's checkout summary, 
 * including event details, shipping total, payment plan fees, and maximum series total.
 * It also allows toggling the visibility of all requests in the series.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.cart - The shopping cart data.
 * @param {Object} props.outletEvent - The primary outlet event information.
 * @param {Object} props.seriesSummary - Summary details for the event series, including totals and requests.
 * @param {boolean} props.allowShowAllRequest - Whether the "Show All Requests" option should be available.
 * @param {boolean} props.seriesTotalIsVisible - Flag to control the visibility of the series total section.
 * @param {boolean} props.seriesShippingTotalIsVisible - Flag to control the visibility of the shipping total section.
 * 
 * @return {JSX.Element|null} - The rendered component or null if required props are missing.
 *
 * @example
 * <CheckoutTotalsSummaryFanRequest
 *   cart={cartData}
 *   outletEvent={outletEventData}
 *   seriesSummary={seriesSummaryData}
 *   allowShowAllRequest={true}
 *   seriesTotalIsVisible={true}
 *   seriesShippingTotalIsVisible={true}
 * />
 *
 * @remarks
 * - This component handles conditional rendering based on the availability of props.
 * - Shipping total is dynamically calculated based on delivery methods and country.
 * - Displays detailed information for each request in the series if available.
 */
export default function CheckoutTotalsSummaryFanRequest(props = {}) {
  const [allRequestsAreVisible, setAllRequestsAreVisible] = useState(false);
  const {
    cart,
    outletEvent,
    seriesSummary,
    allowShowAllRequest,
    seriesTotalIsVisible,
    seriesShippingTotalIsVisible,
  } = props;

  if (!outletEvent || !Object.keys(outletEvent).length || !seriesSummary) {
    return null;
  }
  const { OutletEventGroup, LotteryOERequests } = seriesSummary;

  let userRequestedAnOptOut = false;

  if (Array.isArray(LotteryOERequests)) {
    LotteryOERequests.map((x) => {
      if (Array.isArray(x.LotteryOEIRequests)) {
        x.LotteryOEIRequests.map((y) => {
          if (y.PriceLevelOptOut) {
            userRequestedAnOptOut = true;
          }
          return y;
        });
      }
      return x;
    });
  }
  //Start RLMTS-2839
  let shippingTotal = 0;
  if (
      seriesSummary &&
      seriesSummary.Totals &&
      seriesSummary.Totals.shipping > -1
  ) {
    shippingTotal = seriesSummary.Totals.shipping;

    if (
        cart &&
        cart.shippingAddress &&
        OutletEventGroup &&
        Array.isArray(OutletEventGroup.OutletDeliveryMethods)
    ) {
      const {
        OutletDeliveryMethods,
        LRDomOutletDeliveryMethodID,
        LRIntlOutletDeliveryMethodID,
      } = OutletEventGroup;
      const { CountryID } = cart.shippingAddress;
      let deliveryMethod;
      if (Number(CountryID) === 1 && LRDomOutletDeliveryMethodID) {
        deliveryMethod = OutletDeliveryMethods.find(
            (x) =>
                Number(x.OutletDeliveryID) === Number(LRDomOutletDeliveryMethodID),
        );
      }
      if (Number(CountryID) !== 1 && LRIntlOutletDeliveryMethodID) {
        deliveryMethod = OutletDeliveryMethods.find(
            (x) =>
                Number(x.OutletDeliveryID) === Number(LRIntlOutletDeliveryMethodID),
        );
      }
      if (deliveryMethod && deliveryMethod.OverridePrice > 0) {
        shippingTotal = deliveryMethod.OverridePrice;
      }
    }
    //End RLMTS-2839
    return (
        <div>
          {allowShowAllRequest && (
              <RowStyled opacity={0.8} borderBottom={true} borderTop={true}>
                <div
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => setAllRequestsAreVisible(!allRequestsAreVisible)}
                >
                  <Typography tag="h5">
                    {allRequestsAreVisible ? 'Hide' : 'Show'} all requests in this
                    series
                  </Typography>
                  {allRequestsAreVisible ? <IconMinus/> : <IconPlus/>}
                </div>
              </RowStyled>
          )}
          {allRequestsAreVisible && Array.isArray(LotteryOERequests) ? (
              <div>
                {LotteryOERequests.map((request, idx) => {
                  const Event = request.OutletEvent && request.OutletEvent.Event;
                  const Venue = Event && Event.Venue;
                  let currentEvent = Boolean(
                      Number(outletEvent.OutletEventID) ===
                      Number(request.OutletEvent.OutletEventID),
                  );

                  return (
                      <RowStyled
                          key={idx}
                          highlighted={currentEvent}
                          borderBottom={true}
                      >
                        {Venue && (
                            <React.Fragment>
                              <div>{Venue.VenueName}</div>
                              <div>
                                {Venue.City}, {Venue.State}
                              </div>
                            </React.Fragment>
                        )}

                        <div>
                          {Event && Event.ShowDate
                              ? new Date(Event.ShowDate).toLocaleDateString('en-US', {
                                weekday: 'long',
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                                timeZone: 'America/New_York',
                              })
                              : 'Event Info not available'}
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Label>
                            <strong></strong>Event Maximum Total
                          </Label>
                          <Total>
                            {request.Totals &&
                                request.Totals.total &&
                                new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                }).format(request.Totals.total)}
                          </Total>
                        </div>
                      </RowStyled>
                  );
                })}
              </div>
          ) : null}
          {shippingTotal > -1 && seriesShippingTotalIsVisible && (
              <EventTotalLineStyled bold={true}>
                <Label>Shipping</Label>
                <Total>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(shippingTotal)}
                </Total>
              </EventTotalLineStyled>
          )}
          {seriesSummary &&
              seriesSummary.PaymentPlanEnrolled &&
              seriesSummary.Totals.ppfee && (
                  <EventTotalLineStyled bold={true}>
                    <Label>Payment Plan Fee</Label>
                    <Total>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(seriesSummary.Totals.ppfee)}
                    </Total>
                  </EventTotalLineStyled>
              )}
          {seriesTotalIsVisible && (
              <EventTotalLineStyled background={100} padding={rowPadding}>
                <Label>
                  <Typography tag="h4">Maximum Series Total </Typography>
                  {userRequestedAnOptOut && (
                      <div style={{ fontWeight: 'normal', fontSize: '1.2rem' }}>
                        Opting out of price levels reduces your chances of being
                        confirmed for tickets
                      </div>
                  )}
                </Label>
                <Total>
                  <Typography tag="h4">
                    {seriesSummary &&
                        seriesSummary.Totals &&
                        new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(seriesSummary.Totals.total)}
                  </Typography>
                </Total>
              </EventTotalLineStyled>
          )}
        </div>
    );
  }
}

CheckoutTotalsSummaryFanRequest.propTypes = {
  outletEvent: PropTypes.object.isRequired,
  seriesSummary: PropTypes.object,
  allowShowAllRequest: PropTypes.bool,
  seriesTotalIsVisible: PropTypes.bool,
  seriesShippingTotalIsVisible: PropTypes.bool,
};

CheckoutTotalsSummaryFanRequest.defaultProps = {
  outletEvent: null,
  seriesSummary: null,
  allowShowAllRequest: true,
  seriesTotalIsVisible: true,
  seriesShippingTotalIsVisible: true,
};
