import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Alert from '../utils/Alert';
import Attention from '../utils/Attention';
import AuthContainer from '../components/auth/AuthContainer';
import Button from '../utils/Button';
import CheckoutAddons from '../components/checkout/CheckoutAddons';
import Collapse from '../utils/Collapse';
import Columns from '../utils/Columns';
import Contain from '../components/app/Contain';
import DisplayError from '../utils/Error';
import Modal from '../utils/Modal';
import OutletEventDetails from '../components/outletEvent/OutletEventDetails';
import PriceLevelSelectorFanRequest from '../components/priceLevel/PriceLevelSelectorFanRequest';
import PriceLevelSelectorInstant from '../components/priceLevel/PriceLevelSelectorInstant';
import Typography from '../utils/Typography';
import { calculateSeriesStatuses, goToUrl, htmlHasValidContent } from '../utils/utilities';
import {
  deleteInstantRequest,
  resetFanRequestCart,
  resetGuestListCart,
  updateFanRequest,
  updateInstantRequest,
} from '../components/cart/actions';
import { getUserFanRequestSeriesSummary, getUserFanRequests } from '../components/user/actions';

const LeftColumStyled = styled.div`
  margin: 2rem 0 0 0;
`;
const RightColumStyled = styled.div`
  margin: 2rem 0 0 0;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
  }
`;
const ImportantInfoBox = styled.div`
  border: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
  background-color: ${(props) => props.theme.PRIMARY_GRAY}70;
  padding: 1rem;

  margin: 0 0 2rem 0;
`;
const ImportantInfoBoxHeader = styled.div`
  margin: 0 0 1rem 0;
  text-align: center;
  font-weight: 700;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;
const ImportantInfoBoxContent = styled.div`
  //padding: 1rem;
`;
const ErrorTextStyled = styled.div`
  font-size: 1.4rem;
  margin: 0;
  padding: 0.5rem 0rem;
  font-style: italic;
  color: ${(props) => props.theme.ERROR_TEXT_COLOR};
`;
const PriceLevelContainerStyled = styled.div`
  padding: 0 1rem;
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
`;

/**
 * Component for displaying and managing a single event on an outlet.
 * This component provides functionality for selecting tickets, managing requests,
 * and interacting with an event's details, including lotteries, instant purchases,
 * and associated policies.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @description
 * The `EventView` component is designed to handle various aspects of an outlet's event,
 * such as displaying event details, selecting price levels, managing user requests, and
 * handling interactions like logging in, adding items to a request, or navigating to a checkout page.
 *
 * @functionality
 * - Handles fetching and displaying event details based on `OutletEventID`.
 * - Manages modals for policies, addons, login, and fan request updates.
 * - Supports both instant ticket purchases and fan request workflows.
 * - Ensures proper navigation for authenticated and unauthenticated users.
 * - Dynamically updates the page title based on event information.
 *
 * @dependencies
 * - React hooks: `useState`, `useEffect`.
 * - React Router hooks: `useParams`.
 * - Redux hooks: `useSelector`.
 *
 * @state
 * - `modal` {Object}: Tracks the modal's visibility and content.
 * - `outletEvent` {Object|null}: The current event's details.
 * - `selectedPriceLevels` {Array}: Selected price levels for instant purchases.
 * - `selectedLotteryItems` {Array}: Selected lottery items for fan requests.
 * - `selectedRequestAddons` {Array}: Add-ons selected for the current request.
 *
 * @reduxState
 * - `auth.userIsAuthenticated` {boolean}: User authentication status.
 * - `api` {Object}: Tracks API call states.
 * - `outlet` {Object}: Information about the outlet.
 * - `eventList` {Array}: List of available events for the outlet.
 * - `userFanRequestList` {Array}: List of user's fan requests.
 * - `instantRequest` {Object}: State of the user's instant request.
 * - `fanRequest` {Object}: State of the user's fan request.
 * - `userFanRequestSeriesSummaryList` {Array}: List of user's series summaries.
 * - `session` {Object}: Current user session details.
 *
 * @methods
 * - `handleClickPolicy(policy)`: Opens a modal to display a policy's details.
 * - `handleOnChangeLotteryRequest(formdata, outletEventItem, existingLotteryOEIRequest)`: Updates selected lottery items.
 * - `handleOnChangePriceLevel(priceLevel, increment)`: Adjusts the quantity of a selected price level.
 * - `handleOnSubmitInstant()`: Initiates an instant purchase and navigates to the review page.
 * - `handleRequestAddOnQtyChange(selectedPriceLevels)`: Updates the selected add-ons for a request.
 * - `showAddonsModal(outletEvent)`: Displays a modal for selecting add-ons.
 * - `handleOnSubmitFanRequest()`: Creates or updates a fan request and handles related logic.
 * - `handleClickRefreshPriceLevels(refetch)`: Refreshes the price levels (currently a placeholder).
 * - `renderSeriesMessage(outletEvent)`: Renders informational messages about the event's series.
 * - `handleClickLoginButton()`: Redirects the user to a login page or shows a login modal.
 * - `handleOnLogin()`: Closes the login modal upon successful login.
 *
 * @returns {JSX.Element} JSX rendering of the `EventView` component or a warning alert if no event data is available.
 */

function EventView() {
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
  });
  const { OutletEventID } = useParams();
  const [outletEvent, setOutletEvent] = useState(null);
  const [selectedPriceLevels, setSelectedPriceLevels] = useState([]);
  const [selectedLotteryItems, setSelectedLotteryItems] = useState([]);
  const [selectedRequestAddons, setSelectedRequestAddons] = useState([]);
  const userIsAuthenticated = useSelector(
    (state) => state.auth.userIsAuthenticated,
  );
  const api = useSelector((state) => state.api);
  const outlet = useSelector((state) => state.outlet);
  const outletEventList = useSelector((state) => state.eventList);
  const userFanRequestList = useSelector((state) => state.userFanRequestList);
  const instantRequest = useSelector((state) => state.instantRequest);
  const fanRequest = useSelector((state) => state.fanRequest);
  const userFanRequestSeriesSummaryList = useSelector((state) => state.userFanRequestSeriesSummaryList);
  const session = useSelector((state) => state.session);

  const UserID = session && session.UserID;

  /**
   * Effect hook to initialize and manage the state and behavior for an outlet event.
   *
   * @param {Array} dependencies The dependencies that trigger this effect: `[outletEvent]`.
   *
   * @description
   * - Resets user-related cart data, including guest list, fan requests, and instant requests.
   * - Searches for and sets the outlet event based on `OutletEventID` and updates the state with the found event.
   * - Dynamically updates the document title to reflect the current event's details, including venue name, event date, and type (request or buy).
   * - Cleans up by restoring the original outlet name as the document title when the component unmounts or dependencies change.
   *
   * @dependencies
   * - `OutletEventID` {number|string}: The ID of the outlet event to be processed.
   * - `outletEventList` {Array}: List of outlet events used to find the relevant event.
   * - `outlet` {Object}: Contains information about the current outlet, including its name.
   *
   * @sideEffects
   * - Updates the browser's document title to reflect the current event.
   * - Resets multiple user-related cart states at the beginning of the effect.
   *
   * @cleanup
   * - Restores the original outlet name as the document title upon cleanup.
   */
  useEffect(() => {
    resetGuestListCart();
    resetFanRequestCart();
    deleteInstantRequest();

    const outletEventFound =
      OutletEventID &&
      outletEventList &&
      outletEventList.find((oe) => Number(oe.OutletEventID) === Number(OutletEventID));

    if (outletEventFound) {
      setOutletEvent(outletEventFound);
      setSelectedPriceLevels([]);
      const eventData = outletEventFound.Event;
      const eventdate = eventData && eventData.ShowDate ? new Date(eventData.ShowDate) : '';
      const venueName = eventData && eventData.Venue ? eventData.Venue.VenueName : '';

      document.title =
        (outletEventFound.SalesTypeID === 2 ? 'Request - ' : 'Buy - ') +
        venueName +
        ' - ' +
        (eventdate.getMonth() + 1) +
        '/' +
        eventdate.getDate() +
        ' | ' +
        outlet.OutletName;
    }

    return function cleanup() {
      document.title = outlet.OutletName;
    };
  }, [outletEvent]);

  /**
   * Effect hook to fetch user fan request data and series summaries on component mount.
   *
   * @description
   * - Fetches the user's fan request series summary to determine if the current event can be added to an existing Outlet Event Group Request (OEGR).
   * - Fetches the user's fan requests to check if they can make a new request for the event based on their existing requests.
   *
   * @dependencies
   * - This effect runs only once, on component mount, as it has an empty dependency array.
   *
   * @param {number|string} UserID The unique identifier for the current user.
   *
   *
   */
  useEffect(() => {
    getUserFanRequestSeriesSummary(); // needed to see if the current event should be added to an existing OEGR
    getUserFanRequests(UserID); // needed to see if user can make a new request for event based on the current list of requests
  }, []);

  const handleClickPolicy = (policy) => {
    setModal({
      content: policy.PolicyText,
      title: policy.Headline,
      constant: 'POLICY',
      isVisible: true,
    });
  };

  const handleOnChangeLotteryRequest = (
    formdata = {},
    outletEventItem,
    existingLotteryOEIRequest,
  ) => {
    const OutletEventItemID =
      outletEventItem && parseInt(outletEventItem.OutletEventItemID, 10);
    const LotteryOEIRequestUUID =
      existingLotteryOEIRequest &&
      existingLotteryOEIRequest.LotteryOEIRequestUUID;

    if (OutletEventItemID) {
      const requestedItem = {
        LotteryOEIRequestUUID,
        PriceLevelID: parseInt(Number(formdata.selectedPriceLevelId), 10),
        Quantity: parseInt(formdata.selectedQty, 10),
        OutletEventItemID,
        isAddOn: false,
        PriceLevelOptOut: formdata.optOutIsSelected,
      };

      const selectedLotteryItemsUpdated = [...selectedLotteryItems];
      const index = selectedLotteryItems.findIndex(
        (item) => item.OutletEventItemID === OutletEventItemID,
      );

      if (index > -1) {
        selectedLotteryItemsUpdated[index] = requestedItem;
      } else {
        selectedLotteryItemsUpdated.push(requestedItem);
      }
      setSelectedLotteryItems(selectedLotteryItemsUpdated);
    }
  };

  const handleOnChangePriceLevel = (priceLevel, increment = 1) => {
    const selectedPriceLevelsUpdated = [...selectedPriceLevels];
    const index = selectedPriceLevels.findIndex(
      (pl) => pl.PriceLevelID === priceLevel.PriceLevelID,
    );

    if (index === -1) {
      const selectedPriceLevel = { ...priceLevel };
      selectedPriceLevel.quantity = increment;
      selectedPriceLevelsUpdated.push(selectedPriceLevel);
    } else {
      const spl = selectedPriceLevelsUpdated[index];
      spl.quantity = spl.quantity + increment;
      if (spl.quantity < 1) {
        selectedPriceLevelsUpdated.splice(index);
      }
    }
    setSelectedPriceLevels(selectedPriceLevelsUpdated);
  };

  const handleOnSubmitInstant = () => {
    // clean up any existing (old) cart data before creating new
    deleteInstantRequest();

    const lineItems = selectedPriceLevels.map((li) => {
      const formatted = {
        priceLevelID: li.PriceLevelID,
        quantity: li.quantity,
      };
      return formatted;
    });

    const config = {
      action: 'INSTANT_REQUEST_CREATE_CART',
      requestBody: { lineItems },
      outletEvent,
    };

    updateInstantRequest(config).then((res) => {
      goToUrl('/checkout/i/review', { replace: false });
    }).catch((error) => {
      // alert("error");
      // if(error=="Not enough inventory to fulfill request.")
      // if (error == "Insufficient Inventory") {
      // setModalTitle("Unable to complete request");
      // setModalContent(error);
      // setModalConstant("QTY_REQUESTED");
      // setModalIsVisible(true);
      // }
    });
  };

  const handleRequestAddOnQtyChange = (selectedPriceLevels) => {
    const selectedAddonsFormattted = selectedPriceLevels
      // .filter((p) => p.isAddOn)
      .map((spl) => {
        const quantity = parseInt(Number(spl.quantity || spl.Quantity, 10));
        const priceLevelID = parseInt(Number(spl['PriceLevelID'], 10));
        let outletEventItemID =
          spl.OutletEventItemID || (spl.oei && spl.oei.OutletEventItemID);
        outletEventItemID = parseInt(Number(outletEventItemID, 10));
        return {
          ...spl,
          quantity,
          Quantity: quantity,
          priceLevelID,
          isAddOn: true,
          outletEventItemID,
        };
      });
    setSelectedRequestAddons(selectedAddonsFormattted);
  };

  const showAddonsModal = (outletEvent) => {
    const outletEventItemsWithAddons =
      outletEvent && Array.isArray(outletEvent.OutletEventItems)
        ? outletEvent.OutletEventItems.filter(
          (oei) => oei?.EventItem?.SuppressOnEDP,
        ).filter((oei) => (oei.PriceLevels || []).length !== 0)
        : [];

    let priceLevelsAreAvailable = false;

    if (outletEventItemsWithAddons.length) {
      outletEventItemsWithAddons.map((oei) => {
        if (oei.PriceLevels && oei.PriceLevels.length) {
          oei.PriceLevels.map((priceLevel, plidx) => {
            if (priceLevel.SaleStatus !== 'OFF_SALE') {
              priceLevelsAreAvailable = true;
            }
            return null;
          });
        }
      });
    }

    if (priceLevelsAreAvailable) {
      setModal({
        content: outletEventItemsWithAddons,
        title: 'Addons',
        constant: 'FAN_REQUEST_ADDONS',
        isVisible: true,
      });
    }
  };

  const handleOnSubmitFanRequest = () => {
    const lineItems = Array.isArray(selectedLotteryItems) ? selectedLotteryItems.filter((li) => li.PriceLevelID) : null;

    if (lineItems && lineItems.length && outletEvent) {
      // RLMTS-2694 does this event request belong to an existing series request
      // if so, skip remaining checkout stage and send user to My requests page
      let existingSeriesRequest;
      const OutletEventGroupID =
        outletEvent.OutletEventGroup &&
        outletEvent.OutletEventGroup.OutletEventGroupID;

      if (OutletEventGroupID && Array.isArray(userFanRequestSeriesSummaryList)) {
        existingSeriesRequest = userFanRequestSeriesSummaryList.find(
          (x) => Number(x.OutletEventGroupID) === OutletEventGroupID,
        );
      }

      const addonsAvailable =
        outletEvent &&
        outletEvent.OutletEventItems &&
        outletEvent.OutletEventItems.some(
          (oei) => oei?.EventItem?.SuppressOnEDP,
        );

      if (existingSeriesRequest) {
        const lineItemsMerged = [...lineItems];
        if (
          Array.isArray(selectedRequestAddons) &&
          selectedRequestAddons.length
        ) {
          selectedRequestAddons.map((addon) => {
            const { OutletEventItemID, PriceLevelID } = addon;
            lineItemsMerged.push({
              OutletEventItemID,
              PriceLevelID,
              isAddOn: true,
              PriceLevelOptOut: false,
              Quantity: addon.quantity || addon.qty,
            });
          });
        }
        const config = {
          action: 'SAVE_FAN_REQUEST',
          cart: { lineItems: lineItemsMerged },
          outletEvent,
        };

        // Start RLMTS-2871 show user message if they are newly eligible for payment plan
        const paymentPlanIsAvailable = Boolean(
          existingSeriesRequest &&
          existingSeriesRequest.OutletEventGroup &&
          existingSeriesRequest.OutletEventGroup.OfferPaymentPlan,
        );
        const userIsEnrolledInPaymentPlan =
          existingSeriesRequest && existingSeriesRequest.PaymentPlanEnrolled;

        const existingSeriesTotal =
          (existingSeriesRequest.Totals &&
            existingSeriesRequest.Totals.total) ||
          0;

        let adjustedSeriesTotal = existingSeriesTotal;

        if (Array.isArray(lineItemsMerged)) {
          lineItemsMerged.map((li) => {
            const { OutletEventItemID, PriceLevelID, Quantity } = li;

            if (outletEvent && outletEvent.OutletEventItems) {
              const oeiFound = outletEvent.OutletEventItems.find(
                (oei) =>
                  Number(oei.OutletEventItemID) === Number(OutletEventItemID),
              );
              if (oeiFound && oeiFound.PriceLevels) {
                const plFound = oeiFound.PriceLevels.find(
                  (pl) => Number(pl.PriceLevelID) === Number(PriceLevelID),
                );
                if (plFound && plFound.PriceLevelValue && Quantity) {
                  adjustedSeriesTotal += plFound.PriceLevelValue * Quantity;
                }
              }
            }
          });
        }
        let userIsNewlyEligibleForPaymentPlan = false;

        if (outlet && Array.isArray(outlet.OutletEventGroups)) {
          const oegFound = outlet.OutletEventGroups.find(
            (oeg) =>
              Number(oeg.OutletEventGroupID) ===
              Number(outletEvent.OutletEventGroup.OutletEventGroupID),
          );
          if (oegFound && oegFound.PaymentPlanRules) {
            userIsNewlyEligibleForPaymentPlan =
              !userIsEnrolledInPaymentPlan &&
              paymentPlanIsAvailable &&
              adjustedSeriesTotal >=
              Number(oegFound.PaymentPlanRules.eligibilityMinimum);
          }
        }
        // End RLMTS-2871
        updateFanRequest(config)
          .then((res) => {

            // if API does not return a response we should not run any of the code below this
            if (!res) {
              return;
            }

            setSelectedRequestAddons([]);
            getUserFanRequests();
            let myRequestUrl = '/user/history/requests';
            if (existingSeriesRequest && existingSeriesRequest.RequestID) {
              myRequestUrl += '/' + existingSeriesRequest.RequestID;
            }
            setModal({
              content: myRequestUrl,
              title: 'Successfully updated request',
              constant: 'FAN_REQUEST_UPDATE',
              isVisible: true,
              userIsNewlyEligibleForPaymentPlan,
            });
          })
          .catch((err) => {
            console.error('Unable to handle request. ', err);
          });
      } else {
        const stage = addonsAvailable ? 'addons' : 'shipping-address';
        const checkoutUrl = '/checkout/f/' + stage;

        // Fan request example
        // LotteryOEIRequestUUID: undefined
        // OutletEventItemID: 578
        // PriceLevelID: 957
        // PriceLevelOptOut: true
        // Quantity: 4
        // isAddOn: false

        // Instant cart example
        // lineItems: [
        //   {
        //     priceLevelID: 955,
        //     quantity: 4,
        //     seatInfo: {
        //       priceLevel: {
        //         priceLevelName: "Gold reserve seats",
        //         itemName: "Tickets",
        //       },
        //     },
        //     held: true,
        //   }
        updateFanRequest({
          outletEvent,
          action: 'CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY',
          cart: { lineItems },
        }).then((res) => {
          setSelectedRequestAddons([]);
          goToUrl(checkoutUrl);
        });
      }
    } else {
      alert('invalid form');
      return false;
    }
  };

  const handleClickRefreshPriceLevels = (refetch) => {
  };

  const renderSeriesMessage = (outletEvent) => {
    if (!outletEvent || !outletEvent.OutletEventGroup) return null;

    const { seriesIsVisible, seriesIsOpen, seriesIsClosed } =
      calculateSeriesStatuses(outletEvent.OutletEventGroup);
    const { SeriesVisibleMessage, SeriesClosedMessage } =
      outletEvent.OutletEventGroup;
    let customText = '';

    if (seriesIsVisible && seriesIsOpen == false && !seriesIsClosed) {
      customText = SeriesVisibleMessage ? SeriesVisibleMessage : '';
    } else if (seriesIsClosed) {
      customText = SeriesClosedMessage ? SeriesClosedMessage : '';
    }

    if (seriesIsClosed || (seriesIsVisible && seriesIsOpen == false)) {
      let hyperlinkText = 'On Sale Now';
      const ev = outletEvent.Event;

      if (ev.PublicOnsaleURL) {
        // if ev.PublicOnsaleDate == null
        // add "On Sale Now" link that links to ev.PublicOnsaleURL
        if (ev.PublicOnsaleDate == null) {
          customText =
            customText +
            `<a href=${ev.PublicOnsaleURL}>${ev.PublicOnSaleTextOverride
              ? ev.PublicOnSaleTextOverrideText
              : hyperlinkText
            }</a>`;
        } else {
          // if outlet.Event.PublicOnsaleDate != null
          const saleDate = new Date(ev.PublicOnsaleDate);
          if (saleDate > new Date()) {
            const dateStr = saleDate.toLocaleString('en-US', {
              day: 'numeric',
              year: 'numeric',
              month: 'long',
              timeZone: 'America/New_York',
            });
            const timeStr = saleDate.toLocaleString('en-US', {
              timeStyle: 'short',
              timeZone: 'America/New_York',
            });

            // if date is in the future
            // �Public Onsale: [POS month] [POS date], 2023 at [POS time (if time is set)] local time through Event Date�
            hyperlinkText = `Public Onsale: ${dateStr} at ${timeStr} local time through Event Date`;
            customText =
              customText +
              `<a href=${ev.PublicOnsaleURL}>${ev.PublicOnSaleTextOverride
                ? ev.PublicOnSaleTextOverrideText
                : hyperlinkText
              }</a>`;
          } else {
            // if date is reached or in the past
            // add "On Sale Now" link without the time info
            customText =
              customText +
              `<a href=${ev.PublicOnsaleURL}>${ev.PublicOnSaleTextOverride
                ? ev.PublicOnSaleTextOverrideText
                : hyperlinkText
              }</a>`;
          }
        }
      }
    }
    const textIsValid = htmlHasValidContent(customText);

    if (customText && textIsValid) {
      return (
        <ImportantInfoBox>
          <ImportantInfoBoxHeader>Important Information</ImportantInfoBoxHeader>
          <ImportantInfoBoxContent>
            <div
              dangerouslySetInnerHTML={{
                __html: customText,
              }}
            />
          </ImportantInfoBoxContent>
        </ImportantInfoBox>
      );
    }

    return null;
  };

  const handleClickLoginButton = () => {
    if (outlet && outlet.LoginURL) {
      goToUrl(outlet.LoginURL, { outside: true });
    } else {
      setModal({
        isVisible: true,
        title: 'Login to Proceed',
        constant: 'LOGIN',
      });
    }
  };

  const handleOnLogin = () => {
    setModal({ isVisible: false });
  };

  if (outletEvent) {
    //  salesTypeId 1 = Instant real time tix
    //  salesTypeId 2 = Lottery
    //  salesTypeId 3 = External sales url
    const { SalesTypeID, OutletEventGroup } = outletEvent;
    const loading = instantRequest.loading || fanRequest.loading;
    const addonsAvailable =
      outletEvent &&
      outletEvent.OutletEventItems &&
      outletEvent.OutletEventItems.some(
        (oei) => oei?.EventItem?.SuppressOnEDP,
      );

    let existingSeriesSummary;
    const existingFanRequest =
      userFanRequestList &&
      userFanRequestList.find(
        (lr) => lr.OutletEventID === outletEvent.OutletEventID,
      );

    if (existingFanRequest) {
      existingSeriesSummary = userFanRequestSeriesSummaryList.find(
        (x) => x.LotteryOEGRequestID === existingFanRequest.LotteryOEGRequestID,
      );
    }

    const ticketGuidlines = outlet.OutletPolicies.filter(
      (policy) =>
        (policy.SalesTypeID === SalesTypeID || policy.SalesTypeID === -1) &&
        policy.OutletPolicyTypeID === 4,
    )[0];
    // RLMTS-1381 Remove OEI's that are "suppressed" on event details page (EDP)
    // RLMTS-605 Sort OEI's by Sort value
    const outletEventItemList = outletEvent.OutletEventItems.filter(
      (oei) => !oei?.EventItem?.SuppressOnEDP,
    );
    const { seriesIsVisible, seriesIsOpen, seriesIsClosed } =
      calculateSeriesStatuses(OutletEventGroup);
    const eventIsReadyToSell =
      seriesIsOpen && outletEventItemList && outletEventItemList.length;

    const userFanRequestListIsLoading =
      api && api.loading.includes('GET_USER_FAN_REQUESTS');
    const userFanRequestSeriesSummaryIsLoading =
      api && api.loading.includes('GET_USER_FAN_REQUEST_SERIES_SUMMARY');

    let continueButtonLabel = 'Buy Now';
    let continueButtonIsDisabled = true;
    let continueButtonIsVisible = true;
    let loginButtonIsVisible = false;
    const errorMessages = [];

    if (SalesTypeID === 2) {
      continueButtonLabel = 'Continue';

      if (selectedLotteryItems.length) {
        const selectedLotteryItemsFiltered = selectedLotteryItems.filter(
          (x) => x.PriceLevelID || x.Quantity,
        );

        selectedLotteryItemsFiltered.map((x) => {
          if (x.PriceLevelID || x.Quantity) {
            let oei; let errorMessage;
            if (Array.isArray(outletEventItemList)) {
              oei = outletEventItemList.find(
                (y) =>
                  Number(y.OutletEventItemID) === Number(x.OutletEventItemID),
              );
            }
            if (!x.PriceLevelID) {
              errorMessage = oei.Name + ': Price level required';
            }
            if (!x.Quantity || Number(x.Quantity) < 1) {
              errorMessage = oei.Name + ': Quantity required';
            }
            if (oei && errorMessage) {
              errorMessages.push({
                OutletEventItemID: x.OutletEventItemID,
                message: errorMessage,
                oei,
              });
            }
          }
        });

        const allItemsValid =
          selectedLotteryItemsFiltered &&
          selectedLotteryItemsFiltered.length &&
          selectedLotteryItemsFiltered.every(
            (l) => l.PriceLevelID && l.Quantity && Number(l.Quantity) > 0,
          );
        if (allItemsValid) {
          continueButtonIsDisabled = false;
        }
      }

      if (!userIsAuthenticated) {
        loginButtonIsVisible = true;
        continueButtonIsVisible = false;
      }

      if (seriesIsClosed || !seriesIsOpen) {
        continueButtonIsVisible = false;
      }

      if (existingFanRequest) {
        continueButtonLabel = 'Go to my requests';
        continueButtonIsDisabled = false;
      }
    } else {
      if (selectedPriceLevels.length) {
        continueButtonIsDisabled = false;
      }

      if (seriesIsClosed || !seriesIsOpen) {
        continueButtonIsVisible = false;
      }
    }

    return (
      <div style={{ height: '100%' }}>
        {ticketGuidlines && (
          <Attention onClick={() => handleClickPolicy(ticketGuidlines)}>
            <Typography
              style={{
                fontWeight: '700',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              {ticketGuidlines.Headline}
            </Typography>
          </Attention>
        )}

        <Contain hasPaddingMobile={true}>
          <Columns qty={2}>
            <LeftColumStyled>
              {/* //RLMTS-2695
              <Typography tag="h1" style={{ margin: "0 0 2rem 0" }}>
                Event Details
              </Typography> */}
              <OutletEventDetails outletEvent={outletEvent} />
            </LeftColumStyled>
            <RightColumStyled>
              <>
                <Typography tag="h1" style={{ margin: '0 0 2rem 0' }}>
                  Select Items
                </Typography>

                {renderSeriesMessage(outletEvent)}

                {Array.isArray(outletEventItemList) &&
                  outletEventItemList.map((oei, idx) => {
                    const EventItemTypeID =
                      oei.EventItem && oei.EventItem.EventItemTypeID;

                    const outletEventItemIsATicket =
                      EventItemTypeID && EventItemTypeID === 1;

                    const lotteryOEIRequest =
                      existingFanRequest &&
                      existingFanRequest.LotteryOEIRequests &&
                      existingFanRequest.LotteryOEIRequests.find(
                        (r) => r.OutletEventItemID === oei.OutletEventItemID,
                      );
                    // RLMTS-1955
                    // OEIs will display in 2 possible states:
                    // 1. Available - available OEIs have visible price levels below it.  PL's are either yet to go on-sale, currently on-sale or marked as sold out (see Price Level notes below)
                    // 2. Unavailable - if there are no Price Levels under the OEI (due to all PL's either being hidden or Off Sale) then the OEI is unavailable and should show the message "Currently unavailable."
                    let priceLevelsAreAvailable = false;
                    if (oei.PriceLevels && oei.PriceLevels.length) {
                      oei.PriceLevels.map((priceLevel, plidx) => {
                        if (priceLevel.SaleStatus !== 'OFF_SALE') {
                          priceLevelsAreAvailable = true;
                        }
                        return null;
                      });
                    }

                    return (
                      <div key={'oei-' + idx} style={{ margin: '0 0 3rem 0' }}>
                        <Collapse
                          heading={oei.Name}
                          subHeading={oei.Description}
                          disabled={seriesIsClosed || !seriesIsOpen}
                        >
                          {priceLevelsAreAvailable ? (
                            <div>
                              {SalesTypeID === 2 ? (
                                <div>
                                  {userIsAuthenticated ? (
                                    <div>
                                      {userFanRequestListIsLoading ||
                                        userFanRequestSeriesSummaryIsLoading ? (
                                        <ImportantInfoBox>
                                          ...loading your requests
                                        </ImportantInfoBox>
                                      ) : existingFanRequest ? (
                                        <ImportantInfoBox>
                                          You have an existing request for this
                                          event. If you wish to make changes,
                                          please visit the
                                          <Typography
                                            tag="a"
                                            onClick={() => {
                                              let url =
                                                '/user/history/requests';
                                              if (existingSeriesSummary) {
                                                url =
                                                  url +
                                                  '/' +
                                                  existingSeriesSummary.RequestID +
                                                  '/item-details/' +
                                                  existingFanRequest.LotteryOERequestUUID;
                                              }
                                              goToUrl(url);
                                            }}
                                          >
                                            {' '}
                                            my requests page
                                          </Typography>
                                        </ImportantInfoBox>
                                      ) : (
                                        <PriceLevelSelectorFanRequest
                                          lotteryOEIRequest={lotteryOEIRequest}
                                          outletEventItem={oei}
                                          onChangeCallback={(formData) =>
                                            handleOnChangeLotteryRequest(
                                              formData,
                                              oei,
                                              lotteryOEIRequest,
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <Button
                                      fullWidth
                                      name="btn-login"
                                      loading={loading}
                                      onClick={handleClickLoginButton}
                                    >
                                      Login to proceed
                                    </Button>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {oei.PriceLevels.map((priceLevel, plidx) => {
                                    const selectedPriceLevel =
                                      selectedPriceLevels.find(
                                        (spl) =>
                                          spl.PriceLevelID ===
                                          priceLevel.PriceLevelID,
                                      );
                                    // RLMTS-1927 Price Level Selector is disabled when:
                                    // 1) a price level has already been choosen from an OEI of type === "Ticket"
                                    let priceLevelSelectorIsEnabled = false;
                                    if (
                                      selectedPriceLevel ||
                                      selectedPriceLevels.length < 1 ||
                                      outletEventItemIsATicket === false
                                    ) {
                                      priceLevelSelectorIsEnabled = true;
                                    }

                                    return (
                                      <PriceLevelContainerStyled key={plidx}>
                                        <PriceLevelSelectorInstant
                                          priceLevel={
                                            selectedPriceLevel || priceLevel
                                          }
                                          updatePriceLevelQty={
                                            priceLevelSelectorIsEnabled
                                              ? (pl, qty) =>
                                                handleOnChangePriceLevel(
                                                  pl,
                                                  qty,
                                                )
                                              : false
                                          }
                                        />
                                      </PriceLevelContainerStyled>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          ) : (
                            <ErrorTextStyled>
                              Currently unavailable
                            </ErrorTextStyled>
                          )}
                        </Collapse>
                      </div>
                    );
                  })}

                <div style={{ margin: '3rem 0' }}>
                  {continueButtonIsVisible && (
                    <>
                      {errorMessages && errorMessages.length
                        ? errorMessages.map((e) => (
                          <DisplayError>{e.message}</DisplayError>
                        ))
                        : null}
                      <Button
                        fullWidth
                        name="btn-continue"
                        loading={loading || userFanRequestListIsLoading}
                        disabled={
                          loading ||
                          continueButtonIsDisabled ||
                          userFanRequestListIsLoading
                        }
                        onClick={() => {
                          if (SalesTypeID === 2) {
                            if (existingFanRequest) {
                              // if lottery request
                              goToUrl(
                                '/user/history/requests/' +
                                existingFanRequest.LotteryOERequestUUID,
                              );
                            } else {
                              let existingSeriesRequest;
                              const OutletEventGroupID =
                                outletEvent.OutletEventGroup &&
                                outletEvent.OutletEventGroup.OutletEventGroupID;

                              if (
                                OutletEventGroupID &&
                                Array.isArray(userFanRequestSeriesSummaryList)
                              ) {
                                existingSeriesRequest =
                                  userFanRequestSeriesSummaryList.find(
                                    (x) =>
                                      Number(x.OutletEventGroupID) ===
                                      OutletEventGroupID,
                                  );
                              }
                              if (existingSeriesRequest && addonsAvailable) {
                                showAddonsModal(outletEvent);
                              } else {
                                handleOnSubmitFanRequest();
                              }
                            }
                          } else {
                            handleOnSubmitInstant();
                          }
                        }}
                      >
                        {continueButtonLabel}
                      </Button>{' '}
                    </>
                  )}
                </div>
              </>
            </RightColumStyled>
          </Columns>
          <Modal
            title={modal.title}
            open={modal.isVisible}
            onClose={() => {
              if (modal.constant === 'QTY_REQUESTED') {
                handleClickRefreshPriceLevels();
              }
              setModal({ isVisible: false });
            }}
          >
            {modal.constant === 'FAN_REQUEST_ADDONS' && (
              <div>
                <CheckoutAddons
                  // expandedOutletEventItems={expandedOutletEventItems}
                  // selectedPriceLevels={cart.lineItems.filter(
                  //   (li) => li.isAddOn
                  // )}
                  outletEventItems={modal.content}
                  handleAddOnQtyChange={handleRequestAddOnQtyChange}
                />
                <Button
                  fullWidth
                  name="btn-continue"
                  loading={loading || userFanRequestListIsLoading}
                  disabled={
                    loading ||
                    continueButtonIsDisabled ||
                    userFanRequestListIsLoading
                  }
                  onClick={() => handleOnSubmitFanRequest()}
                >
                  Continue
                </Button>
              </div>
            )}
            {modal.constant === 'QTY_REQUESTED' && (
              <div>
                Please reduce the quantity requested or choose a different price
                level
                <div style={{ margin: '3rem 0' }}>
                  <Button onClick={() => handleClickRefreshPriceLevels()}>
                    Go back
                  </Button>
                </div>
              </div>
            )}
            {modal.constant === 'POLICY' && (
              <div
                dangerouslySetInnerHTML={{
                  __html: modal.content,
                }}
              />
            )}
            {modal.constant === 'LOGIN' && (
              <AuthContainer
                onLogin={() => handleOnLogin()}
                onCreateAccount={() => handleOnLogin()}
              />
            )}
            {modal.constant === 'FAN_REQUEST_UPDATE' && (
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    color: 'green',
                    textAlign: 'center',
                  }}
                >
                  <p>You successfully added an event to your request</p>
                  {modal.userIsNewlyEligibleForPaymentPlan && (
                    <p>
                      {' '}
                      Your request is eligible for you to enroll in the payment
                      plan. View your request and manage your billing
                      information to enroll.{' '}
                    </p>
                  )}
                </div>

                <div
                  style={{
                    margin: '3rem 0',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ margin: '0 2rem 0 0' }}>
                    <Button onClick={() => goToUrl('/')}>
                      Add To Your Request
                    </Button>
                  </div>
                  <div>
                    <Button onClick={() => goToUrl(modal.content)}>
                      View Your Request
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </Contain>
      </div>
    );
  }

  return <Alert type="warning">No event information available</Alert>;
}

export default EventView;
