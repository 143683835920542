import React from 'react';
import { DisplayPaymentMethod } from '../../utils/DisplayContent';
import { formatDate } from '../../utils/utilities';

/**
 * Subcomponent for rendering installment payment details.
 *
 * This component displays each installment's payment method and associated processing date.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Array<Object>} props.paymentList - List of payment methods for each installment.
 * @param {Object} props.outletEventGroup - The outlet event group containing installment dates.
 * @param {string} [props.outletEventGroup.PaymentPlanInstallment1Date] - The processing date for the first installment.
 * @param {string} [props.outletEventGroup.PaymentPlanInstallment2Date] - The processing date for the second installment.
 * @param {string} [props.outletEventGroup.PaymentPlanInstallment3Date] - The processing date for the third installment.
 * @return {React.FC}
 *
 * @remarks
 * - Formats installment dates using `formatDate` and displays them alongside their respective payment methods.
 *
 * @dependencies
 * - `DisplayPaymentMethod`: Utility component for rendering payment method details.
 */
const RenderInstallments = ({ paymentList, outletEventGroup }) => {
  const installmentDates = [
    outletEventGroup?.PaymentPlanInstallment1Date,
    outletEventGroup?.PaymentPlanInstallment2Date,
    outletEventGroup?.PaymentPlanInstallment3Date,
  ];

  return paymentList.map((paymentMethod, index) => {
    const processDate = installmentDates[index];
    const processText = processDate
      ? `${index === 0 ? 'Process on or around ' : 'Processes on '}${formatDate(processDate, {
        timeZone: 'UTC',
      })}`
      : null;

    return (
      <div style={{ margin: '0 0 1.5rem 0' }} key={index}>
        <div style={{ fontWeight: 700 }}>
          Installment {index + 1} - {processText}
        </div>
        <DisplayPaymentMethod paymentMethod={paymentMethod} />
      </div>
    );
  });
};

/**
 * Component for rendering payment details during checkout.
 *
 * This component conditionally displays either a list of installment payment details
 * for users enrolled in a payment plan or a single payment method.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.outletEvent - The current outlet event data.
 * @param {Object} [props.outletEvent.OutletEventGroup] - The group data associated with the outlet event, including payment plan details.
 * @param {boolean} props.userIsEnrolledInPaymentPlan - Indicates whether the user is enrolled in a payment plan.
 * @param {Array<Object>} [props.paymentList] - List of payment methods associated with the user's installment plan.
 * @param {Object} [props.paymentMethod] - The default payment method if the user is not enrolled in a payment plan.
 * @return {React.FC}
 *
 * @remarks
 * - If the user is enrolled in a payment plan, the `RenderInstallments` component is used to display installment details.
 * - If not, the `DisplayPaymentMethod` component is used to show the default payment method.
 *
 * @dependencies
 * - `RenderInstallments`: Subcomponent for rendering installment payment details.
 * - `DisplayPaymentMethod`: Utility component for rendering a single payment method.
 */
const PaymentDetails = (props) => {
  const { outletEvent, userIsEnrolledInPaymentPlan, paymentList, paymentMethod } = props;
  const outletEventGroup = outletEvent?.OutletEventGroup;

  return userIsEnrolledInPaymentPlan && paymentList ? (
    <RenderInstallments paymentList={paymentList} outletEventGroup={outletEventGroup} />
  ) : (
    <DisplayPaymentMethod paymentMethod={paymentMethod} />
  );
};

export default PaymentDetails;
