import React from 'react';
import PaymentPlanForm from './PaymentPlanForm';
import PaymentMethodForm from '../../utils/PaymentMethodForm';
import Button from '../../utils/Button';

/**
 * Component for rendering a payment form during the checkout process.
 *
 * This component dynamically renders either a payment plan form or a standard payment method form
 * based on the `isPaymentPlan` prop. It also includes a "Continue" button that triggers a provided callback.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isPaymentPlan - Indicates whether the payment plan form should be displayed.
 * @param {Object} [props.paymentPlanRules] - Rules and details for the payment plan.
 * @param {Object} [props.seriesSummary] - Summary details for the series, including totals.
 * @param {Object} [props.seriesSummary.Totals] - The totals object containing payment details.
 * @param {number} [props.seriesSummary.Totals.total=0] - The total amount for the series.
 * @param {Array<Object>} props.userPaymentMethodList - List of available payment methods for the user.
 * @param {Array<Object>} props.paymentList - List of selected payment methods for the payment plan.
 * @param {Function} props.handleOnSelectPaymentMethod - Callback triggered when a payment method is selected.
 * @param {Function} props.handleOnCreateNewPaymentMethodViaIFrame - Callback triggered when a new payment method is created via an iframe.
 * @param {Object} [props.outletEventGroup] - The outlet event group data associated with the transaction.
 * @param {Object} [props.paymentMethod] - The currently selected payment method for standard payment.
 * @param {boolean} props.loading - Indicates whether the form is in a loading state.
 * @param {Function} props.handleClickContinue - Callback triggered when the "Continue" button is clicked.
 * @return {React.FC}
 *
 * @remarks
 * - If `isPaymentPlan` is `true`, a payment plan form (`PaymentPlanForm`) is displayed.
 * - If `isPaymentPlan` is `false`, a standard payment method form (`PaymentMethodForm`) is displayed.
 * - The "Continue" button is disabled based on validation rules for payment methods or loading state.
 *
 * @dependencies
 * - `PaymentPlanForm`: Component for managing payment plan details.
 * - `PaymentMethodForm`: Component for selecting and managing standard payment methods.
 * - `Button`: Component for triggering the "Continue" action.
 */
const PaymentForm = ({
  isPaymentPlan,
  paymentPlanRules,
  seriesSummary,
  userPaymentMethodList,
  paymentList,
  handleOnSelectPaymentMethod,
  handleOnCreateNewPaymentMethodViaIFrame,
  outletEventGroup,
  paymentMethod,
  loading,
  handleClickContinue,
}) => {
  const total = (seriesSummary && seriesSummary.Totals.total) || 0;
  const isButtonDisabled = isPaymentPlan
    ? !paymentList || paymentList.length < 3 || loading
    : !paymentMethod || loading;

  return (
    <>
      {isPaymentPlan ? (
        <PaymentPlanForm
          paymentPlanRules={paymentPlanRules}
          total={total}
          userPaymentMethodList={userPaymentMethodList}
          paymentList={paymentList}
          onChangePaymentMethod={handleOnSelectPaymentMethod}
          onCreateViaIFrame={handleOnCreateNewPaymentMethodViaIFrame}
          outletEventGroup={outletEventGroup}
        />
      ) : (
        <PaymentMethodForm
          defaultMethod={paymentMethod}
          paymentMethods={userPaymentMethodList}
          onSelect={handleOnSelectPaymentMethod}
          onCreateViaIFrame={handleOnCreateNewPaymentMethodViaIFrame}
        />
      )}
      <Button
        fullWidth
        loading={loading}
        disabled={isButtonDisabled}
        onClick={handleClickContinue}
      >
        Continue
      </Button>
    </>
  );
};

export default PaymentForm;
