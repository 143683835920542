/* eslint-disable require-jsdoc */
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddressForm from '../../utils/AddressForm';
import Attention from '../../utils/Attention';
import AuthContainer from '../../components/auth/AuthContainer';
import Button from '../../utils/Button';
import CheckoutAddons from '../../components/checkout/CheckoutAddons';
import CheckoutBreadcrumbs from '../../components/checkout/CheckoutBreadcrumbs';
import CheckoutTotalsSummaryFanRequest from '../../components/checkout/CheckoutTotalsSummaryFanRequest';
import Columns from '../../utils/Columns';
import Contain from '../../components/app/Contain';
import DisplayError from '../../utils/Error';
import EditRequestLineItems from '../../components/fanrequest/EditRequestLineItems';
import Modal from '../../utils/Modal';
import OutletEventSummary from '../../components/outletEvent/OutletEventSummary';
import PaymentPlanEnrollment from '../../components/fanrequest/PaymentPlanEnrollment';
import Typography from '../../utils/Typography';
import { DisplayHeader, DisplayShippingAddress } from '../../utils/DisplayContent';
import {
  resetFanRequestCart,
  setFanRequestError,
  updateFanRequest,
} from '../../components/cart/actions';
import {
  getMobileDeliveryMethodFromOutlet,
  goToUrl,
  isFanRequestUsingMobileDelivery,
  lineItemsNormalizedByOutletEventItem,
  normalizeAddress,
} from '../../utils/utilities';
import { getUserAddressList, getUserFanRequests, getUserPaymentMethodList } from '../../components/user/actions';
import { FanRequestCheckoutHeader } from '../../components/fanrequest/FanRequestCheckoutHeader';
import { FanRequestMobileDeliveryDetails } from '../../components/fanrequest/FanRequestMobileDeliveryDetails';
import { COUNTRY_ID } from '../../constants/address.constant';
import PaymentDetails from '../../components/fanrequest/payment-details.component';
import PaymentForm from '../../components/fanrequest/payment-form.component';
import TermsAndConditions from '../../components/fanrequest/terms-and-conditions.component';
import RequestLineItems from '../../components/fanrequest/request-line-items.component';
import { addToast } from '../../components/toast/actions';

const RightColumnStyled = styled.div`
  padding: 0 0 3rem 0;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    // padding: 3rem 2rem;
  }
`;
const LeftColumnStyled = styled.div`
  padding: 0 0 2rem 0;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    padding: 0 0 3rem 0;
  }
`;

const EditRequestRiboon = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.8rem;
  padding: 1rem;
  color: #fff;
  font-weight: 700;
  background-color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
`;

const fanStageList = [
  { sort: 1, stage: 'addons', label: 'Addons', header: 'Add to Request' },
  {
    sort: 2,
    stage: 'shipping-address',
    label: 'Shipping',
    header: 'Shipping Details',
  },
  {
    sort: 3,
    stage: 'payment-method',
    label: 'Billing',
    header: 'Payment Details',
  },
  { sort: 4, stage: 'confirm', label: 'Confirm', header: 'Place Request' },
];


/**
 * Checkout component rendered for fan request (lottery) checkout type
 * This was broken out from CheckoutView.js which originally contained the checkout
 * views for all checkout types (fan request, guestlist, instant)
 * @return {React.FC}
 */
const FanRequestCheckout = (props) => {
  const { type, stage } = useParams();
  const outlet = useSelector((state) => state.outlet);
  const session = useSelector((state) => state.session);
  const fanRequest = useSelector((state) => state.fanRequest);
  const userAddressList = useSelector((state) => state.userAddressList);
  const userPaymentMethodList = useSelector((state) => state.userPaymentMethodList);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [userAcceptsTermsOfService, setUserAcceptsTermsOfService] = useState(false);
  const [userHasViewedAddons, setUserHasViewedAddons] = useState(false);
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
  });

  const UserID = session && session.UserID;

  /**
     * useEffect hook to handle scrolling
     *
     * @param {string} stage - The current stage of the user flow. Possible values: "payment-method" or "shipping-address".
     */
  useEffect(() => {
    const scrollToElement = document.querySelector('#breadcrumbs-root-container');

    if (scrollToElement) {
      scrollToElement.scrollIntoView({ behavior: 'smooth' });
    }

    return () => { };
  }, [stage]);

  /**
   * useEffect hook to handle data fetching based on the current type and stage (taken from the url query param).
   *
   * @param {string} stage - The current stage of the user flow. Possible values: "payment-method" or "shipping-address".
   * @param {string} type - The current type of the user flow. Possible values: "f"
   */
  useEffect(() => {
    // Fetch payment data for user used in the dropdown
    if (stage === 'payment-method') {
      getUserPaymentMethodList({ UserID });
    }

    // Fetch address data for user used in the dropdown
    if (stage === 'shipping-address') {
      getUserAddressList({ UserID });
    }

    return () => { };
  }, [stage, type]);

  if (!stage || !type) {
    return <Redirect to="/" />;
  }

  let cart;
  let error;
  let loading;
  let cartUUID;
  let stageList;
  let outletEvent;
  let currentStage;
  let paymentMethod;
  let shippingAddress;
  let ticketGuidlines;
  let seriesSummary;
  let paymentPlanRules;
  let outletPrivacyPolicy;
  let outletTermsAndConditions;
  let lineItemsNormalizedByOEI;
  let outletEventItemsWithAddons;
  let paymentList = [];
  let paymentPlanIsAvailable = false;
  const paymentCollectionIsRequired = true;
  let userIsEnrolledInPaymentPlan = false;
  let fanRequestIsMobileDelivery = false;
  const mobileDeliveryMethod = getMobileDeliveryMethodFromOutlet(outlet);

  cart = fanRequest.cart;
  error = fanRequest.error;
  stageList = [...fanStageList];
  loading = fanRequest.loading;
  outletEvent = fanRequest.outletEvent;

  const addonsAvailable =
    outletEvent &&
    outletEvent.OutletEventItems &&
    outletEvent.OutletEventItems.some(
      (oei) => oei?.EventItem?.SuppressOnEDP,
    );

  if (!addonsAvailable) {
    stageList = stageList.filter((x) => x.stage !== 'addons').map((x, idx) => ({ ...x, sort: idx + 1 }));
  }

  currentStage = stageList.find((x) => x.stage === stage);
  seriesSummary = fanRequest.seriesSummary;
  fanRequestIsMobileDelivery = Boolean(mobileDeliveryMethod?.Active && isFanRequestUsingMobileDelivery({ seriesSummary }));

  if (cart) {
    cartUUID = 'FanrequestUUUD';
    paymentList = cart.paymentList;
    shippingAddress = cart.shippingAddress;

    if (
      paymentList?.[0]?.PaymentMethodUUID && Array.isArray(userPaymentMethodList)) {
      paymentMethod = userPaymentMethodList
        .find(({ PaymentMethodUUID }) => PaymentMethodUUID === paymentList[0].PaymentMethodUUID);
    }


    if (seriesSummary) {
      const {
        Totals,
        PaymentInfo,
        PaymentPlanEnrolled,
      } = seriesSummary;

      // if shipping is NOT set in the cart, default it to the series shipping method
      if (!shippingAddress && seriesSummary.Shipping) {
        // if shipping address is found via lottery summary,
        // normalize the keys because they dont match the address table
        shippingAddress = normalizeAddress(seriesSummary.Shipping);
      }

      // if paymentList is NOT set in the cart, default it to the series payment info
      if (!Array.isArray(paymentList) || !paymentList.length) {
        paymentList = PaymentInfo;
      }

      if (
        outlet?.OutletEventGroups?.length &&
        outletEvent?.OutletEventGroup?.OfferPaymentPlan
      ) {
        const oegFound = outlet.OutletEventGroups.find(
          (oeg) =>
            Number(oeg.OutletEventGroupID) ===
            Number(outletEvent.OutletEventGroup.OutletEventGroupID)
        );

        if (oegFound?.PaymentPlanRules) {
          const { PaymentPlanRules } = oegFound;
          paymentPlanRules = PaymentPlanRules;
          paymentPlanIsAvailable =
            Number(Totals.total) >= Number(PaymentPlanRules.eligibilityMinimum);
        }
      }

      if (cart.enrollInPaymentPlan === undefined) {
        userIsEnrolledInPaymentPlan = Boolean(PaymentPlanEnrolled);
      } else {
        userIsEnrolledInPaymentPlan = cart.enrollInPaymentPlan;
      }
    }
  }

  let userHasValidCart = false;

  userHasValidCart = cart && cartUUID && outletEvent;

  if (!userHasValidCart) {
    return <Redirect to="/" />;
  }

  const handleClickEditFanRequestLineItems = (params = {}) => {
    const { title, outletEventItems } = params;
    setModal({
      ...modal,
      constant: 'EDIT_REQUEST_LINE_ITEMS',
      title: title || 'Edit Line Items',
      isVisible: true,
      content: { outletEventItems },
    });
  };

  const handleClickPolicy = (policy) => {
    setModal({
      title: policy && policy.Headline,
      content: policy && policy.PolicyText,
      constant: 'POLICY',
      isVisible: true,
    });
  };

  const handleOnChangeAcceptsTermsOfService = (e) =>
    setUserAcceptsTermsOfService(e.target.checked);


  const handleSuccessfullAuthentication = () => {
    if (
      outletEventItemsWithAddons &&
      Array.isArray(outletEventItemsWithAddons) &&
      outletEventItemsWithAddons.length &&
      userHasViewedAddons === false
    ) {
      goToUrl('/checkout/' + type + '/addons');
    } else {
      goToUrl('/checkout/' + type + '/shipping-address');
    }
  };

  const handleAddOnQtyChange = (selectedPriceLevels) => {
    const selectedAddonsFormattted = selectedPriceLevels
      // .filter((p) => p.isAddOn)
      .map((spl) => {
        const quantity = parseInt(Number(spl.quantity || spl.Quantity, 10));
        const priceLevelID = parseInt(Number(spl['PriceLevelID'], 10));
        let outletEventItemID =
          spl.OutletEventItemID || (spl.oei && spl.oei.OutletEventItemID);
        outletEventItemID = parseInt(Number(outletEventItemID, 10));
        return {
          ...spl,
          quantity,
          Quantity: quantity,
          priceLevelID,
          isAddOn: true,
          outletEventItemID,
        };
      });
    setSelectedAddons(selectedAddonsFormattted);
  };

  const handleClickCloseModal = () => {
    setModal({ ...modal, isVisible: false });
  };

  const handleClickSaveAddons = () => {
    setUserHasViewedAddons(true);

    const lineItems = selectedAddons.map((a) => {
      const formattedItem = {
        ...a,
        quantity: a.quantity,
        priceLevelID: a.priceLevelID,
        isAddOn: true,
      };
      return formattedItem;
    });

    const config = {
      action: 'CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY',
      addons: lineItems,
    };

    updateFanRequest(config).then((res) => {
      setSelectedAddons([]);
      goToUrl('/checkout/' + type + '/shipping-address');
    });
  };


  const handleOnSubmitShippingAddress = (shippingAddress) => {
    if (shippingAddress) {
      const LotteryRequestShippingAddressUUID = fanRequest?.seriesSummary?.Shipping?.LotteryRequestShippingAddressUUID;
      const LotteryRequestShippingAddressID = fanRequest?.seriesSummary?.Shipping?.LotteryRequestShippingAddressID;
      const config = {
        action: 'FAN_REQUEST_UPDATE_SHIPPING_ADDRESS',
        cart: {
          shippingAddress: {
            ...shippingAddress,
            LotteryRequestShippingAddressID,
            LotteryRequestShippingAddressUUID,
          },
        },
      };
      updateFanRequest(config).then((res) => {
        goToUrl('/checkout/f/payment-method');
      });
    } else {
      alert('error missing shipping address details');
    }
  };

  const handleOnCreateNewPaymentMethodViaIFrame = (iFrameResponse) => {
    const UserID = session && session.UserID;
    getUserPaymentMethodList({ UserID }).then((userPaymentMethods) => {
      if (
        userPaymentMethods &&
        Array.isArray(userPaymentMethods) &&
        userPaymentMethods.length
      ) {
        if (userPaymentMethods[0]) {
          const pmAdjusted = { ...userPaymentMethods[0] };
          if (iFrameResponse.Sort) {
            pmAdjusted['Sort'] = iFrameResponse.Sort;
          }
          handleOnSelectPaymentMethod(pmAdjusted);
        }
      }
    });
  };


  const handleOnSelectPaymentMethod = (pm) => {
    if (pm && pm.PaymentMethodUUID) {
      const paymentMethodUUID = pm.PaymentMethodUUID;

      if (!pm.Sort) {
        pm.Sort = 1;
      }

      let LotteryOEGRequestPaymentMethodUUID;
      let LotteryOEGRequestPaymentMethodID;
      if (
        fanRequest &&
        fanRequest.seriesSummary &&
        Array.isArray(fanRequest.seriesSummary.PaymentInfo)
      ) {
        const paymentIndex = fanRequest.seriesSummary.PaymentInfo.findIndex(
          (p) => Number(p.Sort) === Number(pm.Sort),
        );

        if (paymentIndex > -1) {
          LotteryOEGRequestPaymentMethodUUID =
            fanRequest.seriesSummary.PaymentInfo[paymentIndex].LotteryOEGRequestPaymentMethodUUID;
          LotteryOEGRequestPaymentMethodID =
            fanRequest.seriesSummary.PaymentInfo[paymentIndex].LotteryOEGRequestPaymentMethodID;
        }
      }
      const config = {
        action: 'FAN_REQUEST_UPDATE_PAYMENT_METHOD',
        paymentMethod: {
          ...pm,
          LotteryOEGRequestPaymentMethodID,
          LotteryOEGRequestPaymentMethodUUID,
        },
        // cart: { paymentList: [{ ...pm, Sort: 1 }] },
      };

      updateFanRequest(config).then((res) => {
        if (!userIsEnrolledInPaymentPlan) {
          goToUrl('/checkout/f/confirm');
        }
      });
    } else {
      alert('error missing payment method details');
    }
  };


  /**
   * Handles the submission of a fan request.
   *
   * This function triggers the fan request submission process, resets the cart,
   * fetches the updated user address list and fan requests, and navigates
   * the user to the appropriate page based on the response.
   *
   * @async
   * @function handleClickSubmitRequest
   *
   * @throws Will log an error to the console if the request submission fails.
   *
   * @return {Promise<void>} Resolves once the request submission and related actions are complete.
   */
  const handleClickSubmitRequest = async () => {
    try {
      const config = { action: 'SAVE_FAN_REQUEST' };
      const res = await updateFanRequest(config);

      if (!res) {
        return;
      }

      const { requestId, outletEventRequestUUID } = res;

      resetFanRequestCart();
      getUserAddressList();
      getUserFanRequests();

      const url = requestId && outletEventRequestUUID
        ? `/user/history/requests/${requestId}/receipt/${outletEventRequestUUID}`
        : '/user/history/requests';

      goToUrl(url, { replace: true });
    } catch (error) {
      addToast({
        title: 'Error',
        description: 'There was an error. Please try again',
        type: 'error',
      });
      console.error(`Error submitting request:`, error);
    }
  };


  const handleClickContinueFromPaymentMethod = () => {
    goToUrl('/checkout/' + type + '/confirm');
  };


  const handleClickBreadcrumb = (crumb) => {
    if (crumb && crumb.stage) {
      goToUrl('/checkout/' + type + '/' + crumb.stage);
    }
    return false;
  };


  /**
   * Handler for Enroll in payment plan checkbox
   * @param {*} e event object
   */
  const handleOnChangePaymentPlanEnrollment = (e) => {
    const isChecked = e.target.checked;
    const config = {
      action: 'ENROLL_IN_PAYMENTPLAN',
      cart: { enrollInPaymentPlan: Boolean(isChecked) },
    };
    updateFanRequest(config);
  };


  if (Array.isArray(outlet.OutletPolicies)) {
    outlet.OutletPolicies.map((policy) => {
      // OutletPolicyTypeID to Name:
      // 1 Privacy Policy
      // 2 Terms & Conditions
      // 3 Payment Plan Policy
      // 4 Ticket Guidelines
      // 5 Shipping Address
      // 6 External Events
      // 7 Payment Plan T&C
      // 8 Priority Select
      // 9 Pit Upgrade
      // 10 Estimated Shipping Date Text

      // SalesTypeID to Name:
      // -1 All (Realtime & Lottery && External)
      // 1 Realtime (instant)
      // 2 Lottery (fan request)
      // 3 External (ie. ticketmaster link)

      if (policy.SalesTypeID === -1) {
        if (policy.OutletPolicyTypeID === 1) {
          outletPrivacyPolicy = policy;
        }
        if (policy.OutletPolicyTypeID === 2) {
          outletTermsAndConditions = policy;
        }
      }

      if (policy.SalesTypeID === 2 || policy.SalesTypeID === -1) {
        if (policy.OutletPolicyTypeID === 4) {
          ticketGuidlines = policy;
        }
      }

      return null;
    });
  }

  const userAddressListSorted = Array.isArray(userAddressList)
    ? userAddressList.sort((a, b) => a.AddressID - b.AddressID)
    : [];


  outletEventItemsWithAddons = outletEvent
    ? outletEvent.OutletEventItems.filter(
      (oei) => oei?.EventItem?.SuppressOnEDP,
    ).filter((oei) => (oei.PriceLevels || []).length !== 0)
    : [];

  lineItemsNormalizedByOEI = lineItemsNormalizedByOutletEventItem({
    OutletEventItems: outletEvent ? outletEvent.OutletEventItems : [],
    cart: { lineItems: cart.lineItems },
    // cart: { lineItems: cart.lineItems.filter((li) => !li.isAddOn) },
  });


  let filteredDeliveryMethods = Array.isArray(outlet.OutletDeliveryMethods)
    ? outlet.OutletDeliveryMethods.filter((x) => x.Active)
    : [];

  if (shippingAddress && shippingAddress.CountryID) {
    // DeliveryMethodTypeID: 1 is 'Will Call'
    // DeliveryMethodTypeID: 2 is 'Shippable - Domestic'
    // DeliveryMethodTypeID: 3 is 'Shippable - International'
    if (Number(shippingAddress.CountryID) === COUNTRY_ID['UNITED_STATES']) {
      // Unitied States is CountryID === 1
      filteredDeliveryMethods = filteredDeliveryMethods.filter(
        (x) => x.DeliveryMethodTypeID === 1 || x.DeliveryMethodTypeID === 2,
      );
    } else {
      filteredDeliveryMethods = filteredDeliveryMethods.filter(
        (x) => x.DeliveryMethodTypeID === 1 || x.DeliveryMethodTypeID === 3,
      );
    }
  }

  return (
    <div data-testid="fan-request-checkout-container">
      {ticketGuidlines && (
        <Attention onClick={() => handleClickPolicy(ticketGuidlines)}>
          <Typography
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              cursor: 'pointer',
            }}
          >
            {ticketGuidlines.Headline}
          </Typography>
        </Attention>
      )}
      <Contain fillHeight hasPadding>
        <div id="breadcrumbs-root-container">
          <CheckoutBreadcrumbs
            stage={stage}
            stageList={stageList}
            onClickCrumb={(stage) => handleClickBreadcrumb(stage)}
          />
        </div>

        <Columns qty={2}>
          <LeftColumnStyled>
            <Typography tag="h1" style={{ marginBottom: '2rem' }}>Current Request</Typography>
            <div>
              <OutletEventSummary outletEvent={outletEvent} />
              <EditRequestRiboon>
                <div>Your Request</div>
                <div>
                  <Typography
                    tag="a"
                    style={{ color: 'white' }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClickEditFanRequestLineItems({
                        title: 'Edit Line Items',
                        outletEventItems: outletEvent.OutletEventItems,
                      });
                    }}
                  >
                    Edit
                  </Typography>
                </div>
              </EditRequestRiboon>
              <RequestLineItems
                lineItemsNormalizedByOEI={lineItemsNormalizedByOEI}
                handleClickEditFanRequestLineItems={handleClickEditFanRequestLineItems}
              />
              <CheckoutTotalsSummaryFanRequest
                cart={cart}
                outletEvent={outletEvent}
                seriesSummary={fanRequest.seriesSummary}
              />
            </div>
          </LeftColumnStyled>

          <RightColumnStyled>
            <FanRequestCheckoutHeader
              stage={stage}
              stageList={stageList}
              isMobileDelivery={fanRequestIsMobileDelivery}
            />

            {stage === 'login' && (
              <AuthContainer
                view="checkout"
                onLogin={() => handleSuccessfullAuthentication()}
                onCreateAccount={() => handleSuccessfullAuthentication()}
              />
            )}

            {stage === 'addons' && (
              <>
                <CheckoutAddons
                  // expandedOutletEventItems={expandedOutletEventItems}
                  cart={cart}
                  selectedPriceLevels={cart.lineItems.filter((li) => li.isAddOn !== false)}
                  outletEventItems={outletEventItemsWithAddons}
                  handleAddOnQtyChange={handleAddOnQtyChange}
                  modal={modal}
                  displayFees={type === 'f'}
                />
                <Button
                  fullWidth
                  loading={loading}
                  style={{ margin: '2rem 0' }}
                  onClick={handleClickSaveAddons}
                  id="save-realtime-addon-btn"
                >
                  Continue
                </Button>
              </>
            )}

            {stage === 'shipping-address' && (
              <>
                {fanRequestIsMobileDelivery &&
                  <FanRequestMobileDeliveryDetails
                    fanRequest={fanRequest}
                    isShippingHeaderVisible={true}
                    mobileDeliveryMethod={mobileDeliveryMethod}
                  />
                }
                <AddressForm
                  loading={loading}
                  // error={cartError}
                  buttonText="Continue"
                  defaultAddress={shippingAddress}
                  addresses={userAddressListSorted}
                  onSubmit={handleOnSubmitShippingAddress}
                  showConnectionToArtist={type === 'g'}
                  showNote={type === 'g'}
                />
              </>
            )}

            {stage === 'payment-method' && (
              <>
                <>
                  {fanRequestIsMobileDelivery &&
                    <FanRequestMobileDeliveryDetails
                      fanRequest={fanRequest}
                      mobileDeliveryMethod={mobileDeliveryMethod}
                    />
                  }
                  <DisplayHeader
                    btnText="Edit"
                    btnName="btn-shipping"
                    title="Shipping Address"
                    onClickBtn={() => goToUrl('/checkout/' + type + '/shipping-address')}
                  />
                  <DisplayShippingAddress address={shippingAddress} />
                </>

                <Typography tag="h1" style={{ margin: '2rem 0' }}>
                  {currentStage.header}
                </Typography>

                {paymentPlanIsAvailable &&
                  <PaymentPlanEnrollment
                    userIsEnrolledInPaymentPlan={userIsEnrolledInPaymentPlan}
                    onChangeCheckboxEnrollment={handleOnChangePaymentPlanEnrollment}
                  />
                }

                <PaymentForm
                  isPaymentPlan={userIsEnrolledInPaymentPlan}
                  paymentPlanRules={paymentPlanRules}
                  seriesSummary={seriesSummary}
                  userPaymentMethodList={userPaymentMethodList}
                  paymentList={paymentList}
                  handleOnSelectPaymentMethod={handleOnSelectPaymentMethod}
                  handleOnCreateNewPaymentMethodViaIFrame={handleOnCreateNewPaymentMethodViaIFrame}
                  outletEventGroup={outletEvent && outletEvent.OutletEventGroup}
                  paymentMethod={paymentMethod}
                  loading={loading}
                  handleClickContinue={handleClickContinueFromPaymentMethod}
                />
              </>
            )}

            {stage === 'confirm' && (
              <>
                <div style={{ margin: '0 0 2rem 0' }}>
                  <>
                    {fanRequestIsMobileDelivery &&
                      <FanRequestMobileDeliveryDetails
                        fanRequest={fanRequest}
                        mobileDeliveryMethod={mobileDeliveryMethod}
                      />
                    }
                    <DisplayHeader
                      btnText="Edit"
                      btnName="btn-shipping"
                      title="Shipping Address"
                      onClickBtn={() => goToUrl('/checkout/' + type + '/shipping-address')}
                    />
                    <DisplayShippingAddress address={shippingAddress} />
                  </>
                </div>
                {paymentCollectionIsRequired && (
                  <div style={{ margin: '0 0 2rem 0' }}>
                    <DisplayHeader
                      btnText="Edit"
                      title="Payment Details"
                      btnName="btn-shipping"
                      onClickBtn={() => goToUrl('/checkout/' + type + '/payment-method')}
                    />
                    <PaymentDetails
                      userIsEnrolledInPaymentPlan={userIsEnrolledInPaymentPlan}
                      paymentList={paymentList}
                      outletEvent={outletEvent}
                      paymentMethod={paymentMethod}
                    />
                  </div>
                )}

                <TermsAndConditions
                  userAcceptsTermsOfService={userAcceptsTermsOfService}
                  outletTermsAndConditions={outletTermsAndConditions}
                  outletPrivacyPolicy={outletPrivacyPolicy}
                  ticketGuidlines={ticketGuidlines}
                  handleOnChangeAcceptsTermsOfService={handleOnChangeAcceptsTermsOfService}
                  handleClickPolicy={handleClickPolicy}
                  handleClickSubmitRequest={handleClickSubmitRequest}
                  loading={loading}
                />
              </>
            )}
          </RightColumnStyled>
        </Columns>
      </Contain>

      <Modal
        title={modal.title}
        open={modal.isVisible}
        onClose={handleClickCloseModal}
      >
        {modal.constant === 'EDIT_REQUEST_LINE_ITEMS' && (
          <div>
            <EditRequestLineItems
              loading={fanRequest.loading}
              outletEventItems={modal.content && modal.content.outletEventItems}
              defaultUserSelectedPriceLevels={fanRequest.cart.lineItems}
              checkoutSelectedPriceLevels={selectedAddons}
              // checkoutOutletEventItemsState={}//todo
              onClickCancel={() => {
                setFanRequestError(null);
                handleClickCloseModal();
              }}
              onClickSave={(lineItems) => {
                setFanRequestError(null);
                updateFanRequest({
                  outletEvent,
                  action: 'CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY',
                  cart: {
                    lineItems: lineItems.filter((x) => Number(x.Quantity) > 0),
                  },
                }).then((res) => {
                  setModal({ ...modal, isVisible: false });
                });
              }}
            >
              {fanRequest && fanRequest.error && (<DisplayError>{fanRequest.error}</DisplayError>)}
            </EditRequestLineItems>
          </div>
        )}

        {modal.constant === 'POLICY' && (
          <div
            dangerouslySetInnerHTML={{
              __html: modal.content,
            }}
          />
        )}

      </Modal>
    </div>
  );
}

export default FanRequestCheckout;
