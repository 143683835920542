/*
* Returns the api URL using window object
*/
export const getApiUrl = () => {
    // reminder: the colon (:) is included in window.location.protocol
    const PROTOCOL = window.location.protocol ?? 'https:';
    const HOSTNAME = window.location.hostname;
    const API_PREFIX = process.env.REACT_APP_API_PREFIX ?? 'api';
    const HOSTNAME_SPLIT = HOSTNAME.split('.');
    HOSTNAME_SPLIT.shift();
    const HOSTNAME_WITHOUT_PREFIX = HOSTNAME_SPLIT.join('.');
    return `${PROTOCOL}//${API_PREFIX}.${HOSTNAME_WITHOUT_PREFIX}`;
};

/*
* Returns aws lambda API url
*/
export const getLambdaURL = () => {
    const LAMBDA_PATHNAME = 'gen.v1';
    const API_URL = getApiUrl();
    return `${API_URL}/${LAMBDA_PATHNAME}`;
};
