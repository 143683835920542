import store from '../../store';
import { addToast } from '../toast/actions';
import { client } from '../../utils/apiClient';

/**
 * An object that maps action identifiers to their corresponding API endpoints.
 * TODO: Deprecate this pattern in favor of using API service wrapper to make API calls (ie: GTService)
 *
 * @description
 * This object serves as a centralized mapping of actions to API endpoints. Each property in `actionLookup`
 * represents a unique action the application can perform, with the `endpoint` field specifying the URL path for
 * the associated API request. This structure allows the application to dynamically select the appropriate endpoint
 * based on an action identifier, improving code maintainability by consolidating endpoint management in one location.
 */
const actionLookup = {
  GET_OUTLET: {
    endpoint: 'outlet/getinfo',
  },
  GET_OUTLET_PROTOCOLS: {
    endpoint: 'outlet/getprotocols',
  },
  GET_OUTLET_LIST: {
    endpoint: 'outlet/getoutlets',
  },
  GET_EVENT_LIST: {
    endpoint: 'outlet/getoutletevents',
  },
  GET_OUTLET_EVENT_LIST: {
    endpoint: 'outlet/getoutletevents',
  },
  GET_AUTHNET_PROFILE_TOKEN: {
    endpoint: 'payment/gethostedprofilepage',
  },
  GET_USER_ADDRESS_LIST: {
    endpoint: 'user/getsavedaddresses',
  },
  GET_USER_PAYMENT_METHOD_LIST: {
    endpoint: 'payment/paymentmethodlist',
  },
  GET_USER_FAN_REQUESTS: {
    endpoint: 'tixrequests/getoerequest',
  },
  GET_USER_FAN_REQUEST_SERIES_SUMMARY: {
    // getUserFanRequestSeriesSummary() is the method that calls this endpoint
    // this endpoint is called in the /user/history/requests route (UserHistoryView.js) to hydrate the users requests
    // this endpoint is called in /event/{id} route (EventView.js) to determine whether a new event request should be added to an existing OEGR
    endpoint: 'tixrequests/getoegrequestsummary',
  },
  CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY: {
    endpoint: 'tixrequests/getoegrequestsummarywithcart',
  },
  GET_USER_FAN_REQUEST_SERIES_SUMMARY_FOR_CART: {
    endpoint: 'tixrequests/getoegrequestsummarywithcart',
  },
  DELETE_USER_FAN_SERIES_REQUEST: {
    endpoint: 'tixrequests/putoerequest',
  },
  UPDATE_USER_FAN_REQUEST: {
    endpoint: 'tixrequests/putoerequest',
  },
  // called during the creation of a new lottery fan request (soon to be depreated for GT-4520)
  SAVE_FAN_REQUEST: {
    endpoint: 'tixrequests/putoerequest',
  },
  UPDATE_USER_FAN_SERIES_REQUEST: {
    endpoint: 'tixrequests/putoegrequest',
  },
  UPDATE_USER_FAN_SERIES_PRIORITY: {
    endpoint: 'tixrequests/updateoegrpriority',
  },
  INIT_SESSION: {
    endpoint: 'user/sessioninit',
  },
  CHECK_SESSION: {
    endpoint: 'user/checksession',
  },
  LOGIN_USER: {
    endpoint: 'user/login',
  },
  CREATE_USER: {
    endpoint: 'user/create',
  },
  UPDATE_USER_PROFILE: {
    endpoint: 'user/updateProfile',
  },
  RESET_USER_PASSWORD: {
    endpoint: 'user/resetpassword',
  },
  CHANGE_USER_PASSWORD: {
    endpoint: 'user/changePassword',
  },
  FORGOT_USER_PASSWORD: {
    endpoint: 'user/forgotpassword',
  },
  GET_USER_ORDER: {
    endpoint: 'customerorder/get',
  },
  GET_USER_ORDER_SUMMARY_LIST: {
    endpoint: 'customerorder/getlist',
  },
  GET_USER_MOBILE_TICKET_LIST: {
    endpoint: 'customerorder/getmobileticketlist',
  },

  INSTANT_REQUEST_CREATE_CART: {
    endpoint: 'cart/createcart',
  },
  INSTANT_REQUEST_CONFIRM_SEATS: {
    endpoint: 'cart/begincheckout',
  },
  INSTANT_REQUEST_UPDATE_LINEITEMS: {
    endpoint: 'cart/updatelineitems',
  },
  INSTANT_REQUEST_UPDATE_DELIVERY_METHOD: {
    endpoint: 'cart/adddeliverymethod',
  },
  INSTANT_REQUEST_UPDATE_SHIPPING_ADDRESS: {
    endpoint: 'cart/addshippingaddress',
  },
  INSTANT_REQUEST_UPDATE_PAYMENT_METHOD: {
    endpoint: 'cart/addpaymentmethod',
  },
  INSTANT_REQUEST_CONFIRM_ORDER: {
    endpoint: 'cart/createorder',
  },
  UPDATE_USER_ADDRESS: {
    endpoint: 'user/updatesavedaddress',
  },
  CREATE_USER_ADDRESS: {
    endpoint: 'user/createsavedaddress',
  },
  DELETE_USER_ADDRESS: {
    endpoint: 'user/deletesavedaddress',
  },
  UPDATE_USER_ORDER_ADDRESS: {
    endpoint: 'customerorder/updateaddress',
  },
  UPDATE_USER_ORDER_PAYMENT: {
    endpoint: 'customerorder/updatescheduledpayments',
  },
  DELETE_USER_PAYMENT_METHOD: {
    endpoint: 'payment/disablepaymentmethod',
  },
  GET_GUEST_LIST_INVITATION: {
    endpoint: 'guestlist/getTokenInfo',
  },
  CREATE_GUEST_LIST_REQUEST: {
    endpoint: 'guestlist/createRequest',
  },
  UPDATE_OUTLET_EVENT_ITEM_UPGRADE: {
    endpoint: 'customerorder/updateOutletEventItemUpgrade',
  },
  CAPTURE_SCHEDULED_PAYMENT: {
    endpoint: 'customerorder/capturescheduledpayment',
  },
  LOGIN_PRIVATE_OUTLET: {
    endpoint: 'user/checkoutletpassword',
  },
  UPDATE_OUTLET_EVENT_ITEM_REFUND_REQUEST: {
    endpoint: 'customerorder/requestoeirefund',
  },
};

/**
 * Dispatches an API request with a specified action, endpoint, and payload.
 * Manages success and error responses, optionally showing toast notifications.
 *
 * @function api
 * @param {Object} params - The configuration object for the API call.
 * @param {string} params.action - The action type for the request, used to determine the endpoint if not specified.
 * @param {string} [params.endpoint] - The API endpoint to call. If not provided, it will be derived from the action.
 * @param {Object} [params.payload] - The data payload to be sent with the API request.
 * @param {boolean} [params.showToast=false] - Whether to display a toast notification in case of an error.
 *
 * @return {Promise<Object>} - A promise that resolves with the API response data if successful, or rejects with an 
 * error message if the request fails.
 *
 * @description
 * This function initiates an API request based on the specified action or endpoint.
 * It dispatches an `API_INIT` action at the start, an `API_SUCCESS` action upon success,
 * and an `API_ERROR` action on failure. If an "Invalid Session" error is detected,
 * an `INVALID_SESSION` action is dispatched. Optionally, an error toast is shown if `showToast` is set to true.
 */
export const api = ({ action, endpoint, payload, showToast }) => {
  store.dispatch({ type: 'API_INIT', action });
  const ep = endpoint || actionLookup[action].endpoint;

  return new Promise((resolve, reject) => {
    client(ep, { body: payload }).then((res) => {
      // TODO: Do we need to check for error here?
      // The same logic is also duplicated below in the catch block.
      if (res.error) {
        const message = res.message || 'api response error';

        if (message === 'Invalid Session') {
          store.dispatch({
            action,
            invalidSession: true,
            type: 'INVALID_SESSION',
          });
        } else {
          store.dispatch({
            type: 'API_ERROR',
            action,
          });
        }

        if (showToast) {
          addToast({
            title: 'API error',
            description: message,
            type: 'error',
          });
        }

        if (action === 'DELETE_USER_PAYMENT_METHOD') {
          let deleteRes = { message };
          if (res.response) {
            deleteRes = { ...deleteRes, ...res.response };
          }
          reject(deleteRes);
        }
        reject(message);
      } else {
        store.dispatch({
          type: 'API_SUCCESS',
          action,
        });
        if (res.response) {
          resolve(res.response);
        } else {
          resolve(res);
        }
      }
    }).catch(async (res) => {
      const { status } = res;
      const response = await res.json();
      const errorMessage = response.message || `api server error. status code: ${status}`;

      if (errorMessage === 'Invalid Session') {
        store.dispatch({
          action,
          invalidSession: true,
          type: 'INVALID_SESSION',
        });
      } else {
        store.dispatch({
          type: 'API_ERROR',
          action,
        });
      }

      if (showToast) {
        addToast({
          title: 'API server error',
          description: action + ' - ' + errorMessage,
          type: 'error',
        });
      }

      reject(errorMessage);
    });
  });
};
