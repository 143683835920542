import React from 'react';
import { formatCurrency } from '../../utils/utilities';
import { LineItemRow, OutletEventItemHeader } from '../cart/LineItemSummary';
import { DisplayFees } from '../../utils/DisplayContent';
import LineItemPreferenceList from '../cart/LineItemPreferenceList';

/**
 * Renders details for an add-on line item, including its label, quantity, and associated fees.
 *
 * This component formats and displays add-on items with a price level, including their total cost and associated fees.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.lineItem - The line item data.
 * @param {string} props.lineItem.PriceLevelName - The name of the price level.
 * @param {number} props.lineItem.PriceLevelValue - The value of the price level.
 * @param {number} [props.lineItem.quantity] - The quantity of the line item.
 * @param {number} [props.lineItem.Quantity] - An alternate field for the quantity of the line item.
 * @param {Array<Object>} [props.lineItem.FeeGroups] - Fee groups associated with the line item.
 * @return {React.FC}
 *
 * @example
 * const lineItem = {
 *   PriceLevelName: "VIP Seating",
 *   PriceLevelValue: 100,
 *   quantity: 2,
 *   FeeGroups: [{ FeeName: "Service Fee", FeeAmount: 5 }],
 * };
 * <LineItemAddOn lineItem={lineItem} />
 *
 * @dependencies
 * - `LineItemRow`: Component for rendering the line item row details.
 * - `DisplayFees`: Component for displaying associated fees for the line item.
 */
const LineItemAddOn = ({ lineItem }) => {
  const quantity = lineItem.quantity || lineItem.Quantity || 0;
  const label = `${lineItem.PriceLevelName} ${formatCurrency(lineItem.PriceLevelValue)} x ${quantity}`;

  return (
    <>
      <LineItemRow
        hasLeftBorder
        hasRightBorder
        label={label}
        showTotal={true}
        total={lineItem.PriceLevelValue * quantity}
      />
      <DisplayFees
        FeeGroups={lineItem?.FeeGroups}
        quantity={quantity}
        hasLeftBorder={true}
        hasRightBorder={true}
      />
    </>
  );
};

/**
 * Renders default line item preferences, including selected quantity and price level.
 *
 * This component displays the details of a standard line item, using a preference list for user interaction.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.lineItem - The line item data.
 * @param {number} [props.lineItem.quantity] - The quantity of the line item.
 * @param {number} [props.lineItem.Quantity] - An alternate field for the quantity of the line item.
 * @param {number} props.lineItem.PriceLevelID - The ID of the price level.
 * @param {boolean} [props.lineItem.PriceLevelOptOut=false] - Whether the price level opt-out option is selected.
 * @param {Object} props.oei - The Outlet Event Item data associated with the line item.
 * @return {React.FC}
 *
 * @example
 * const lineItem = { PriceLevelID: 1, quantity: 2, PriceLevelOptOut: false };
 * const oei = { OutletEventItemID: 101, Name: "Concert" };
 * <LineItemDefault lineItem={lineItem} oei={oei} />
 *
 * @dependencies
 * - `LineItemPreferenceList`: Component for rendering user preferences for the line item.
 */
const LineItemDefault = ({ lineItem, oei }) => (
  <LineItemPreferenceList
    selectedQty={lineItem.quantity || lineItem.Quantity}
    outletEventItem={oei}
    selectedPriceLevelId={lineItem.PriceLevelID}
    optOutIsSelected={Boolean(lineItem.PriceLevelOptOut)}
    onlyPreferenceIsVisible={false}
  />
);

/**
 * Renders an Outlet Event Item (OEI) and its associated line items.
 *
 * This component displays details for an outlet event item, including its header and associated line items.
 * It supports add-on and default line items and provides an edit option.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.oei - The Outlet Event Item data.
 * @param {Array<Object>} props.oei.lineItems - The line items associated with the Outlet Event Item.
 * @param {Function} props.handleClickEditFanRequestLineItems - Callback triggered when the "Edit" button is clicked.
 * @return {React.FC}
 *
 * @example
 * const oei = {
 *   OutletEventItemID: 101,
 *   Name: "Concert",
 *   lineItems: [{ PriceLevelID: 1, isAddOn: false, quantity: 2 }],
 * };
 * const handleEdit = (data) => console.log("Edit clicked:", data);
 * <OutletEventItem oei={oei} handleClickEditFanRequestLineItems={handleEdit} />
 *
 * @dependencies
 * - `OutletEventItemHeader`: Component for rendering the header of the Outlet Event Item.
 * - `LineItemAddOn`: Component for rendering add-on line items.
 * - `LineItemDefault`: Component for rendering default line items.
 */
const OutletEventItem = ({ oei, handleClickEditFanRequestLineItems }) => (
  <div>
    <OutletEventItemHeader
      oei={oei}
      onClickEdit={() => {
        const title = 'Edit ' + (oei.OutletEventItemName || oei.Name);
        handleClickEditFanRequestLineItems({
          title,
          outletEventItems: [oei],
        });
      }}
    />
    {oei.lineItems?.map((lineItem, idx) => (
      <div key={idx}>
        {lineItem.isAddOn ? (
          <LineItemAddOn lineItem={lineItem} />
        ) : (
          <LineItemDefault lineItem={lineItem} oei={oei} />
        )}
      </div>
    ))}
  </div>
);

/**
 * Component for displaying a list of line items grouped by Outlet Event Items (OEI).
 *
 * This component iterates through a normalized list of line items (`lineItemsNormalizedByOEI`) 
 * and renders an `OutletEventItem` component for each group. It supports handling "Edit" actions 
 * for individual line items via a callback function.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Array<Object>} props.lineItemsNormalizedByOEI - Array of normalized line items grouped by Outlet Event Items (OEI).
 * Each object represents an event item with its associated line items.
 * @param {Object} props.lineItemsNormalizedByOEI[].oei - The Outlet Event Item data.
 * @param {Array<Object>} props.lineItemsNormalizedByOEI[].lineItems - The line items associated with the Outlet Event Item.
 * @param {Function} props.handleClickEditFanRequestLineItems - Callback function triggered when the "Edit" button for an event item is clicked.
 * The callback receives data about the selected event item.
 * @return {React.FC}
 *
 * @remarks
 * - Relies on the `OutletEventItem` component for rendering individual event item groups.
 * - Does not render anything if the `lineItemsNormalizedByOEI` array is empty.
 * - Each `OutletEventItem` manages its own line item details, including add-ons and default preferences.
 *
 * @dependencies
 * - `OutletEventItem`: Component responsible for rendering the details of each Outlet Event Item, including headers and line items.
 */
const RequestLineItems = ({ lineItemsNormalizedByOEI, handleClickEditFanRequestLineItems }) => (
  <>
    {lineItemsNormalizedByOEI.length > 0 &&
      lineItemsNormalizedByOEI.map((oei, idx) => (
        <OutletEventItem
          key={idx}
          oei={oei}
          handleClickEditFanRequestLineItems={handleClickEditFanRequestLineItems}
        />
      ))}
  </>
);

export default RequestLineItems;
