import styled from 'styled-components';
import React, { useState } from 'react';
import Button from '../../utils/Button';
import DisplayError from '../../utils/Error';
import Modal from '../../utils/Modal';
import PaymentMethodForm from '../../utils/PaymentMethodForm';
import Typography from '../../utils/Typography';
import { addToast } from '../toast/actions';
import { captureScheduledPayment, getUserOrder } from '../user/actions';
import { formatCurrency, formatDate } from '../../utils/utilities';

const PaymentInfoContainerStyled = styled.div`
  padding: 1rem 0;
  margin: 0 0 2rem 0;
  //border: solid 1px ${(props) => props.theme.PRIMARY_GRAY};
`;

function calcPaymentPlanFirstPayment(amount, PaymentPlanRules) {
  if (PaymentPlanRules && amount >= PaymentPlanRules.eligibilityMinimum) {
    amount =
        amount * PaymentPlanRules.firstPaymentPercent + PaymentPlanRules.fee;

    amount = Math.round((amount + Number.EPSILON) * 100) / 100;
  }

  return amount;
}

function PaymentPlanForm(props = {}) {
  const {
    request,
    seriesSummary,
    userPaymentMethodList,
    onCreateViaIFrame,
    total,
    paymentList,
    onChangePaymentMethod,
    loading,
    readOnly,
    outletEventGroup,
    payNowIsAvailable,
    paymentPlanRules,
  } = props;

  const [payNowIsLoading, setPayNowIsLoading] = useState(false);
  const [payNowError, setPayNowError] = useState(null);
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
  });

  const handleClickPayNowBtn = (paymentMethod) => {
    setPayNowError(null);
    setModal({
      constant: 'CONFIRM_PAY_NOW',
      title: 'Pay Now',
      isVisible: true,
      paymentMethod,
    });
  };

  const GetUserFriendlyFailedMessage = (failedMessage) => {
    if (failedMessage == 'ErrorCode 2 - This transaction has been declined.') {
      return 'Transaction declined. Please provide a valid credit card number and complete your payment for this installment.';
    }
  };
  const handleClickConfirmPayNowBtn = (paymentMethod) => {
    if (
        paymentMethod &&
        paymentMethod.CustomerOrderScheduledPaymentID &&
        paymentMethod.CustomerOrderID
    ) {
      const { CustomerOrderScheduledPaymentID, CustomerOrderID } =
          paymentMethod;

      setPayNowIsLoading(true);
      setPayNowError(null);
      const request = {
        CustomerOrderScheduledPaymentID,
        CustomerOrderID,
      };
      console.log('handleClickConfirmPayNowBtn request ', request);
      captureScheduledPayment(request).then((res) => {
        setPayNowIsLoading(false);
        addToast({
          title: 'Success!',
          description: ' Thank you for your payment',
          //type: "error",
        });
        setModal({
          constant: '',
          title: '',
          isVisible: false,
        });
        getUserOrder({ OrderID: CustomerOrderID, forceRefresh: true }).then(
            (order) => {
            },
        );
      }).catch((error) => {
        setPayNowError(error);
        setPayNowIsLoading(false);
      });
    } else {
      alert('Missing payment method details');
    }
  };

  if (paymentPlanRules) {
    const {
      fee,
      totalPayments,
      eligibilityMinimum,
      firstPaymentMinimum,
      firstPaymentPercent,
    } = paymentPlanRules;

    // let firstPayment = (total - 25) * 0.4 + 25;
    // let secondPayment = (total - 25) * 0.3;
    // let thirdPayment = total - (firstPayment + secondPayment);
    // let installmentTotal = firstPayment;

    // if (idx === 1) {
    //   percentMessage = "30% of awarded total";
    //   installmentTotal = secondPayment;
    // }
    // if (idx === 2) {
    //   percentMessage = "Remainder of awarded total";
    //   installmentTotal = thirdPayment;
    // }
    //const totalPayments = 3;
    const installments = [...Array(totalPayments)].map((x, idx) => {
      return { Sort: idx + 1 };
    });

    const firstPayment = calcPaymentPlanFirstPayment(
        total,
        paymentPlanRules,
    );
    let StartingRemainder = total - firstPayment;
    let RemainingTotal = total - firstPayment;

    return (
        <div>
          {installments.map((i, idx) => {
            let paymentMethod,
                installmentTotal,
                percentDisplay,
                percentMessage,
                processDate;

            if (idx === 0) {
              installmentTotal = firstPayment;
              percentDisplay = Math.round(
                  (firstPaymentPercent + Number.EPSILON) * 100,
              );
              percentMessage =
                  percentDisplay +
                  '% of awarded total + ' +
                  formatCurrency(fee) +
                  ' Payment plan fee';
            } else {
              if (idx < totalPayments - 1) {
                // so.. not the last payment
                let percentNumber = Math.round(
                    ((1 - firstPaymentPercent) / (totalPayments - 1) +
                        Number.EPSILON) *
                    100,
                );
                percentMessage = percentNumber + ' % of awarded total';
                installmentTotal = (total * percentNumber / 100);
                console.log('payment RemainingTotal 2nd', RemainingTotal); // 305

                installmentTotal =
                    Math.round((installmentTotal + Number.EPSILON) * 100) / 100;
                RemainingTotal -= installmentTotal;
                console.log('payment RemainingTotal 2nd after', RemainingTotal); // 140

              } else {
                let percentNumber = Math.round(
                    ((1 - firstPaymentPercent) / (totalPayments - 1) +
                        Number.EPSILON) *
                    100,
                );
                percentMessage = 'Remainder of awarded total';
                installmentTotal = (total * percentNumber / 100);
                installmentTotal =
                    Math.round((installmentTotal + Number.EPSILON) * 100) / 100;
              }
            }

            if (Array.isArray(paymentList)) {
              paymentMethod = paymentList.find(
                  (pm) => Number(pm.Sort) === Number(idx + 1),
              );
              if (paymentMethod && paymentMethod.PaymentMethod) {
                paymentMethod = {
                  ...paymentMethod,
                  ...paymentMethod.PaymentMethod,
                };
              }
            }
            console.log(
                'PaymentPlanForm paymentMethod ' + idx + ' ',
                paymentMethod,
            );
            if (outletEventGroup) {
              if (idx === 0) {
                processDate = outletEventGroup.PaymentPlanInstallment1Date;
              }
              if (idx === 1) {
                processDate = outletEventGroup.PaymentPlanInstallment2Date;
              }
              if (idx === 2) {
                processDate = outletEventGroup.PaymentPlanInstallment3Date;
              }
            }

            let processText =
                idx === 0 ? 'Process on or around ' : 'Processes on ';
            if (processDate) {
              processText += formatDate(processDate, {
                timeZone: 'UTC',
              });
            }

            let paymentIsPaid = paymentMethod && paymentMethod.Captured;
            let paymentIsFailed = paymentMethod && paymentMethod.Failed;

            return (
                <PaymentInfoContainerStyled key={idx}>
                  <Typography tag="h5" style={{ margin: '0 0 .5rem 0' }}>
                    Installment {idx + 1} -
                    {paymentMethod &&
                    paymentMethod.Amount &&
                    Number(paymentMethod.Amount) > -1
                        ? formatCurrency(paymentMethod.Amount)
                        : formatCurrency(installmentTotal)}
                    <>
                      {paymentIsPaid ? (
                          <span style={{ color: 'green' }}> - Paid</span>
                      ) : paymentIsFailed ? (
                          <span style={{ color: 'red' }}>
                      {' '}
                            -{' '}
                            {(paymentMethod && GetUserFriendlyFailedMessage(paymentMethod.FailedMessage)) ||
                                'Attention needed'}
                    </span>
                      ) : null}
                    </>
                  </Typography>
                  {true && (
                      <div style={{ fontSize: '1.2rem' }}>{percentMessage}</div>
                  )}
                  {processDate && processText && (
                      <div style={{ fontSize: '1.2rem' }}>{processText}</div>
                  )}
                  {readOnly || (paymentMethod && paymentMethod.Captured) ? (
                      <div>
                        <Typography>
                          {paymentMethod && paymentMethod.CardHolderName}
                        </Typography>
                        <Typography>{paymentMethod && paymentMethod.Type}</Typography>
                        <Typography>
                          **** {paymentMethod && paymentMethod.LastFour}
                        </Typography>
                        {/* <Typography>Expire: {paymentMethod && paymentMethod.Expiration} </Typography> */}
                      </div>
                  ) : loading ? (
                      '...loading'
                  ) : (
                      <div>
                        <PaymentMethodForm
                            disabled={readOnly}
                            defaultMethod={paymentMethod}
                            paymentMethods={userPaymentMethodList}
                            onSelect={(pm) => {
                              onChangePaymentMethod &&
                              onChangePaymentMethod({
                                ...pm,
                                Sort: idx + 1,
                              });
                            }}
                            onCreateViaIFrame={(p) => {
                              onCreateViaIFrame &&
                              onCreateViaIFrame({ iframeResponse: p, Sort: idx + 1 });
                            }}
                        />
                        {payNowIsAvailable && (
                            <div>
                              <Button
                                  size={'small'}
                                  onClick={() => handleClickPayNowBtn(paymentMethod)}
                                  loading={payNowIsLoading}
                              >
                                Pay Now
                              </Button>
                            </div>
                        )}
                      </div>
                  )}

                  {/* <DisplayPaymentMethod paymentMethod={paymentMethod} /> */}
                </PaymentInfoContainerStyled>
            );
          })}
          <Modal
              title={modal.title}
              open={modal.isVisible}
              onClose={() => setModal({ isVisible: false })}
          >
            {modal.constant === 'CONFIRM_PAY_NOW' && (
                <div>
                  <div>
                    <Typography tag="p">
                      <strong>ARE YOU SURE?</strong>
                    </Typography>
                    <Typography tag="p">
                      Capture Amt:{' '}
                      <strong>
                        {modal.paymentMethod &&
                            formatCurrency(modal.paymentMethod.Amount)}
                      </strong>{' '}
                      <br/>
                      Payment #:{' '}
                      <strong>
                        {modal.paymentMethod && modal.paymentMethod.Sort}
                      </strong>
                    </Typography>
                    {modal.paymentMethod && (
                        <Typography tag="p">
                    <span>
                      Name:{' '}
                      <strong>{modal.paymentMethod.CardHolderName}</strong>
                    </span>
                          <br/>
                          <span>
                      Type: <strong>{modal.paymentMethod.Type}</strong>
                    </span>{' '}
                          <br/>
                          <span>
                      Exp: <strong>{modal.paymentMethod.Expiration}</strong>
                    </span>{' '}
                          <br/>
                          <span>
                      Last Four:{' '}
                            <strong>**** {modal.paymentMethod.LastFour}</strong>
                    </span>{' '}
                          <br/>
                        </Typography>
                    )}
                  </div>
                  {payNowError && <DisplayError>{payNowError}</DisplayError>}
                  <div>
                    {payNowError ? (
                        <Button
                            loading={payNowIsLoading}
                            onClick={() => {
                              setPayNowError(null);
                              setModal({ isVisible: false });
                            }}
                            secondary
                        >
                          Select or add new payment method
                        </Button>
                    ) : (
                        <>
                          <Button
                              onClick={() =>
                                  handleClickConfirmPayNowBtn(modal.paymentMethod)
                              }
                              loading={payNowIsLoading}
                              style={{ margin: '0 2rem 0 0' }}
                          >
                            Yes I'm Sure
                          </Button>
                          {!payNowIsLoading && (
                              <Button
                                  loading={payNowIsLoading}
                                  onClick={() => {
                                    setPayNowError(null);
                                    setModal({ isVisible: false });
                                  }}
                                  secondary
                              >
                                Nevermind
                              </Button>
                          )}
                        </>
                    )}
                  </div>
                </div>
            )}
          </Modal>
        </div>
    );
  }
  return null;
}

export default PaymentPlanForm;
