import React from 'react';
import styled from 'styled-components';
import { DisplayHeader } from '../../utils/DisplayContent';
import { CheckboxInput } from '../../utils/Form';
import Typography from '../../utils/Typography';
import Button from '../../utils/Button';

const ActionBtnContainerStyled = styled.div`
  padding: 0rem 0;
`;

/**
 * Component for displaying and managing acceptance of terms and conditions during a checkout or registration process.
 *
 * This component renders a checkbox for users to accept the terms and conditions, privacy policy, and ticket guidelines.
 * It includes links to the respective policies and a "Submit Request" button to proceed after acceptance.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.userAcceptsTermsOfService - Indicates whether the user has accepted the terms of service.
 * @param {Object} [props.outletTermsAndConditions] - The terms and conditions policy object.
 * @param {string} [props.outletTermsAndConditions.Headline] - The headline text of the terms and conditions policy.
 * @param {Object} [props.outletPrivacyPolicy] - The privacy policy object.
 * @param {string} [props.outletPrivacyPolicy.Headline] - The headline text of the privacy policy.
 * @param {Object} [props.ticketGuidlines] - The ticket guidelines policy object.
 * @param {string} [props.ticketGuidlines.Headline] - The headline text of the ticket guidelines.
 * @param {Function} props.handleOnChangeAcceptsTermsOfService - Callback triggered when the user toggles the acceptance checkbox.
 * @param {Function} props.handleClickPolicy - Callback triggered when a policy link is clicked.
 * @param {Function} props.handleClickSubmitRequest - Callback triggered when the "Submit Request" button is clicked.
 * @param {boolean} props.loading - Indicates if the submit request is in progress.
 * @return {React.FC}
 *
 * @remarks
 * - Disables the "Submit Request" button until the user accepts the terms and conditions.
 * - Displays links to policies dynamically if `Headline` values are provided.
 * - Uses `Typography` for consistent styling of text and links.
 *
 * @dependencies
 * - `DisplayHeader`: Component for rendering the header.
 * - `CheckboxInput`: Input component for toggling acceptance.
 * - `Button`: Button component for submitting the request.
 * - `Typography`: Text component for rendering links and styled content.
 */
const TermsAndConditions = ({
  userAcceptsTermsOfService,
  outletTermsAndConditions,
  outletPrivacyPolicy,
  ticketGuidlines,
  handleOnChangeAcceptsTermsOfService,
  handleClickPolicy,
  handleClickSubmitRequest,
  loading,
}) => (
  <div style={{ marginBottom: '2rem' }}>
    <DisplayHeader title="Terms & Conditions" />
    <ActionBtnContainerStyled>
      <div style={{ display: 'flex' }}>
        <CheckboxInput
          name="userAccepts"
          checked={userAcceptsTermsOfService}
          onChange={handleOnChangeAcceptsTermsOfService}
        />
        <div style={{ flex: 1, paddingLeft: '.5rem' }}>
          <Typography>
            I accept the{' '}
            {outletTermsAndConditions?.Headline ? (
              <Typography
                tag="a"
                onClick={() => handleClickPolicy(outletTermsAndConditions)}
              >
                {outletTermsAndConditions.Headline}
              </Typography>
            ) : (
              'terms and conditions'
            )}{' '}
            and{' '}
            {outletPrivacyPolicy?.Headline ? (
              <Typography
                tag="a"
                onClick={() => handleClickPolicy(outletPrivacyPolicy)}
              >
                {outletPrivacyPolicy.Headline}
              </Typography>
            ) : (
              'privacy policy'
            )}
            {ticketGuidlines && (
              <>
                {' '}
                and{' '}
                <Typography
                  tag="a"
                  onClick={() => handleClickPolicy(ticketGuidlines)}
                >
                  {ticketGuidlines.Headline || 'Ticket Guidelines'}
                </Typography>
              </>
            )}
          </Typography>
        </div>
      </div>
      <Button
        fullWidth
        name="btn-complete"
        loading={loading}
        id="complete-order-request"
        onClick={handleClickSubmitRequest}
        disabled={!userAcceptsTermsOfService || loading}
      >
        Submit Request
      </Button>
    </ActionBtnContainerStyled>
  </div>
);

export default TermsAndConditions;
