import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Typography from '../../utils/Typography';
import { IconCalendar, IconClock, IconMapPin } from '../../utils/Icon';
import { calculateSeriesStatuses, formatDate, formatTime } from '../../utils/utilities';

const EventSummaryBlock = styled.div`
  font-size: 1.6rem;
  padding: 1.5rem;
  color: ${(props) => props.theme.HEADER_TEXT_COLOR};
  background-color: ${(props) => props.theme.HEADER_BACKGROUND};
  border: ${(props) => 'solid 1px' + props.theme.PRIMARY_GRAY + '80'};
`;

const DetailRowStyled = styled(Typography)`
  display: flex;
  margin: 0 0 1rem 0;
`;

const IconContainerStyled = styled.div`
  //color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  svg {
    // color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
    fill: none;
    width: 10px;
    height: 10px;
  }
`;

const DetailContainerStyled = styled.div`
  flex: 1;
  padding: 0 1rem;
`;

/**
 * Component to display a summary of an outlet event, including details like event name, date, venue, 
 * and series statuses. Provides a structured view of the event details with optional styling and icons.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.outletEvent - The outlet event data object containing event details.
 * 
 * @return {JSX.Element|null} - The rendered summary of the outlet event or null if no event data is provided.
 *
 * @example
 * <OutletEventSummary outletEvent={eventData} />
 *
 * @remarks
 * - Utilizes `useSelector` to access outlet-related data from the Redux store.
 * - Dynamically calculates and displays headline name based on outlet type.
 * - Formats and displays event date, time, venue, and series status.
 * - Includes conditional logic for refund window visibility.
 *
 * @dependencies
 * - `styled-components` for styling.
 * - `react-redux` for state management.
 * - Utility functions for date/time formatting and series status calculations.
 *
 * @see {@link calculateSeriesStatuses}
 * @see {@link formatDate}
 * @see {@link formatTime}
 */
function OutletEventSummary(props) {
  const outlet = useSelector((state) => state.outlet);

  const {
    outletEvent,
  } = props;

  if (!outletEvent) {
    return null;
  }
  let {
    EventTable,
    OutletSeriesID,
    OutletEventGroup,
    OutletEventDoorTime,
    OutletEventOverrideName,
    OutletEventDoorTimeStatus,
  } = outletEvent;

  const Event = outletEvent.Event || EventTable;

  const displayEventShowTime = Event && Event.DisplayShowTime;

  const eventName = Event.Name;
  //const eventImgMobile = Event.EventImageMobile;
  const venueName = Event.Venue && Event.Venue.VenueName;
  const city = Event.Venue && Event.Venue.City;
  const state = Event.Venue && Event.Venue.State;
  //const GoogleMapLink = Event.Venue && Event.Venue.GoogleMapLink;
  const showDate = Event.ShowDate;
  //const seatMap = Event.SeatMapImage;
  let artistName = '';
  if (Event.Artist && Event.Artist.ArtistName) {
    artistName = Event.Artist.ArtistName;
  }
  const iconSize = 20;
  let headlineName = OutletEventOverrideName;

  //RLMTS-1738 new logic for headline below:
  //Artist Outlet (1): Use Outlet Event Override if set, otherwise Artisist Name(RLMTS-2565). It was previously - Venue Name
  //Venue Outlet (2): Use Outlet Event Override if set, otherwise Artist Name
  //Guest List (3): Use Outlet Event Override if set, otherwise Event Name, else nothing
  //Point of Sale (4): no rules defined (so im defaulting to option 1)

  if (!headlineName) {
    if (outlet.OutletTypeID === 1) {
      headlineName = artistName;
    }
    if (outlet.OutletTypeID === 2) {
      headlineName = artistName;
    }
    if (outlet.OutletTypeID === 3) {
      headlineName = eventName;
    }
    if (outlet.OutletTypeID === 4) {
      headlineName = venueName;
    }
  }

  //OutletEventGroup is not included on all outletEvent objects
  //So grab it from the outlet object
  if (!OutletEventGroup && OutletSeriesID) {
    OutletEventGroup =
        outlet &&
        outlet.OutletEventGroups &&
        outlet.OutletEventGroups.find(
            (oeg) => oeg.OutletEventGroupID === OutletSeriesID,
        );
  }

  const { seriesIsOpen, seriesIsVisible, seriesIsClosed } =
      calculateSeriesStatuses(OutletEventGroup);

  return (
      <EventSummaryBlock>
        {headlineName && (
            <Typography tag="h1" style={{ margin: '0 0 1rem 0' }}>
              {headlineName}
            </Typography>
        )}

        {showDate && (
            <DetailRowStyled>
              <IconContainerStyled>
                <IconCalendar height={iconSize} width={iconSize}/>
              </IconContainerStyled>
              <DetailContainerStyled>
                {formatDate(showDate, {
                  weekday: 'long',
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}

                {displayEventShowTime && (
                    <span>
                {' '}
                      @{' '}
                      {formatTime(showDate, {
                        hour: 'numeric',
                        minute: 'numeric',
                      })}
              </span>
                )}

                {OutletEventDoorTimeStatus && OutletEventDoorTime ? (
                    <Typography>
                      Doors Open @{' '}
                      {formatTime(OutletEventDoorTime, {
                        hour: 'numeric',
                        minute: 'numeric',
                      })}
                    </Typography>
                ) : null}
              </DetailContainerStyled>
            </DetailRowStyled>
        )}

        {venueName && (
            <DetailRowStyled>
              <IconContainerStyled>
                <IconMapPin height={iconSize} width={iconSize}/>
              </IconContainerStyled>
              <DetailContainerStyled>
                {venueName}
                <br/>
                {city}, {state}
              </DetailContainerStyled>
            </DetailRowStyled>
        )}

        {OutletEventGroup &&
            OutletEventGroup.Name &&
            OutletEventGroup.ShowSeriesName && (
                <DetailRowStyled>
                  <IconContainerStyled>
                    <IconClock height={iconSize} width={iconSize}/>
                  </IconContainerStyled>
                  <DetailContainerStyled>
                    {OutletEventGroup.Name}{' '}
                    <span>
                {seriesIsClosed
                    ? ' - Closed'
                    : seriesIsOpen
                        ? ' - Open'
                        : seriesIsVisible
                            ? ''
                            : null}
              </span>
                  </DetailContainerStyled>
                </DetailRowStyled>
            )}
        {outletEvent.OutletEventItems.some(oei => oei.AllowRefund) && outletEvent.Event.RefundWindowOpen === true ?
            <span style={{ color: 'red' }}>{outletEvent.Event.RefundRequestWindowOpenText}</span> : null}
        {outletEvent.OutletEventItems.some(oei => oei.AllowRefund) && outletEvent.Event.RefundWindowOpen === false ?
            <span style={{ color: 'red' }}>{outletEvent.Event.RefundRequestWindowClosedText}</span> : null}
      </EventSummaryBlock>
  );
}

export default OutletEventSummary;
