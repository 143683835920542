import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Attention from '../utils/Attention';
import Button from '../utils/Button';
import Contain from '../components/app/Contain';
import Loading from '../utils/Loading';
import Modal from '../utils/Modal';
import OutletEventList from '../components/outletEvent/OutletEventList';
import OutletImage from '../components/outlet/OutletImage';
import PromoImageList from '../utils/PromoImageList';
import Slider from '../components/carousel/Slider';
import Typography from '../utils/Typography';
import { IconExternalLink } from '../utils/Icon';
import { goToUrl } from '../utils/utilities';
import { resetGuestListCart } from '../components/cart/actions';

const FilterRowStyled = styled.div`
  margin: 2rem 0;
  display: grid;
  grid-template-rows: auto;
  gap: 1rem;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    grid-template-columns: auto 1fr auto;
    justify-items: center;
    align-items: center;
  }
`;
const FilterItemStyled = styled.span`
  font-size: 1.6rem;
  padding: 0 0.5rem;
    //color: ${(props) => (props.selected ? 'gray' : 'initial')};
`;

let DefaultExternalLinkPolicy = {
  Headline: 'So this is goodbye?',
  PolicyText: `This event is currenlty available only on Ticketmaster.com.
    By clicking the Find Tickets button you will be leaving this site and taken to the Ticketmaster event page.
    To continue shopping for tickets here, simply click cancel`,
};

export default function EventsView(props) {
  const [outletEvent, setOutletEvent] = useState({});
  const [filterByArtistID, setFilterByArtistID] = useState(0);
  const [modal, setModal] = useState({
    title: '',
    content: '',
    constant: null,
    isVisible: false,
  });
  const api = useSelector((state) => state.api);
  const outlet = useSelector((state) => state.outlet);
  const outletEventList = useSelector((state) => state.eventList);
  const guestListRequestCart = useSelector((state) => state.guestRequest.cart);
  const guestListInvitationError = useSelector((state) => state.auth.guestListInvitationError);

  useEffect(() => {
    resetGuestListCart();
    //RLMTS-2715 auto scroll to top when page loads
    var element = document.getElementById('root');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);


  const onClickSubmitGuestListPricelevels = () => {
    goToUrl('/checkout/g/contact-info');
  };

  const handleClickFilterEvents = (ArtistID) => {
    setFilterByArtistID(ArtistID);
  };

  const handleClickEventDetails = (outletEvent = {}) => {
    //  salesTypeId 1 = Instant tix
    //  salesTypeId 2 = Fan Request
    //  salesTypeId 3 = External sales url
    if (outletEvent.SalesTypeID === 1 || outletEvent.SalesTypeID === 2) {
      goToUrl('/event/' + outletEvent.OutletEventID);
    }

    if (outletEvent.SalesTypeID === 3) {
      const customerExternalLinkPolicy =
        outlet && Array.isArray(outlet.OutletPolicies)
          ? outlet.OutletPolicies.find(
            (policy) => policy.OutletPolicyTypeID === 6,
          )
          : null;

      const externalLinkPolicy = customerExternalLinkPolicy || DefaultExternalLinkPolicy;

      setOutletEvent(outletEvent);
      setModal({
        title: externalLinkPolicy.Headline,
        content: externalLinkPolicy.PolicyText,
        isVisible: true,
      });
    }
  };

  let artistList = [{ ArtistID: 0, ArtistName: 'Show All' }];
  outletEventList.map(
    (oe) =>
      oe.Event &&
      oe.Event.Artist &&
      oe.Event.Artist.ArtistID &&
      artistList.push(oe.Event.Artist),
  );
  //remove duplicate artists
  artistList = artistList.filter(
    (thing, index, self) =>
      self.findIndex((t) => t.ArtistID === thing.ArtistID) === index,
  );

  let outletEventListVisible = [...outletEventList];
  if (filterByArtistID) {
    outletEventListVisible = outletEventListVisible.filter(
      (oe) => oe.Event.Artist && oe.Event.Artist.ArtistID === filterByArtistID,
    );
  }

  outletEventListVisible.sort((a, b) => new Date(a.Event.ShowDate) - new Date(b.Event.ShowDate));

  const carouselImageList = outlet && outlet.OutletTheme && outlet.OutletTheme.CarouselImages;
  const loadingApiConstants = ['GET_EVENT_LIST', 'GET_GUEST_LIST_INVITATION'];
  const eventsAreLoading =
    api && api.loading.some((r) => loadingApiConstants.includes(r));
  return (
    <div style={{ height: '100%' }}>
      <OutletImage />

      {outlet.Description && (
        <Attention>
          <Contain>
            <div
              dangerouslySetInnerHTML={{ __html: outlet.Description }}
            />
          </Contain>
        </Attention>
      )}

      <Contain hasPadding={true}>
        {eventsAreLoading ? (
          <Loading message="Loading events" align="center" />
        ) : (
          <>
            <FilterRowStyled>
              <div>
                <Typography tag="h1">All Events</Typography>
              </div>
              <div>
                {Array.isArray(artistList) && artistList.length > 1
                  ? artistList.map((a, idx) => {
                    const isSelected = a.ArtistID === filterByArtistID;
                    return (
                      <React.Fragment key={idx}>
                        <FilterItemStyled>
                          {isSelected ? (
                            a.ArtistName
                          ) : (
                            <Typography
                              tag="a"
                              onClick={() =>
                                handleClickFilterEvents(a.ArtistID)
                              }
                            >
                              {a.ArtistName}
                            </Typography>
                          )}
                        </FilterItemStyled>
                        {idx < artistList.length - 1 ? ' | ' : null}
                      </React.Fragment>
                    );
                  })
                  : null}
              </div>
            </FilterRowStyled>

            {outlet.OutletTypeID === 3 && guestListInvitationError ? (
              guestListInvitationError
            ) : (
              <OutletEventList
                outlet={outlet}
                outletEventList={outletEventListVisible || []}
                onClickEventDetails={(oe) => handleClickEventDetails(oe)}
              />
            )}

            {outlet.OutletTypeID === 3 &&
              outletEventListVisible &&
              outletEventListVisible.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    fullWidth
                    style={{ margin: '1rem 0' }}
                    disabled={
                      guestListRequestCart &&
                        Array.isArray(guestListRequestCart.priceLevels) &&
                        guestListRequestCart.priceLevels.length
                        ? false
                        : true
                    }
                    onClick={() => onClickSubmitGuestListPricelevels()}
                    name={'submit-guestlist-pricelevels-btn'}
                    className="submit-guestlist-pricelevels-btn"
                  >
                    Request Tickets
                  </Button>
                </div>
              )}
          </>
        )}
        <PromoImageList
          imageList={
            (outlet.OutletTheme && outlet.OutletTheme.PromoImages) || []
          }
          style={{ marginBottom: '2rem' }}
        />
      </Contain>
      {carouselImageList &&
        Array.isArray(carouselImageList) &&
        carouselImageList.length ? (
        <Slider
          slides={carouselImageList}
          autoPlay={carouselImageList.length > 1 ? 5 : false}
        />
      ) : null}
      <Modal
        title={modal.title}
        open={outletEvent && modal.isVisible}
        onClose={() => setModal({ ...modal, isVisible: false })}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: modal.content,
          }}
        />
        <div style={{ textAlign: 'center', margin: '2rem 0' }}>
          <Typography
            tag="a"
            onClick={() => setModal({ ...modal, isVisible: false })}
          >
            Cancel
          </Typography>
        </div>
        <Button
          fullWidth
          id="external-tickets-link-btn"
          onClick={() => {
            setModal({ ...modal, isVisible: false });
            window.open(outletEvent.ExternalSalesURL, '_blank');
          }}
        >
          GET TICKETS{' '}
          <span style={{ paddingLeft: '0.5rem' }}>
            <IconExternalLink width="16" height="16" />
          </span>
        </Button>
      </Modal>
    </div>
  );
}
