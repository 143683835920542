import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

//import HideOnMobile from "../../utils/HideOnMobile";
import Typography from '../../utils/Typography';

const RootStyled = styled.div`
  margin: 3rem 0;
`;

const StageColStyled = styled.div`
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: baseline;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    justify-content: center;
  }
`;

const StageContainerStyled = styled.div`
  display: flex;
  margin: 3rem 0 0 0;
  justify-content: space-around;
  align-content: center;
  align-items: baseline;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    justify-content: center;
  }
`;
const StageBlockStyled = styled.div`
  cursor: ${(props) =>
    props.visited && !props.disabled ? 'pointer' : 'not-allowed'};
  display: grid;
  align-content: center;
  opacity: ${(props) => (props.highlight ? 1 : 0.3)};
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    width: 76px;
  }
`;
const StageSortStyled = styled.div`
  height: 3rem;
  width: 3rem;
  color: ${(props) => (props.highlight || props.visited ? '#fff' : '#3d3c3c')};
  font-weight: 700;

  border-radius: 50%;
  margin: 0 auto;
  line-height: 3rem;
  vertical-align: middle;
  text-align: center;
  background-color: ${(props) =>
    props.highlight || props.visited
      ? props.theme.PRIMARY_LINK_COLOR
      : '#acacac'};
`;

const StageLableStyled = styled.div`
  margin: 1rem 0 0 0;
`;
const DotsStyled = styled.div`
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 1fr;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Dot = styled.div`
  color: #e6e6e6;
  background-color: #e6e6e6;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  margin: 0 auto;
  display: ${(props) => (props.hideOnMobile ? 'none' : 'block')};
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    display: block;
  }
`;
const CheckoutBreadcrumbs = ({ onClickCrumb, stageList, stage }) => {
  let currentStage = stageList.find((x) => x.stage === stage);
  if (stage === 'login') {
    currentStage = stageList.find((x) => x.stage === 'review');
  }

  if (!currentStage || !stageList) return null;

  return (
    <RootStyled>
      <Typography tag="h1" style={{ textAlign: 'center' }} data-testid="checkout-breadcrumbs-title">
        {currentStage.header}
      </Typography>
      <StageContainerStyled>
        {stageList &&
          Array.isArray(stageList) &&
          stageList.map((stage, idx) => {
            let highlight = false;
            let visited = false;
            if (currentStage.sort === stage.sort) {
              highlight = true;
            }
            if (currentStage.sort > stage.sort) {
              visited = true;
            }
            return (
              <StageColStyled key={idx}>
                {idx > 0 && idx < stageList.length && (
                  <DotsStyled key={idx + '-dots'}>
                    <Dot />
                    <Dot hideOnMobile={true} />
                    <Dot hideOnMobile={true} />
                  </DotsStyled>
                )}
                <StageBlockStyled
                  key={idx + '-stage'}
                  highlight={highlight}
                  visited={visited}
                  disabled={stage.disabled}
                  onClick={() =>
                    visited && !stage.disabled ? onClickCrumb(stage) : false
                  }
                >
                  <StageSortStyled highlight={highlight} visited={visited}>
                    {stage.sort}
                  </StageSortStyled>
                  <StageLableStyled highlight={highlight} visited={visited}>
                    <Typography tag="h3">{stage.label}</Typography>
                  </StageLableStyled>
                </StageBlockStyled>
              </StageColStyled>
            );
          })}
      </StageContainerStyled>
    </RootStyled>
  );
};

CheckoutBreadcrumbs.propTypes = {
  stageList: PropTypes.array,
  stage: PropTypes.string,
  currentStage: PropTypes.object,
};

export default CheckoutBreadcrumbs;
