import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../utils/Button';
import PaymentMethodForm from '../../utils/PaymentMethodForm';
import PaymentPlanEnrollment from './PaymentPlanEnrollment';
import PaymentPlanForm from './PaymentPlanForm';
import { getUserPaymentMethodList } from '../user/actions';

/**
 * Component for editing billing information during checkout.
 *
 * This component allows users to select or update payment methods and enroll in a payment plan if available.
 * It supports displaying a payment plan form or a payment method form based on the enrollment status.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} [props.loading=false] - Indicates if the save operation is in progress.
 * @param {Function} props.onClickSave - Callback for saving the updated billing information.
 * @param {boolean} props.paymentPlanIsAvailable - Indicates if the payment plan option is available.
 * @param {Object} props.outletEvent - The current outlet event data.
 * @param {number} [props.seriesTotal=0] - The total cost of the series.
 * @param {boolean} [props.userIsEnrolledInPaymentPlan=false] - Indicates if the user is currently enrolled in a payment plan.
 * @param {Array<Object>} [props.userPaymentMethodList=[]] - List of payment methods available to the user.
 * @param {Array<Object>} [props.defaultPaymentInfo=[]] - Default payment information for the user.
 * @return {React.FC}
 *
 * @state {Array<Object>} paymentList - The current list of payment methods for the user.
 * @state {boolean} paymentPlanEnrollmentIsSelected - Indicates if the payment plan enrollment is selected.
 *
 * @remarks
 * - Supports enrollment in a payment plan if eligibility criteria are met.
 * - Manages user-selected payment methods, allowing updates via forms or Authnet iFrame.
 * - Uses `useSelector` to access `outlet` and `authnetIframeIsVisible` from Redux state.
 *
 * @dependencies
 * - `PaymentPlanEnrollment`: Component for enrolling in a payment plan.
 * - `PaymentPlanForm`: Component for managing payment plan details.
 * - `PaymentMethodForm`: Component for managing payment method selection and creation.
 * - `Button`: Component for saving changes.
 *
 */
function EditRequestBilling(props = {}) {
  const {
    loading = false,
    onClickSave,
    paymentPlanIsAvailable,
    outletEvent,
    seriesTotal = 0,
    userIsEnrolledInPaymentPlan = false,
    userPaymentMethodList = [],
    defaultPaymentInfo = [],
  } = props;
  const outlet = useSelector((state) => state.outlet);
  const authnetIframeIsVisible = useSelector(
    (state) => state.app.authnetIframeIsVisible,
  );
  const [paymentList, setPaymentList] = useState(defaultPaymentInfo);

  const [paymentPlanEnrollmentIsSelected, setPaymentPlanEnrollmentIsSelected] =
    useState(userIsEnrolledInPaymentPlan);

  const handleOnCreateNewPaymentMethodViaIFrame = (params) => {
    if (params) {
      const { Sort } = params;
      getUserPaymentMethodList().then((userPaymentMethods) => {
        if (
          userPaymentMethods &&
          Array.isArray(userPaymentMethods) &&
          userPaymentMethods.length
        ) {
          if (userPaymentMethods[0]) {
            const newPaymentMethod = { ...userPaymentMethods[0] };
            newPaymentMethod['Sort'] = Sort || 1;
            const newPaymentList = [...paymentList];
            const index = newPaymentList.findIndex(
              (p) => Number(p.Sort) === Number(Sort),
            );
            if (index > -1) {
              newPaymentList[index] = newPaymentMethod;
            } else {
              newPaymentList.push(newPaymentMethod);
            }
            setPaymentList(newPaymentList);
          }
        }
      });
    }
  };
  const handleOnSubmit = () => {
    onClickSave &&
      onClickSave({ paymentList, paymentPlanEnrollmentIsSelected });

    return false;
  };
  const handleOnChangePaymentPlanEnrollment = (e) => {
    const isChecked = e.target.checked;
    setPaymentPlanEnrollmentIsSelected(Boolean(isChecked));
  };

  const handleOnSelectPaymentMethod = (pm) => {
    if (pm && pm.PaymentMethodUUID) {
      if (!pm.Sort) {
        pm.Sort = 1;
      }

      let paymentListAdjusted = Array.isArray(paymentList)
        ? [...paymentList]
        : [];

      const paymentIndex = paymentListAdjusted.findIndex(
        (p) => Number(p.Sort) === Number(pm.Sort),
      );
      if (paymentIndex > -1) {
        paymentListAdjusted[paymentIndex] = {
          ...paymentListAdjusted[paymentIndex],
          ...pm,
        };
      } else {
        paymentListAdjusted.push(pm);
      }
      setPaymentList(paymentListAdjusted);
    } else {
      alert('error missing payment method details');
    }
  };

  let defaultPayment;

  if (Array.isArray(paymentList) && paymentList.length) {
    defaultPayment =
      paymentList.find((p) => Number(p.Sort) === 1) || paymentList[0];
  }

  let showPaymentPlanEnrollment = false;
  let paymentPlanRules;
  if (
    paymentPlanIsAvailable &&
    Array.isArray(outlet.OutletEventGroups) &&
    outletEvent.OutletEventGroup &&
    outletEvent.OutletEventGroup.OfferPaymentPlan
  ) {
    const oegFound = outlet.OutletEventGroups.find(
      (oeg) =>
        Number(oeg.OutletEventGroupID) ===
        Number(outletEvent.OutletEventGroup.OutletEventGroupID),
    );

    if (oegFound && oegFound.PaymentPlanRules) {
      paymentPlanRules = oegFound.PaymentPlanRules;
      showPaymentPlanEnrollment =
        Number(seriesTotal) >=
        Number(oegFound.PaymentPlanRules.eligibilityMinimum);
    }
  }

  return (
    <>
      {showPaymentPlanEnrollment ? (
        <PaymentPlanEnrollment
          userIsEnrolledInPaymentPlan={paymentPlanEnrollmentIsSelected}
          onChangeCheckboxEnrollment={handleOnChangePaymentPlanEnrollment}
        />
      ) : null}

      {paymentPlanEnrollmentIsSelected ? (
        <>
          <PaymentPlanForm
            paymentPlanRules={paymentPlanRules}
            total={seriesTotal}
            paymentList={paymentList}
            userPaymentMethodList={userPaymentMethodList}
            outletEventGroup={outletEvent && outletEvent.OutletEventGroup}
            onChangePaymentMethod={(pm) => handleOnSelectPaymentMethod(pm)}
            onCreateViaIFrame={(x = {}) =>
              handleOnCreateNewPaymentMethodViaIFrame({
                ...x,
              })
            }
          />
          {!authnetIframeIsVisible && (
            <Button
              fullWidth
              loading={loading}
              onClick={handleOnSubmit}
              disabled={!paymentList || paymentList.length < 3 || loading}
            >
              Save
            </Button>
          )}
        </>
      ) : (
        <>
          <PaymentMethodForm
            defaultMethod={defaultPayment}
            paymentMethods={userPaymentMethodList}
            onSelect={(pm) => handleOnSelectPaymentMethod(pm)}
            onCreateViaIFrame={(x) =>
              handleOnCreateNewPaymentMethodViaIFrame({
                iframeResponse: x,
                Sort: 1,
              })
            }
          />
          {!authnetIframeIsVisible && (
            <Button
              fullWidth
              loading={loading}
              disabled={!defaultPayment || loading}
              onClick={handleOnSubmit}
            >
              Save
            </Button>
          )}
        </>
      )}
    </>
  );
}

export default EditRequestBilling;
