import store from '../../store';
import { api } from '../api/actions';

export function getGuestListInvitation(payload) {
  const action = 'GET_GUEST_LIST_INVITATION';
  const response = api({
    action,
    payload,
  });

  response.then((data) => {
    const eventList =
        data && Array.isArray(data.OutletEvents) ? data.OutletEvents : [];
    store.dispatch({
      type: 'EVENT_LIST',
      data: eventList,
    });

    const guestListInvitationData = (data && data.inviteInfo) || null;
    store.dispatch({
      type: 'SET_GUEST_LIST_INVITATION',
      guestListInvitation: guestListInvitationData,
    });

    const guestListInvitationDeliveryMethodList = (data && data.DeliveryMethods) || [];
    store.dispatch({
      type: 'SET_GUEST_LIST_INVITATION_DELIVERY_METHOD_LIST',
      guestListInvitationDeliveryMethodList: guestListInvitationDeliveryMethodList,
    });

    store.dispatch({
      type: 'SET_GUEST_LIST_INVITATION_ERROR',
      guestListInvitationError: null,
    });

    //setGuestListInvitation(data);
  }).catch((error) => {
    store.dispatch({
      type: 'SET_GUEST_LIST_INVITATION_ERROR',
      guestListInvitationError: error,
    });

    store.dispatch({
      type: 'EVENT_LIST',
      data: [],
    });

    store.dispatch({
      type: 'SET_GUEST_LIST_INVITATION',
      guestListInvitation: null,
    });
  });
  return response;
}

export function getEventList(payload) {
  const action = 'GET_EVENT_LIST';
  let defaultPayload = {
    IncludePast: false,
    //Group: "Artist",
    InfoTypes: ['EventItems', 'OutletEvents'],
  };

  const response = api({
    action,
    payload: payload || defaultPayload,
  });

  response.then((data) => {
    store.dispatch({
      type: 'EVENT_LIST',
      data,
    });
  });
  return response;
}
