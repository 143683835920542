import React from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { logoutUser } from '../auth/actions';
import { toggleAppDrawerVisibility } from './actions';
import { goToUrl } from '../../utils/utilities';

import { getEventList } from '../event/actions';

const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(60, 60, 60, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  overflow: scroll;
  z-index: 1000;
`;

const Drawer = styled.div`
  width: 50vw;
  height: 100vh;
  background-color: ${(props) => props.theme.NAV_DRAWER_BACKGROUND};
  position: fixed;
  top: 0;
  right: 0;
  outline: none;
  transition: transform 0.5s cubic-bezier(0, 0.52, 0, 1);
  overflow: scroll;
  z-index: 1001;
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
    width: 33vw;
  }
`;

const Menu = styled.nav`
  width: 100%;
  position: relative;
  outline: none;
`;

const MenuLink = styled.a`
  font-size: 1.6rem;
  padding: 16px 24px;
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  border-bottom: solid 1px ${(props) => props.theme.PRIMARY_GRAY}80;
  &:hover {
    background-color: ${(props) => props.theme.PRIMARY_GRAY}80;
  }
  &.active {
    cursor: not-allowed;
    color: ${(props) => props.theme.PRIMARY_TEXT_COLOR};
    &:hover {
      background-color: ${(props) => props.theme.NAV_DRAWER_BACKGROUND};
    }
  }
`;

export const AppDrawer = () => {
  const appDrawerIsVisible = useSelector(
      (state) => state.app.appDrawerIsVisible,
  );
  const outlet = useSelector((state) => state.outlet);
  const userIsAuthenticated = useSelector(
      (state) => state.auth.userIsAuthenticated,
  );
  const { pathname } = useLocation();

  const onClickEventsTab = () => {
    if (outlet && outlet.OutletID) {
      if (Number(outlet.OutletTypeID) !== 3) {
        getEventList({ OutletID: outlet && outlet.OutletID });
      }
    }

    goToUrl('/');
  };

  if (appDrawerIsVisible) {
    return (
        <Backdrop
            tabIndex={0}
            onClick={() => toggleAppDrawerVisibility(!appDrawerIsVisible)}
        >
          <Drawer>
            <Menu>
              <MenuLink
                  name="menu-events"
                  id="menu-events"
                  //as={NavLink}
                  //exact
                  //to={"/"}
                  onClick={() => onClickEventsTab()}
              >
                All Events
              </MenuLink>

              {userIsAuthenticated ? (
                  <>
                        <MenuLink
                            name="menu-history"
                            id="menu-history"
                            to="/user/history/orders"
                            isActive={() =>
                                [
                                  '/user/history/orders',
                                  '/user/history/requests',
                                ].includes(pathname)
                            }
                            as={NavLink}
                        >
                          Order History
                        </MenuLink>

                    <MenuLink
                        name="menu-myaccount"
                        id="menu-myaccount"
                        to="/user/account"
                        as={NavLink}
                    >
                      Account
                    </MenuLink>
                    <MenuLink
                        name="menu-signout"
                        id="menu-signout"
                        onClick={() => logoutUser()}
                    >
                      Sign Out
                    </MenuLink>
                  </>
              ) : (
                  <MenuLink name="menu-login" to="/login" as={NavLink}>
                    Sign In
                  </MenuLink>
              )}
              {outlet && outlet.HelpLink && (
                  <MenuLink
                      name="menu-help"
                      id="menu-help"
                      onClick={() => window.open(outlet.HelpLink, '_blank')}
                  >
                    Help
                  </MenuLink>
              )}
            </Menu>
          </Drawer>
        </Backdrop>
    );
  }
  return null;
};

export default AppDrawer;
