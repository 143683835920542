import store from '../../store';
import { api } from '../api/actions';
import { getGuestListInvitation } from '../event/actions';
import { goToUrl, isUserAuthenticated } from '../../utils/utilities';

export const setGuestListToken = (guestListToken) => {
  store.dispatch({
    type: 'SET_GUEST_LIST_TOKEN',
    guestListToken,
  });
};

export const setGuestListInvitation = (guestListInvitation) => {
  store.dispatch({
    type: 'SET_GUEST_LIST_INVITATION',
    guestListInvitation,
  });
};

export function forgotUserPassword(payload) {
  const response = api({
    action: 'FORGOT_USER_PASSWORD',
    payload,
  });
  response.then((res) => {
    store.dispatch({
      type: 'FORGOT_USER_PASSWORD',
      error: null,
    });
  }).catch((error) => {
  });
  return response;
}

export function resetUserPassword(payload) {
  const response = api({
    action: 'RESET_USER_PASSWORD',
    payload,
  });
  response.then((res) => {
    store.dispatch({
      type: 'RESET_USER_PASSWORD',
      error: null,
    });
  }).catch((error) => {
  });
  return response;
}

export function changeUserPassword(payload) {
  const response = api({
    action: 'CHANGE_USER_PASSWORD',
    payload,
  });
  response.then((res) => {
    store.dispatch({
      type: 'CHANGE_USER_PASSWORD',
      error: null,
    });
  }).catch((error) => {
  });
  return response;
}

/**
 * Logs in a user by sending their credentials to the API and handling the session response.
 *
 * @function loginUser
 * @param {Object} payload - The user's login credentials.
 * @param {string} payload.id - The user's unique identifier (e.g., email address).
 * @param {string} payload.password - The user's password.
 *
 * @returns {Promise<Object>} A promise that resolves to the session response from the API.
 *
 * @description
 * - Sends a login request to the API with the provided user credentials.
 * - Handles the user session upon a successful login.
 * - Checks for a valid `UserID` in the session and validates cookies for guest invitations.
 * - Dispatches additional actions if the login fails, such as resetting the user session and increasing failed login attempts.
 *
 * @example
 * loginUser({ id: "lloyd+2@grok.works", password: "password1!" })
 *   .then((session) => {
 *     console.log('Login successful:', session);
 *   })
 *   .catch((error) => {
 *     console.error('Login failed:', error);
 *   });
 *
 * @effects
 * - Calls `handleUserSession` to manage session data.
 * - Checks cookies for guest invitations (cookies starting with `glInvite`).
 * - Dispatches actions to the store on login failure:
 *   - `SET_USER_SESSION` to nullify the user session.
 *   - `INCREASE_FAILED_LOGIN_ATTEMPTS` to track failed login attempts.
 *
 * @dependencies
 * - `api`: Function to send API requests.
 * - `store`: Redux store for managing application state.
 * - `handleUserSession`: Function to handle session setup.
 * - `getGuestListInvitation`: Function to fetch guest list invitation details.
 */
export function loginUser(payload) {
  const response = api({ action: 'LOGIN_USER', payload });
  response.then((session) => {
    const UserID = session && session.UserID;

    handleUserSession(session);

    if (UserID && Number.isInteger(UserID)) {
      const state = store.getState();
      const authData = state.auth;
      const cookieArray = document.cookie && document.cookie.split('; ');
      let cookieFound;
      if (Array.isArray(cookieArray)) {
        let cookieRecord = cookieArray.find((row) => row.startsWith('glInvite'));
        if (cookieRecord) {
          let cookieParts = cookieRecord.split('=');
          if (Array.isArray(cookieParts) && cookieParts.length === 2) {
            if (cookieParts[1].startsWith(window.location.hostname)) {
              cookieFound = true;
            }
          }
        }
      }

      if (authData && (authData.guestListToken || cookieFound)) {
        getGuestListInvitation({ InvitationToken: authData.guestListToken });
      }
    }
  }).catch((error) => {
    store.dispatch({ type: 'SET_USER_SESSION', data: null });
    store.dispatch({ type: 'INCREASE_FAILED_LOGIN_ATTEMPTS' });
  });
  return response;
}


export function resetUserState() {
  store.dispatch({
    type: 'SET_USER_ORDER',
    data: null,
  });
  store.dispatch({
    type: 'SET_USER_ORDER_LIST',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_ORDER_SUMMARY_LIST',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_PAYMENT_METHOD_LIST',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_ADDRESS_LIST',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_FAN_REQUESTS',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_FAN_REQUEST_SERIES_SUMMARY',
    data: [],
  });
  store.dispatch({
    type: 'SET_USER_FAN_REQUEST_SERIES_PRIORITY',
  });
  store.dispatch({
    type: 'SET_USER_PAYMENT_METHOD_LIST',
    data: [],
  });
  store.dispatch({
    type: 'DELETE_INSTANT_REQUEST',
  });
  store.dispatch({
    type: 'RESET_GUEST_REQUEST_CART',
  });
  store.dispatch({
    type: 'RESET_FAN_REQUEST_CART',
  });
  store.dispatch({
    type: 'SET_GUEST_LIST_INVITATION',
    guestListInvitation: null,
  });
  store.dispatch({
    type: 'SET_GUEST_LIST_TOKEN',
    guestListToken: null,
  });
}

export function logoutUser() {
  resetUserState();
  store.dispatch({
    type: 'USER_IS_AUTHENTICATED',
    userIsAuthenticated: false,
  });
  store.dispatch({
    type: 'SET_USER_SESSION',
    data: null,
  });
  checkSession({ destroy: 1 });
  goToUrl('/');
}

export function handleUserSession(session) {
  let userIsAuthenticated = isUserAuthenticated(session);
  let sessionTimeoutWarningIsVisible =
    userIsAuthenticated && session && session.secondsToExpire < 180;

  if (!session || !session.UserID || session.UserID === 'invalid') {
    resetUserState();
  }

  store.dispatch({
    type: 'SET_USER_SESSION',
    data: session,
  });

  store.dispatch({
    type: 'USER_IS_AUTHENTICATED',
    userIsAuthenticated,
  });
  store.dispatch({
    type: 'SESSION_TIMEOUT_WARNING_IS_VISIBLE',
    isVisible: sessionTimeoutWarningIsVisible,
  });
}

/**
 * Initializes the user session by making an API request.
 *
 * This function sends an API request to initialize a user session and handles the session data or errors
 * returned from the API. If the session initialization is successful, it processes the session data
 * using `handleUserSession`. In case of an error, it calls `handleUserSession` with `null`.
 *
 * @return {Promise<Object>} - The API response containing the session data or an error.
 */
export function initSession() {
  const response = api({
    action: 'INIT_SESSION',
  });
  response.then((session) => {
    handleUserSession(session);
  }).catch((error) => {
    handleUserSession(null);
  });
  return response;
}

export async function checkSession(payload = {}) {
  // pass 1  into url param 'extend' to have it extend the session by 15 minutes (will only add if the session has less than 10 minutes left)

  const destroy = payload && payload.destroy ? 1 : 0;
  const extendSession = payload && payload.extendSession ? 1 : 0;
  let endpoint = `user/checksession?extend=${extendSession}`;

  if (destroy) {
    endpoint = `user/checksession?destroy=1`;
  }
  const response = api({
    action: 'CHECK_SESSION',
    endpoint,
  });

  response.then((session) => {
    handleUserSession(session);
  }).catch((error) => {
    handleUserSession(null);
    resetUserState();
  });

  return response;
}

export function renewUserSession() {
  checkSession({ extendSession: 1 });
}
