import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Route, Switch, useLocation } from 'react-router-dom';
// import LogRocket from 'logrocket';
import { useSelector } from 'react-redux';
import SubHeaderThemeImage from './components/outlet/SubHeaderThemeImage';
import AccountView from './views/AccountView';
import Contain from './components/app/Contain';
import AppDrawer from './components/app/AppDrawer';
import AppFooter from './components/app/AppFooter';
import AppHeader from './components/app/AppHeader';
import Button from './utils/Button';
import CheckoutView from './views/CheckoutView';
import ContentBlock from './components/content/ContentBlock';
import Error from './utils/Error';
import EventView from './views/EventView';
import EventsView from './views/EventsView';
import GuestListReceiptView from './views/GuestListReceiptView';
import Loader from './utils/Loader';
import LoginView from './views/LoginView';
import Modal from './utils/Modal';
import OutletMaintenancePage from './components/outlet/OutletMaintenancePage';
import OutletPrivateLogin from './components/outlet/OutletPrivateLogin';
import OutletVersionsMismatch from './components/outlet/OutletVersionsMismatch';
import PageNotFoundView from './views/PageNotFoundView';
import PrivateRoute from './components/auth/PrivateRoute';
import ResetPasswordView from './views/ResetPasswordView';
import Toast from './components/toast/Toast.js';
import UserHistoryView from './views/UserHistoryView';
import {
  checkSession,
  initSession,
  loginUser,
  logoutUser,
  renewUserSession,
  setGuestListToken,
} from './components/auth/actions';
import { getEventList, getGuestListInvitation } from './components/event/actions';
import { getOutlet, loginPrivateOutlet } from './components/outlet/actions';
import { handlePromise, isOutletLoginHandledExternally } from './utils/utilities';
import { outletThemeDefault } from './utils/themes';
import FanRequestCheckout from './views/checkout/fan-request.component.js';

const DeploymentInfoContainer = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding-right: 4px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  background: rgba(51, 170, 51, .1);
`;

const AppWrapperStyled = styled.div`
  height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: ${(props) =>
    props.rowCount === 6
      ? 'auto auto auto 1fr auto auto'
      : 'auto auto auto auto 1fr auto auto'};
  background-color: ${(props) => props.theme.APP_BACKGROUND};
  @media (min-width: ${(props) => props.theme.BREAKPOINT_MOBILE_WIDTH}px) {
  }
`;

const AppAlertRibbonStyled = styled.div``;
const AppHeaderStyled = styled.div``;
const AppBodyStyled = styled.div`
  // display: grid;
  // grid-template-rows: 1fr;
  // height: 100%;
`;
const AppFooterStyled = styled.div``;

function App() {
  const deployedBranchNameExists = process.env.REACT_APP_CI_COMMIT_REF_NAME !== undefined;
  const commitShortShaExists = process.env.REACT_APP_CI_COMMIT_SHORT_SHA !== undefined;
  const isProduction = process.env.REACT_APP_ENV === 'prod';
  const [appIsInitializing, setAppIsInitializing] = useState(true);
  const [appInitializingError, setAppInitializingError] = useState(false);
  const [userSessionIsInitializing, setUserSessionIsInitializing] =
    useState(true);
  const [userSessionInitializingError, setUserSessionInitializingError] =
    useState(false);
  const [outletAuthIsComplete, setOutletAuthIsComplete] = useState(false);
  const [outletAuthError, setOutletAuthError] = useState(false);
  const [userDeclinedNewOutletVersion, setUserDeclinedNewOutletVersion] =
    useState(false);
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const outlet = useSelector((state) => state.outlet);
  const toastList = useSelector((state) => state.toastList);
  const userSession = useSelector((state) => state.session);

  const initializeUserSession = async () => {
    try {
      const token =
        location &&
          location.search &&
          queryString.parse(location.search) &&
          queryString.parse(location.search).LT
          ? queryString.parse(location.search).LT
          : undefined;

      const [data, error] = token
        ? await handlePromise(loginUser({ Token: token }))
        : await handlePromise(initSession());
      setUserSessionIsInitializing(false);
      if (error) {
        setUserSessionInitializingError(error);
        throw error;
      }

      return data;
    } catch (error) {
      setUserSessionIsInitializing(false);
      setUserSessionInitializingError(error);
      throw error;
    }
  };

  const initializeOutlet = async () => {
    try {
      const [outletInfo, outletError] = await handlePromise(getOutlet());

      if (outletError) {
        throw outletError;
      }

      const { Outlet } = outletInfo;
      const OutletID = Outlet && Outlet.OutletID;
      if (OutletID) {
      } else {
        throw new Error('Missing OutletID');
      }
      return Outlet;
    } catch (error) {
      throw error;
    }
  };

  const setAppMetaData = (Outlet) => {
    if (Outlet) {
      const { OutletName, OutletTheme } = Outlet;
      document.title = OutletName;
      if (OutletTheme && OutletTheme.FaviconImageURL) {
        const faviconElement = document.getElementById('faviconLink');
        const faviconElementApple = document.getElementById('faviconLinkApple');
        faviconElement.href = OutletTheme.FaviconImageURL;
        faviconElementApple.href = OutletTheme.FaviconImageURL;
      }
    }
  };

  const initializeApp = async () => {
    try {
      const outlet = await initializeOutlet();

      // RLMTS-2493 - handle outside login
      const externalLoginRequired = isOutletLoginHandledExternally(outlet, userSession);

      if (externalLoginRequired) {
        window.location.replace(outlet.LoginURL);
      } else {
        setAppMetaData(outlet);
        const guestListInviteToken =
          location &&
            location.search &&
            queryString.parse(location.search) &&
            queryString.parse(location.search).token
            ? queryString.parse(location.search).token
            : undefined;

        setGuestListToken(guestListInviteToken);

        const cookieArray = document.cookie && document.cookie.split('; ');
        let cookieFound;
        if (Array.isArray(cookieArray)) {
          let cookieRecord = cookieArray.find((row) => row.startsWith('glInvite'));
          if (cookieRecord) {
            let cookieParts = cookieRecord.split('=');
            if (Array.isArray(cookieParts) && cookieParts.length === 2) {
              if (cookieParts[1].startsWith(window.location.hostname)) {
                cookieFound = true;
              }
            }
          }
        }

        if (guestListInviteToken || cookieFound) {
          const [guestListInviationData, guestListInviationError] =
            await handlePromise(
              getGuestListInvitation({
                InvitationToken: guestListInviteToken,
              }),
            );
        } else {
          // if not a guest list outlet
          if (outlet && Number(outlet.OutletTypeID) !== 3) {
            const [eventList, eventListError] = await handlePromise(
              getEventList({ OutletID: outlet && outlet.OutletID }),
            );
          }
        }
      }

      setAppIsInitializing(false);
    } catch (error) {
      setAppInitializingError(error);
      setAppIsInitializing(false);
    }
  };

  const handleSubmitPrivateOutletLogin = (formData) => {
    if (formData && formData.password) {
      loginPrivateOutlet(formData).then((res) => {
        if (res && res.outletPasswordRequired) {
          setOutletAuthError('Incorrect password');
        } else {
          setOutletAuthIsComplete(true);
          setOutletAuthError(false);
        }
      }).catch((error) => {
        setOutletAuthError('Processing error , please try again');
      });
    } else {
      setOutletAuthError('Incorrect password');
    }
  };

  useEffect(() => {
    let interval;

    if (outletAuthIsComplete) {
      initializeApp().then((res) => {
        interval = setInterval(() => {
          checkSession();
        }, 60000);
      });
    } else {
      const getUserSession = async () => {
        return await initializeUserSession();
      };
      getUserSession().then((session) => {
        if (session) {
          const { UserID, outletPasswordRequired } = session;

          // GT-4224 disabling LogRocket in prod until we get approval from LN third party risk management team
          if (!isProduction) {
            // LogRocket.identify(session.UserID, {
            //   name: `${session.FirstName} ${session.LastName}`,
            //   email: session.EmailAddress,
            // });
          }

          if (Boolean(outletPasswordRequired) === false) {
            setOutletAuthIsComplete(true)
          }
        }
      }).catch(console.error);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outletAuthIsComplete]);

  if (userSessionIsInitializing || userSessionInitializingError) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', padding: '3rem' }}
      >
        {userSessionIsInitializing ? (
          <Loader>Loading user</Loader>
        ) : userSessionInitializingError ? (
          <Error>{userSessionInitializingError}</Error>
        ) : null}
      </div>
    );
  }

  if (!outletAuthIsComplete) {
    return (
      <OutletPrivateLogin
        error={outletAuthError}
        onSubmit={handleSubmitPrivateOutletLogin}
      />
    );
  }

  let outletTheme = { ...outletThemeDefault };
  if (outlet && outlet.OutletTheme) {
    outletTheme = { ...outletTheme, ...outlet.OutletTheme };
  }

  if (
    userSession &&
    userSession.maintenance &&
    userSession.maintenance.active
  ) {
    return (
      <ThemeProvider theme={outletTheme}>
        <OutletMaintenancePage />
      </ThemeProvider>
    );
  }

  if (
    !userDeclinedNewOutletVersion &&
    userSession &&
    userSession.versions &&
    userSession.versions['samurai-client'] &&
    userSession.versions['samurai-client'] != process.env.REACT_APP_VERSION
  ) {
    return (
      <ThemeProvider theme={outletTheme}>
        <AppHeader showMenu={false} />
        <OutletVersionsMismatch
          onSubmit={() => window.location.reload(true)}
          onCancel={() => setUserDeclinedNewOutletVersion(true)}
        />
      </ThemeProvider>
    );
  }

  if (appIsInitializing || appInitializingError) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', padding: '3rem' }}
      >
        {appIsInitializing ? (
          <Loader>Loading application</Loader>
        ) : appInitializingError ? (
          <Error>{appInitializingError}</Error>
        ) : null}
      </div>
    );
  }

  const outletLoginType =
    outlet && outlet.OutletLoginType && outlet.OutletLoginType.ConstantValue;
  const userAuthIsRequired = outletLoginType === 'OUTLET_LOGIN_ALWAYS';
  const sessionTimeoutWarningIsVisible =
    auth && auth.sessionTimeoutWarningIsVisible;
  const secondsUntilSessionExpires = userSession && userSession.secondsToExpire;
  const redirectToAnOutsideLoginIsRequired = isOutletLoginHandledExternally(
    outlet,
    userSession,
  );
  if (redirectToAnOutsideLoginIsRequired) {
    window.location.replace(outlet.LoginURL);
  }

  const invalidSession = false; //Boolean(apiState.invalidSession);

  if (invalidSession) {
    const outletLoginRedirect = outlet && outlet.outletLoginRedirect;

    if (outletLoginRedirect) {
      return (
        <Route
          component={() => {
            var link = document.createElement('a');
            link.href = outletLoginRedirect;
            document.body.appendChild(link);
            link.click();
            return null;
          }}
        />
      );
    } else {
      return (
        <div>
          Hi there, you have an invalid sesssion, we need to deal with you
          somehow, waiting on buisness rules to be defined.
        </div>
      );
    }
  }

  //RLMTS-3211 only show the sub header image on home page / request reciept page / order reciept page
  let pathArray = window.location && window.location.pathname.split('/');

  let showSubHeaderImage = false;
  if (pathArray && Array.isArray(pathArray)) {
    let validPageToShowSubHeader = false;
    //is current page the homepage
    if (!pathArray[1] || pathArray[1] === 'events') {
      validPageToShowSubHeader = true;
    }
    //is current page the request receipt
    if (pathArray[3] === 'requests' && pathArray[5] === 'receipt') {
      validPageToShowSubHeader = true;
    }
    //is current page the instant order receipt
    if (pathArray[3] === 'orders' && pathArray[5] === 'receipt') {
      validPageToShowSubHeader = true;
    }

    if (
      validPageToShowSubHeader &&
      outletTheme &&
      outletTheme.SubHeaderImageURL
    ) {
      showSubHeaderImage = true;
    }
  }
  const numOfAppRows = showSubHeaderImage ? 7 : 6;

  return (
    <ThemeProvider theme={outletTheme}>
      <AppWrapperStyled rowCount={numOfAppRows}>
        <AppAlertRibbonStyled />
        <AppHeaderStyled>
          <AppHeader
            showMenu={!userAuthIsRequired || auth.userIsAuthenticated}
          />
        </AppHeaderStyled>
        {showSubHeaderImage && (
          <div
            style={{
              backgroundColor:
                (outletTheme && outletTheme.SubHeaderImageBackgroundColor) ||
                'inherit',
            }}
          >
            <Contain hasPadding={false} hasPaddingMobile={false}>
              <SubHeaderThemeImage />
            </Contain>
          </div>
        )}
        <AppAlertRibbonStyled>
          <ContentBlock zone="CONTENT_ZONE_BELOW_HEADER" />
        </AppAlertRibbonStyled>
        <AppBodyStyled>
          <Switch>
            <PrivateRoute
              exact
              path={['/', '/events']}
              authIsRequired={userAuthIsRequired}
            >
              <EventsView />
            </PrivateRoute>
            <PrivateRoute
              path="/event/:OutletEventID?/:OutletEventItemID?/:useCartInfo?"
              authIsRequired={userAuthIsRequired}
            >
              <EventView />
            </PrivateRoute>
            <PrivateRoute
              path="/checkout/:type/:stage/:uuid?"
              authIsRequired={userAuthIsRequired}
            >
              {location?.pathname?.includes('checkout/f') ? <FanRequestCheckout /> : <CheckoutView />}
            </PrivateRoute>
            <PrivateRoute path="/user/account">
              <AccountView />
            </PrivateRoute>

            {outlet.OutletTypeID === 3 && (
              <PrivateRoute path="/guestlistreceipt/:id?">
                <GuestListReceiptView />
              </PrivateRoute>
            )}
            <PrivateRoute path="/user/history/:type?/:ID?/:view?/:itemID?/:itemView?">
              <UserHistoryView />
            </PrivateRoute>


            <Route path="/login">
              <LoginView />
            </Route>
            <Route path="/resetpassword/:token">
              <ResetPasswordView />
            </Route>
            <Route>
              <PageNotFoundView />
            </Route>
          </Switch>
        </AppBodyStyled>
        <AppAlertRibbonStyled>
          <ContentBlock zone="CONTENT_ZONE_ABOVE_FOOTER" />
        </AppAlertRibbonStyled>
        <AppFooterStyled>
          <AppFooter />
        </AppFooterStyled>
      </AppWrapperStyled>

      <DeploymentInfoContainer>
        {!isProduction && deployedBranchNameExists &&
          <span>
            current branch deployed: <b>{process.env.REACT_APP_CI_COMMIT_REF_NAME}</b> {commitShortShaExists &&
              `(${process.env.REACT_APP_CI_COMMIT_SHORT_SHA})`}

          </span>
        }
        {!isProduction && process.env.REACT_APP_VERSION &&
          <span>
            app version: <b>{process.env.REACT_APP_VERSION}</b>
          </span>
        }
      </DeploymentInfoContainer>

      {sessionTimeoutWarningIsVisible && (
        <Modal
          open={true}
          //onClose={this.hideModal}
          closeIconIsVisible={false}
          title="Your Session Is Expiring Soon!"
        >
          <div style={{ margin: '1rem 1rem ' }}>
            {secondsUntilSessionExpires ? (
              <div style={{ padding: '0 0 3rem 0' }}>
                Your session will expire in
                <strong>
                  {' '}
                  {Math.floor(secondsUntilSessionExpires / 60)}
                </strong>{' '}
                minutes{' '}
              </div>
            ) : null}
            <Button onClick={renewUserSession}>Renew Session</Button>
            &nbsp;&nbsp;&nbsp;
            <Button secondary onClick={logoutUser}>
              Destroy Session
            </Button>
          </div>
        </Modal>
      )}

      <Toast
        toastList={toastList}
        position={'top-right'}
        autoDelete={false}
      //autoDeleteTime={autoDeleteTime}
      />
      <AppDrawer />
    </ThemeProvider>
  );
}

export default App;
