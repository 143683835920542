import store from '../../store';
import { createUUID } from '../../utils/utilities';


/**
 * Creates a toast notification. See src/components/toast/Toast.js for implementation details.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the toast notification.
 * @param {string} props.description - The description or message of the toast notification.
 * @param {string} [props.icon] - Optional icon URL for the toast notification.
 * @param {string|number} props.id - Unique identifier for the toast notification.
 * @param {string} [props.type] - The type of the toast notification (e.g., "error", "success"). Determines the background color.
 * @param {boolean} [props.autoDelete] - Flag to determine if the toast should auto-dismiss after a certain time.
 * @param {number} [props.dismissTime=5000] - The time in milliseconds before the toast is automatically dismissed (default is 3000 ms).
 *
 */
export const addToast = (props) => {
  props.id = createUUID();
  store.dispatch({ type: 'ADD_TOAST', toast: props });
};

export const deleteToast = (id) => {
  store.dispatch({ type: 'DELETE_TOAST', id });
};
