import React from 'react';
import styled from 'styled-components';
import Typography from './Typography';
import { formatCurrency, getCountryList } from './utilities';
import { FeeItemRow } from '../components/cart/LineItemSummary';

const BtnStyled = styled(Typography)`
  color: ${(props) => props.theme.PRIMARY_LINK_COLOR};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;
const SeriesTotalsLineItemStyled = styled.div`
  display: flex;
  margin: 0 0 0.5rem 0;
`;

const SeriesTotalsLabelStyled = styled(Typography)`
  flex: 1;
`;
const SeriesTotalsTotalStyled = styled(Typography)`
  padding: 0 0 0 0.5rem;
`;

export const DisplaySeriesTotals = ({ seriesSummary }) => {
  if (!seriesSummary) return null;
  return (
      <div>
        {seriesSummary &&
            seriesSummary.LotteryOERequests &&
            seriesSummary.LotteryOERequests.map((request, idx) => {
              const Event = request.OutletEvent && request.OutletEvent.Event;
              return (
                  <SeriesTotalsLineItemStyled
                      key={idx}
                      style={{ fontStyle: 'italic' }}
                  >
                    <SeriesTotalsLabelStyled>
                      {Event &&
                          Event.ShowDate &&
                          new Date(Event.ShowDate).toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                            timeZone: 'America/New_York',
                          })}{' '}
                      Event Max
                    </SeriesTotalsLabelStyled>
                    <SeriesTotalsTotalStyled>
                      {request.Totals &&
                          request.Totals.total &&
                          new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(request.Totals.total)}
                    </SeriesTotalsTotalStyled>
                  </SeriesTotalsLineItemStyled>
              );
            })}

        {seriesSummary &&
            seriesSummary.Totals &&
            seriesSummary.Totals.shipping > -1 && (
                <SeriesTotalsLineItemStyled style={{ fontStyle: 'italic' }}>
                  <SeriesTotalsLabelStyled>Shipping</SeriesTotalsLabelStyled>
                  <SeriesTotalsTotalStyled>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(seriesSummary.Totals.shipping)}
                  </SeriesTotalsTotalStyled>
                </SeriesTotalsLineItemStyled>
            )}

        <SeriesTotalsLineItemStyled style={{ fontWeight: 'bold' }}>
          <SeriesTotalsLabelStyled>Maximum Order Total</SeriesTotalsLabelStyled>
          <SeriesTotalsTotalStyled>
            {seriesSummary &&
                seriesSummary.Totals &&
                new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(seriesSummary.Totals.total)}
          </SeriesTotalsTotalStyled>
        </SeriesTotalsLineItemStyled>
      </div>
  );
};


/**
 * Component to display a header with a title and an optional button.
 *
 * This component renders a header title along with an optional button, which can trigger an action when clicked.
 * It provides a flexible layout for displaying titles and associated actions.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title to display in the header. If not provided, the component renders `null`.
 * @param {Function} [props.onClickBtn] - Optional click handler for the button. The button is only displayed if this is provided along with `btnText`.
 * @param {string} [props.btnText] - The text to display on the button. The button is only displayed if this is provided along with `onClickBtn`.
 * @param {string} [props.btnName] - Optional name attribute for the button. Defaults to an empty string if not provided.
 * 
 * @return {JSX.Element|null} - The rendered header with title and button, or `null` if no `title` is provided.
 *
 * @example
 * <DisplayHeader 
 *   title="Dashboard" 
 *   onClickBtn={() => alert('Button clicked')} 
 *   btnText="Click Me" 
 *   btnName="dashboardButton" 
 * />
 *
 * @remarks
 * - The button is styled using a custom `BtnStyled` component.
 * - If `title` is not provided, the component renders `null`.
 * - Includes default styling for flex-based layout.
 */
export const DisplayHeader = ({ title, onClickBtn, btnText, btnName }) => {
  if (title) {
    if (!btnName) {
      btnName = '';
    }
    return (
        <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              margin: '0 0 1.5rem 0',
            }}
        >
          <div>
            <Typography tag="h1">{title}</Typography>
          </div>
          {onClickBtn && btnText && (
              <div style={{ fontWeight: 'bold', margin: '0 0 0 2rem', flex: 1 }}>
                <BtnStyled
                    onClick={onClickBtn}
                    name={btnName}
                    className={'header-' + btnName}
                >
                  {btnText}
                </BtnStyled>
              </div>
          )}
        </div>
    );
  } else {
    return null;
  }
};


/**
 * Component to display a formatted shipping address.
 *
 * This component renders the details of a shipping address in a readable format.
 * If the `address` prop is not provided or is empty, it displays a fallback message.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.address - The address object containing details to display.
 * @param {number} props.address.AddressID - The unique ID of the address.
 * @param {string} [props.address.CompanyName] - The company name associated with the address (optional).
 * @param {string} props.address.AddressLineOne - The first line of the address.
 * @param {string} [props.address.AddressLineTwo] - The second line of the address (optional).
 * @param {string} props.address.City - The city of the address.
 * @param {number} props.address.CountryID - The ID of the country.
 * @param {string} props.address.First - The first name of the recipient.
 * @param {string} props.address.Last - The last name of the recipient.
 * @param {string} props.address.Phone - The phone number associated with the address.
 * @param {string} props.address.State - The state or region of the address.
 * @param {string} props.address.Zip - The postal code of the address.
 * @param {string} [props.address.EmailAddres] - The email address associated with the recipient (optional).
 *
 * @return {JSX.Element|string} - The rendered shipping address or a fallback message if no address is provided.
 *
 * @example
 * <DisplayShippingAddress 
 *   address={{
 *     AddressID: 1,
 *     First: "John",
 *     Last: "Doe",
 *     AddressLineOne: "123 Main St",
 *     AddressLineTwo: "Apt 4B",
 *     City: "Springfield",
 *     State: "IL",
 *     Zip: "62704",
 *     CountryID: 840,
 *     Phone: "555-123-4567",
 *     EmailAddres: "john.doe@example.com",
 *   }}
 * />
 *
 * @remarks
 * - Uses `getCountryList()` to resolve the country name from the `CountryID`.
 * - Handles optional fields such as `AddressLineTwo`, `CompanyName`, and `EmailAddres`.
 * - Displays "No address info" if the `address` prop is not provided or empty.
 *
 * @see {@link getCountryList} for country resolution logic.
 */
export const DisplayShippingAddress = ({ address }) => {
  if (address && Object.keys(address).length) {
    const {
      AddressID,
      CompanyName,
      AddressLineOne,
      AddressLineTwo,
      City,
      CountryID,
      First,
      Last,
      Phone,
      State,
      Zip,
      EmailAddres,
    } = address;
    const country = getCountryList().find((x) => x.id == Number(CountryID));
    return (
        <Typography>
          <div>
            {First} {Last}
          </div>
          <div>{CompanyName}</div>
          <div>{AddressLineOne}</div>
          {AddressLineTwo && <div>{AddressLineTwo}</div>}
          <div>
            {City}, {State} {Zip}
          </div>
          {country && country.name && <div>{country.name}</div>}
          <div>{Phone}</div>
          {EmailAddres && <div>{EmailAddres}</div>}
        </Typography>
    );
  } else {
    return 'No address info';
  }
};


/**
 * Component to display a formatted payment method.
 *
 * This component renders the details of a payment method, including the cardholder's name, card type, 
 * masked card number, and expiration date. If the `paymentMethod` prop is not provided or is empty, 
 * it displays a fallback message.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.paymentMethod - The payment method object containing details to display.
 * @param {string} props.paymentMethod.CardHolderName - The name of the cardholder.
 * @param {string} props.paymentMethod.Type - The type of payment method (e.g., "Visa", "MasterCard").
 * @param {string} props.paymentMethod.LastFour - The last four digits of the card number.
 * @param {string} props.paymentMethod.Expiration - The expiration date of the card in "YYYY-MM" format.
 * 
 * @return {JSX.Element} - The rendered payment method details or a fallback message if no payment method is provided.
 *
 * @example
 * <DisplayPaymentMethod 
 *   paymentMethod={{
 *     CardHolderName: "John Doe",
 *     Type: "Visa",
 *     LastFour: "1234",
 *     Expiration: "2025-12",
 *   }}
 * />
 *
 * @remarks
 * - Displays "No payment info" if the `paymentMethod` prop is not provided or empty.
 * - Ensures sensitive data (e.g., full card number) is not displayed.
 */
export const DisplayPaymentMethod = ({ paymentMethod }) => {
  // AddressID: null
  // AuthorizePaymentMethodID: "1507410481"
  // CardHolderName: "Derek Bernier"
  // Description: ""
  // Expiration: "2023-11"
  // LastFour: "1111"
  // PaymentMethodID: 106
  // PaymentMethodUUID: "ed766029-d45e-4bdb-ba6d-3bab5a425157"
  // UserID: 98
  if (paymentMethod) {
    return (
        <Typography>
          <div>{paymentMethod.CardHolderName}</div>
          <div>
            {paymentMethod.Type} ***{paymentMethod.LastFour}
          </div>
          <div>Exp {paymentMethod.Expiration}</div>
        </Typography>
    );
  } else {
    return <div>No payment info</div>;
  }
};


/**
 * Component to display a list of fees, formatted and styled based on the provided layout and options.
 *
 * This component formats and renders fee groups, either as a horizontal list with detailed rows or as 
 * a vertical list with simple text formatting. Each fee's total is calculated based on its value or total, multiplied by the quantity.
 *
 * @component
 * @param {Object} params - The properties passed to the component.
 * @param {Array<Object>} params.FeeGroups - An array of fee group objects containing details about each fee.
 * @param {string} params.FeeGroups[].Name - The name of the fee.
 * @param {string} [params.FeeGroups[].Description] - A description of the fee (optional).
 * @param {number} [params.FeeGroups[].Total] - The total value of the fee (optional, falls back to `Value` if not provided).
 * @param {number} [params.FeeGroups[].Value] - The base value of the fee (optional, used if `Total` is not provided).
 * @param {number} [params.quantity=1] - The multiplier applied to each fee's value or total.
 * @param {boolean} [params.hasLeftBorder=false] - Whether to display a left border on each fee item row.
 * @param {boolean} [params.hasRightBorder=false] - Whether to display a right border on each fee item row.
 * @param {string} [params.layout='horizontal'] - Layout of the fees: `"horizontal"` for detailed rows or `"vertical"` for text-based display.
 * 
 * @return {JSX.Element[]} - An array of rendered fee items based on the specified layout.
 *
 * @example
 * <DisplayFees 
 *   FeeGroups={[
 *     { Name: "Service Fee", Total: 5.00 },
 *     { Name: "Processing Fee", Value: 2.50 }
 *   ]}
 *   quantity={2}
 *   hasLeftBorder={true}
 *   layout="horizontal"
 * />
 *
 * @remarks
 * - When `FeeGroups` is empty or not an array, no fees are rendered.
 * - If both `Total` and `Value` are provided in a fee group, `Total` takes precedence.
 * - The `layout` prop determines the style of the fee display:
 *   - `"horizontal"`: Uses the `FeeItemRow` component for detailed rows.
 *   - `"vertical"`: Displays simple text-based fee information with formatting.
 * - Utilizes a `formatCurrency` utility for formatting monetary values.
 *
 * @see {@link formatCurrency} for fee total formatting.
 */
export const DisplayFees = (params) => {
  const {
    FeeGroups,
    quantity = 1,
    hasLeftBorder = false,
    hasRightBorder = false,
    layout = 'horizontal',
  } = params;

  const feeListFormatted = Array.isArray(FeeGroups) ? FeeGroups.map(feeGroup => {
    const { Total, Value } = feeGroup;
    const feeTotal = (Total || Value) * quantity;
    return ({ feeTotal, ...feeGroup });
  }) : [];

  return feeListFormatted.map((fee, idx) => {
    if (layout === 'horizontal') {
      return (<FeeItemRow
          key={idx}
          label={fee.Name}
          total={fee.feeTotal}
          description={fee.Description}
          hasLeftBorder={hasLeftBorder}
          hasRightBorder={hasRightBorder}
      />);
    } else {
      return (
          <Typography
              key={idx}
              style={{ marginBottom: '1rem' }}>
            {fee.Name}<br/>{formatCurrency(fee.feeTotal)}
          </Typography>);
    }
  });
};
