import styled from 'styled-components';
import React, { useState } from 'react';
import Button from '../../utils/Button';
import Collapse from '../../utils/Collapse';
import PriceLevelSelectorFanRequest from '../priceLevel/PriceLevelSelectorFanRequest';
import Typography from '../../utils/Typography';

const ErrorTextStyled = styled.div`
  font-size: 1.4rem;
  margin: 0;
  padding: 0.5rem 0rem;
  font-style: italic;
  color: ${(props) => props.theme.ERROR_TEXT_COLOR};
`;

/**
 * Component for editing line items in a fan request during checkout.
 *
 * This component is rendered in a modal when the user clicks "Edit" under the "Current Request" section.
 * It allows users to modify price levels and quantities for selected event items.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.loading - Indicates if the save operation is in progress.
 * @param {React.ReactNode} [props.children] - Additional content to render within the component.
 * @param {Array<Object>} props.outletEventItems - List of event items to display and edit.
 * @param {Function} props.onClickSave - Callback for saving the updated price levels.
 * @param {Function} props.onClickCancel - Callback for canceling the edit operation.
 * @param {Array<Object>} [props.defaultUserSelectedPriceLevels=[]] - The default selected price levels for the user.
 * @param {Array<Object>} [props.checkoutSelectedPriceLevels=[]] - The selected price levels in the checkout process.
 * @return {React.FC}
 *
 * @state {Array<Object>} selectedPriceLevels - The current state of selected price levels.
 *
 * @remarks
 * - Handles price level selection and quantity updates for line items.
 * - Includes logic to determine the availability of price levels.
 * - Validates and saves user modifications through `onClickSave`.
 *
 * @dependencies
 * - `Collapse`: Component for collapsible sections displaying event item details.
 * - `PriceLevelSelectorFanRequest`: Component for selecting and updating price levels.
 * - `Button`: Component for saving changes.
 * - `Typography`: Styled text component for rendering cancel links.
 */
function EditRequestLineItems(props = {}) {
  const {
    loading,
    children,
    outletEventItems,
    onClickSave,
    onClickCancel,
    defaultUserSelectedPriceLevels,
    checkoutSelectedPriceLevels,
  } = props;


  const [selectedPriceLevels, setSelectedPriceLevels] = useState(
    defaultUserSelectedPriceLevels || [],
  );

  /**
   * Handler for updating price level on a request (selecting different price level and updating quantity for price level)
   * @param {*} formdata
   * @param {*} outletEventItem
   * @param {*} existingLotteryOEIRequest
   */
  const handleOnChangePriceLevel = (formdata = {}, outletEventItem, existingLotteryOEIRequest) => {
    if (!outletEventItem) return;

    const OutletEventItemID = parseInt(outletEventItem.OutletEventItemID, 10);
    if (!OutletEventItemID) return;

    // note: this is not returned when we add a price level to a OEIR. check if we need to?
    const LotteryOEIRequestID = existingLotteryOEIRequest?.LotteryOEIRequestID;
    const Quantity = parseInt(formdata.selectedQty, 10);
    const PriceLevelID = parseInt(formdata.selectedPriceLevelId, 10);
    const PriceLevelOptOut = formdata.optOutIsSelected;

    const updatedItem = {
      LotteryOEIRequestID,
      Quantity,
      OutletEventItemID,
      PriceLevelID,
      PriceLevelOptOut,
    };

    const updatedPriceLevels = [...selectedPriceLevels];

    // TODO: try and clean this logic up as it's a bit confusing what's happening here
    const existingIndex = selectedPriceLevels.findIndex(
      (item) => item.OutletEventItemID === OutletEventItemID,
    );

    if (existingIndex > -1) {
      updatedPriceLevels[existingIndex] = updatedItem;
    } else {
      updatedPriceLevels.push(updatedItem);
    }

    setSelectedPriceLevels(updatedPriceLevels);
  };

  const handleOnSubmit = () => {
    onClickSave && onClickSave(selectedPriceLevels);
    return false;
  };

  const isValidPriceLevel = (priceLevels) =>
    priceLevels?.some((priceLevel) => priceLevel.SaleStatus !== 'OFF_SALE');

  const getDefaultSelectedPriceLevel = (oei, selectedPriceLevels) => {
    return selectedPriceLevels?.find(
      (spl) => spl.OutletEventItemID === oei.OutletEventItemID,
    );
  };

  const continueButtonIsDisabled = false;

  if (!Array.isArray(outletEventItems) || !outletEventItems.length) {
    return <div>No items available</div>;
  }

  return (
    <div>
      {outletEventItems.map((oei, idx) => {
        let priceLevelsAreAvailable = false;
        let defaultSelectedPriceLevel;

        // Check selected price levels
        defaultSelectedPriceLevel = getDefaultSelectedPriceLevel(oei, selectedPriceLevels);

        if (defaultSelectedPriceLevel) {
          priceLevelsAreAvailable = true;
        }

        // Check OEI price levels if not already available
        if (!priceLevelsAreAvailable) {
          priceLevelsAreAvailable = isValidPriceLevel(oei.PriceLevels);
        }

        // Check checkout-selected price levels
        if (!priceLevelsAreAvailable) {
          defaultSelectedPriceLevel = getDefaultSelectedPriceLevel(oei, checkoutSelectedPriceLevels);
          if (defaultSelectedPriceLevel) {
            priceLevelsAreAvailable = true;
          }
        }

        return (
          <div key={idx} style={{ margin: '2rem 0' }}>
            <Collapse
              heading={oei.OutletEventItemName || oei.Name}
              subHeading={oei.OutletEventItemDescription || oei.Description}
            >
              {priceLevelsAreAvailable ? (
                <PriceLevelSelectorFanRequest
                  lotteryOEIRequest={defaultSelectedPriceLevel}
                  outletEventItem={oei}
                  onChangeCallback={(formData) =>
                    handleOnChangePriceLevel(
                      formData,
                      oei,
                      defaultSelectedPriceLevel,
                    )
                  }
                />
              ) : (
                <ErrorTextStyled>Currently unavailable</ErrorTextStyled>
              )}
            </Collapse>
          </div>
        );
      })}

      {children && <div>{children}</div>}

      <Button
        disabled={continueButtonIsDisabled || loading}
        onClick={handleOnSubmit}
        fullWidth
        loading={loading}
      >
        Save
      </Button>

      <Typography
        style={{
          display: 'block',
          textAlign: 'center',
          margin: '3rem 0',
        }}
        tag="a"
        onClick={() => !loading && onClickCancel()}
        disabled={loading}
      >
        Cancel
      </Typography>
    </div>
  );
}

export default EditRequestLineItems;
