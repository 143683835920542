/**
 * API Wrapper around fetch call for making HTTP requests
 * @param {string} baseURL Base URL of the HTTP request
 * @param {Object} options Options to pass to fetch
 * @param {Object} [options.headers] Optional headers for the requests
 * @param {string} [options.credentials] Optional credentials for the requests
 */
export class ApiClient {
  constructor(baseURL, options = {}) {
    this.baseURL = baseURL;
    this.headers = options.headers || null;
    this.credentials = options.credentials || null;
  }

  get(endpoint) {
    return fetch(`${this.baseURL}/${endpoint}`, {
      method: 'GET',
      ...(this.headers && { headers: this.headers }),
      ...(this.credentials && { credentials: this.credentials }),
    });
  }

  post(endpoint, requestBody) {
    return fetch(`${this.baseURL}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...this.headers,
      },
      body: JSON.stringify(requestBody),
      ...(this.credentials && { credentials: this.credentials }),
    });
  }

  put(endpoint, requestBody) {
    return fetch(`${this.baseURL}/${endpoint}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...this.headers,
      },
      body: JSON.stringify(requestBody),
      ...(this.credentials && { credentials: this.credentials }),
    });
  }

  delete(endpoint, requestBody) {
    return fetch(`${this.baseURL}/${endpoint}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...this.headers,
      },
      body: JSON.stringify(requestBody),
      ...(this.credentials && { credentials: this.credentials }),
    });
  }
}
